.registerAccount {
    width: 558px;
    height: 536px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    gap: 0;
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    span {
        margin: 10px 0 56px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }
    label {
        font-size: 14px;
        font-weight: 600;
        width: 80%;
        color: rgba(78, 85, 102, 1);
    }
    .validation {
        color: rgb(190, 1, 13);
        margin-top: -5px;
        position: absolute;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: left;
        margin-top: 8px;
    }
    .phoneNumberBtn {
        margin-top: 56px;
    }

    .react-international-phone-input-container {
        margin-top: 8px;
    }
    .react-international-phone-country-selector-button__button-content {
        width: 100px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12.5px;
        border: 1px solid rgba(191, 196, 206, 1);
        border-radius: 12px 0 0 12px;
    }
    .react-international-phone-country-selector-button {
        width: 100px;
        height: 56px;
        border: none;
    }
    .react-international-phone-country-selector-button__dropdown-arrow {
        border-top: var(--react-international-phone-country-selector-arrow-size, 0px) solid
            var(--react-international-phone-country-selector-arrow-color, #777);
        border-right: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
        border-left: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
        transition: all 0.2s ease-out;
        background-image: url("../../../assets/Shape.svg");
        width: 14px;
        height: 8px;
        background-repeat: no-repeat;
        margin: 0;
    }
    .react-international-phone-input-container .react-international-phone-input {
        height: 56px;
        width: 356px;
        border: 1px solid rgba(191, 196, 206, 1);
        border-radius: 0 12px 12px 0;
        font-size: 16px;
        padding-left: 24px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }
    .react-international-phone-country-selector-dropdown {
        width: 456px;
        margin-top: 16px;
        height: 400px;
        border: 1px solid rgba(191, 196, 206, 1);
        border-radius: 12px;
        padding: 0;
        &::-webkit-scrollbar {
            display: none;
        }
        .react-international-phone-country-selector-dropdown__list-item {
            border-top: 1px solid rgba(191, 196, 206, 1);
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 24px;

            &:first-child {
                border-top: none;
            }

            span {
                font-size: 16px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
                margin: 0;
            }
            .react-international-phone-country-selector-dropdown__list-item-dial-code {
                margin-left: 10px;
            }
        }
    }
}
@media (max-width: 750px) {
    .registerAccount {
        width: 100%;
        max-width: 455px;
        height: auto;
        padding-top: 0;
        background: inherit;
        box-shadow: none;
        justify-content: center;
        
        label{
            width: auto;
        }
        p{
            width: 100%;
            max-width: 100%;
        }
        span{
            max-width: 100%;
        }
        .react-international-phone-input-container {
            margin-top: 8px;
            background-color: inherit;
            display: flex;
            justify-content: center;
        }
        .react-international-phone-input-container > * {
            background-color: inherit;
        }
        .react-international-phone-country-selector-button__button-content {
            background-color: inherit;
        }
        .react-international-phone-country-selector-button {
            background-color: inherit;
            border-radius: 12px 0 0 12px;
        }
        .react-international-phone-input-container .react-international-phone-input {
            max-width: 356px;
            min-width: 227px;
        }

        .phoneNumberBtn {
            margin-top: 56px;
            width: 100%;
        }
    }
}

@media (max-width: 500px) {
    .phoneForm{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: auto;
    }
    .react-international-phone-input-container .react-international-phone-input {
        width: 300px !important;
    }
    .react-international-phone-country-selector-dropdown {
        width: 402px !important;
    }
}
@media (max-width: 450px) {
    .phoneForm{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: auto;
    }
    .react-international-phone-input-container .react-international-phone-input {
        width: 227px !important;
    }
    .react-international-phone-country-selector-dropdown {
        width: 327px !important;
    }
}