.validationCheckbox {
    display: flex;
    align-items: center;
    justify-content: baseline;
    user-select: none;
    margin-bottom: 8px;

    p {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        color: rgba(78, 85, 102, 1) !important;
    }

    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + *::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border-style: solid;
        border-width: 1px;
        border-color: #405480; 
        margin-right: 16px;
    }
    input[type="checkbox"]:checked + p {
        color: #27ae60;
    }
    input[type="checkbox"]:checked + *::before {
        content: "";
        text-align: center;
        background-image: url("../../../../assets/Checkmark.svg");
        background-position: center;
        background-repeat: no-repeat;
        border-color: #27ae60;
    }
}
