.cameraAccessModal {
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
    border-radius: 0;
    top: 0;

    .textCAM {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        margin-block: 40px;
        text-align: center;
        width: 80%;
        max-width: 700px;
        p {
            font-size: 28px;
            font-family: "SpaceGrotesk";
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }

    .enableCameraBtn {
        width: 240px;
        height: 56px;
        font-size: 16px;
        font-weight: 600;
    }

    .notNowBtn {
        cursor: pointer;

        margin-top: 22px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
    }

    .goBack {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        justify-content: start;
        z-index: 21;
        width: auto;
        cursor: pointer;
        height: auto;
        gap: 16px;
        text-decoration: none;
        margin: 0;
        position: absolute;
        top: 24px;
        left: 54px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .backText {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(122, 132, 153, 1);
        }
    }
}
@media (max-width: 750px) {
    .cameraAccessModal {
        .goBack {
            left: 24px;
        }

        .enableCameraBtn {
            width: 80% !important;
            max-width: 540px;
        }
    }
}
@media (max-width: 500px) {
    .cameraAccessModal {
        margin: 0;
        justify-content: flex-start;
        padding-top: 55px;

        .goBack {
            .backText {
                display: none;
            }
        }
        .textPDM {
            margin-top: 25px;
            max-width: 80%;
        }
        .openCameraBtn {
            width: 80%;
            position: absolute;
            bottom: 40px;
        }
        .goBack {
            left: 24px;
        }
        .doctype {
            background: rgba(249, 249, 249, 1);
        }
    }
}
@media (max-height: 900px) and (max-width: 750px) {
    .cameraAccessModal {
        box-shadow: none;
        justify-content: flex-start;
        padding-top: 55px;
        min-height: 700px;
    }
}

@media (max-height: 700px) and (max-width: 500px) {
    .cameraAccessModal {
        justify-content: flex-start;
        min-height: 600px !important;
    }
}
