.button {
    width: 459px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid rgba(0, 15, 48, 1);
    font-size: 16px;
    font-weight: 600;
    background: inherit;
    color: rgba(43, 47, 56, 1);
    cursor: pointer;

    &:hover {
        background: rgba(242, 243, 245, 1);
    }
    &:active {
        background: rgba(230, 231, 235, 1);
    }

    button:disabled,
    button[disabled] {
        background: rgba(77, 89, 117, 1);
    }

    &.dark {
        background: rgba(0, 15, 48, 1);
        color: rgba(255, 255, 255, 1);

        &:hover {
            background: rgba(0, 18, 58, 1);
        }
        &:active {
            background: rgba(0, 11, 35, 1);
        }
        &:disabled,
        &[disabled] {
            background: rgba(77, 89, 117, 1) !important;
            border: none;
        }
    }
}
.noBorder {
    border: none;
    background: rgba(230, 231, 235, 1);

    &:hover {
        background: rgb(222, 222, 225);
    }

    &:active {
        background: rgb(212, 211, 211);
    }
}
