.default-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    min-width: 100%;
    max-width: 365px;
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    background: linear-gradient(134.94deg, #f8009e -73.34%, #000f30 114.06%);

    cursor: pointer;

    .filter-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        p {
            z-index: 10;
            max-width: 75%;
            word-break: break-all;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-align: left;
            color: rgba(249, 249, 249, 1);
        }
        .filter-detail-container {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    img {
        position: absolute;
        right: 16px;
        top: auto;
    }
}
.default-bond-filter {
    background: linear-gradient(137.06deg, #f8009e -19.1%, #000f30 196.44%) !important;
}
.my-filter {
    min-width: 245px;
    width: 100%;
    // max-width: 300px;
    max-width: 100%;
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    background: linear-gradient(90deg, #cfd9df 0%, #e2ebf0 100%);
    .edit-filter-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        cursor: pointer;
    }
    .filter-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        p {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-align: left;
            color: rgba(43, 47, 56, 1);
        }

        .filter-detail {
            background: rgba(249, 249, 249, 0.6);
        }
    }
}
