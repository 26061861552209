.chart-range-btn {
  width: 43px;
  max-width: 48px;
  min-width: 43px;
  height: 37px;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: 1px solid #bfc4ce;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  color: #2b2f38;
  flex-grow: 1;


  @media (max-width: 398px) {
    max-width: 120px;
  }
}

.chart-range-btn-active {
  border: 2px solid #000f30;
}
