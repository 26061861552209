.sorted-configs {
    min-height: 70px;
    height: auto;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;

    p {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: rgba(249, 249, 249, 1);
        max-width: 50%;
    }

    .sort-config-option {
        width: auto;
        max-width: 50%;
        height: 28px;
        padding: 4px 6px;
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        border: 1px solid #f9f9f9;
        overflow: hidden;

        p {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.005em;
            text-align: left;
            color: rgba(249, 249, 249, 1);
            max-width: 100%;
            overflow: hidden;
        }
    }
}