.message-video {
    min-width: 100px;
    min-height: 90px;
    width: auto;
    height: auto;
    max-width: 68%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 3px;
    background: rgba(77, 89, 117, 1);
    border-radius: 12px;
    position: relative;

    video {
        max-width: 100%;
        max-height: 260px;
        object-fit: contain;
        border-radius: 10px;
    }

    .play-pause-video {
        position: absolute;
        bottom: 4px;
        right: 6px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.15);
        width: 26px;
        min-width: 26px;
        height: 26px;
        transition: 0.3s;

        img {
            width: 16px;
            height: 16px;
        }
        .voice-message-stoped {
            margin-left: 2px;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.15);
        }
    }

    .remaining-sound-wrapper {
        // max-width: 38px;
        background-color: rgba(0, 0, 0, 0.35);
        padding: 2px 6px 2px 6px;
        gap: 6px;
        border-radius: 10px;
        position: absolute;
        top: 6px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        .time-remaining-video {
            font-size: 10px;
            font-weight: 500;
            line-height: 10px;
            letter-spacing: 0.0075em;
            text-align: center;
            color: rgb(253, 253, 253);
        }

        img {
            width: 15px;
            min-width: 15px;
            height: 14px;
            min-height: 14px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.15);
        }
    }

    span {
        max-width: 38px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.0075em;
        text-align: center;
        // color: rgba(191, 196, 206, 1);
        color: rgb(253, 253, 253);
        background-color: rgba(0, 0, 0, 0.35);
        padding: 0 6px 0 6px;
        border-radius: 10px;
        position: absolute;
        bottom: 6px;
        right: 8px;
    }
    &.last-msg {
        border-radius: 12px 12px 0 12px;
        img {
            border-radius: 10px 10px 0 10px;
        }
    }
}
.message-user-video {
    .play-pause-video {
        left: 6px !important;
    }
}
.message-admin-video {
    gap: 0;
    background: rgba(226, 228, 233, 1);

    span {
        // text-align: left;
        // color: rgba(78, 85, 102, 1);
        // position: absolute;
        left: 8px;
    }

    &.last-msg {
        border-radius: 12px 12px 12px 0px;
        img {
            border-radius: 10px 10px 10px 0px;
        }
    }
}

.vue-video-message {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50;

    .vue-vid-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 16px;

        span {
            color: rgba(255, 255, 255, 0.4);
        }

        .action_btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            .action_btn-wrapper {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 0.2s;

                .close-img-vue-icon {
                    width: 22px;
                    max-width: 22px;
                    height: 22px;
                    max-height: 22px;
                    opacity: 0.4;
                    transition: 0.2s;

                    &:hover {
                        background-color: rgb(255, 255, 255, 0.1);
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    video {
        max-width: 80% !important;
    }
}
