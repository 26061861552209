// .portfoli {
//     // max-width: 450px;
//     width: 100%;
//     height: 100%;
//     min-height: 550px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
//     background: #f9f9f9;
//     text-align: center;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 20;

//     .portfoli-header {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 100%;
//         height: 72px;
//         margin-bottom: 12px;

//         .go-back-portfolio {
//             cursor: pointer;
//             position: absolute;
//             left: 24px;
//         }
//         .rotate-screen {
//             // cursor: pointer;
//             display: none;
//             position: absolute;
//             right: 24px;
//             @media (max-width: 1024px) {
//                 display: block;
//             }
//             @media (max-height: 450px) {
//                 rotate: 270deg;
//                 transform: rotate3d(0, 1, 0, 180deg);
//             }
//         }

//         p {
//             font-size: 16px;
//             font-weight: 600;
//             color: #2b2f38;
//         }
//     }
//     .portfoli-main {
//         width: 100%;
//         display: flex;
//         align-items: flex-start;
//         justify-content: flex-start;
//         overflow-x: scroll;

//         -ms-overflow-style: none;
//         scrollbar-width: none;
//         &::-webkit-scrollbar {
//             display: none;
//         }
//     }
//     table {
//         border-spacing: 1;
//         border-collapse: collapse;
//         width: auto;

//         tr {
//             display: flex;
//             flex-direction: row;
//         }

//         th {
//             padding: 16px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-between;
//             border-top: 1px solid rgba(191, 196, 206, 1);
//             border-bottom: 1px solid rgba(191, 196, 206, 1);
//             border-right: 1px solid rgba(191, 196, 206, 1);
//             text-transform: uppercase;
//             font-size: 12px;
//             font-weight: 500;
//             color: rgba(78, 85, 102, 1);

//             span {
//                 padding-left: 8px;
//                 border-left: 1px solid rgba(191, 196, 206, 1);
//             }

//             &:nth-child(1) {
//                 width: 164px;
//                 min-width: 164px;
//                 position: sticky;
//                 left: 0;
//                 background: rgba(251, 251, 251);
//             }

//             &:nth-child(2) {
//                 width: 148px;
//                 min-width: 148px;
//             }
//             &:nth-child(3) {
//                 width: 148px;
//                 min-width: 148px;
//             }
//             &:nth-child(4) {
//                 width: 120px;
//                 min-width: 120px;
//             }
//             &:nth-child(5) {
//                 width: 105px;
//                 min-width: 105px;
//             }
//             &:nth-child(6) {
//                 width: 171px;
//                 min-width: 171px;
//             }
//         }
//     }
// }
// @media (min-width: 1024px) {
//     .portfoli-main {
//         justify-content: center !important;
//     }
//     table {
//         th {
//             border-left: 1px solid rgba(191, 196, 206, 1);
//         }
//         td {
//             border-left: 1px solid rgba(191, 196, 206, 1);
//         }
//     }
// }

.portfolio-container {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .portfolio-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;
        margin-bottom: 12px;

        img {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .portfolio-main {
        padding: 0px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
