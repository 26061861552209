.goBack {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 64px 48px;
  width: 100%;
  justify-content: start;

  .goBacklink {
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: baseline;
    gap: 16px;
    text-decoration: none;
    margin: 0;
  }

  .backText {
    font-family: "NotoSans" !important;
    margin-block: 0px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(122, 132, 153, 1);
  }
}

@media (max-width: 750px) {
  .goBack {
    display: none !important;

    .backText {
      display: none;
    }
  }
}
