@import "../../../styles/main.scss";

.my-bonuses {
    max-width: 450px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgb(251, 251, 251);
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .my-bonuses-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;
        margin-bottom: 12px;

        .go-back-my-bonuses {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
        .search-my-bonuses {
            cursor: pointer;
            position: absolute;
            right: 24px;
        }
    }
    .bonus-points-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        width: 100%;
        padding: 24px;
        p {
            @include flex-center;
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: center;
            color: rgba(43, 47, 56, 1);
            letter-spacing: 0.8px;

            img {
                margin-left: 8px;
            }
        }

        div {
            @include flex-center;
            background-color: rgba(186, 0, 119, 1);
            min-width: 180px;
            width: auto;
            max-width: 90%;
            height: 77px;
            padding: 12px 24px;
            border-radius: 12px;

            h1 {
                font-family: "SpaceGrotesk";
                color: rgba(249, 249, 249, 1);
                font-size: 48px;
                font-weight: 500;
                line-height: 52.8px;
                text-align: center;
            }
        }
    }
    .query-wrapper {
        margin-top: 24px;
        padding: 10px 26px;
        margin-bottom: 12px;
        width: 95%;

        .query-type {
            width: 100%;
            background-color: white;
            border-radius: 16px;
            position: relative;
            height: 44px;
            display: flex;

            button {
                width: 50%;
                height: 44px;
                min-height: 44px;
                border-radius: 16px;
                background-color: inherit;
                transition: 0s;
                overflow: hidden;
                padding: 0 8px;

                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                text-align: center;
                word-break: break-all;
                color: #2b2f38;
                border-bottom: 1px solid rgba(0, 0, 0, 0);
            }

            .active-btn {
                color: #f9f9f9;
                transition:
                    all 0.7s,
                    color 0.3s;
                background: #000f30;
            }
        }
    }

    .my-bonuses-main {
        width: 100%;
        max-width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 32px 24px 44px 24px;
        gap: 40px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .empty-bonuses-tab-main {
        gap: 8px;
        @include flex-center;
        justify-content: flex-start;

        p {
            margin-top: 24px;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            text-align: center;
            color: rgba(43, 47, 56, 1);
            max-width: 90%;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: rgba(78, 85, 102, 1);
            max-width: 90%;
        }
    }
}
