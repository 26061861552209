.documentRow {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 30px;
    width: 100%;
    padding: 24px 36px 24px 36px;
    border-bottom: 0.7px solid rgba(191, 196, 206, 0.6);

    span {
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
        display: flex;
        width: 68%;
        max-width: 715px;

        &::before {
            counter-increment: section;
            content: counter(section) ".";
            margin-right: 7px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
        display: flex;
        width: 65%;
        max-width: 715px;   
    }

    .box {
        width: 324px;
        height: 45px;
        padding: 12px 24px 12px 24px;
        border-radius: 12px;
        border: 1px solid red;
        gap: 16px;
    }
}
