.what-to-buy {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(249, 249, 249, 0.34);

    .tab-bar {
        display: flex;
        gap: 16px;
        overflow: scroll;
        width: 100%;
        max-height: 90px;
        padding: 20px 24px;
        position: sticky;
        top: 0px;
        z-index: 10;
        background: rgba(251, 251, 251);

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .research-categories {
        display: flex;
        gap: 8px;
        width: 100%;
        max-height: 90px;
        padding: 0px 24px 20px 24px;
        background: rgba(251, 251, 251, 1);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);

        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .what-to-buy-main {
        display: flex;
        flex-direction: column;
        padding: 0 24px 90px 24px;
        position: relative !important;

        .section-title {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            padding-block: 8px;
            color: rgba(43, 47, 56, 1);
        }

        .subcategory-wrapper {
            display: flex;
            flex-direction: column;
        }

        .no-favorites-text {
            width: 100%;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin-top: 3%;
        }
    }
    .what-to-buy-main-research {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0px 24px 24px 24px;
        position: relative !important;
        gap: 12px;
        transition: 0.2s;

        .wtb-search-filter {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            top: 84px;
            gap: 10px;
            z-index: 12;
            padding: 0px;
            transition: 0.2s;

            .wtb-search-icon {
                position: absolute;
                left: 10px;
                width: 20px;
                filter: grayscale(100%) brightness(0);
                z-index: 20;
                cursor: pointer;
                transition: all 0.2s;
            }
            input {
                padding-left: 36px;
                height: 32px;
                flex-grow: 1;
                border-radius: 6px;
                font-size: 15px !important;
                transition: 0.2s;
                z-index: 15;
                color: rgba(0, 0, 0, 0.85);

                &::placeholder {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 15px !important;
                }
                &:focus {
                    background: rgba(255, 255, 255, 0.9);
                    outline: none;
                }
            }
            .filter-categories {
                margin-left: auto;
                padding: 6px;
                width: 20px;
                cursor: pointer;
                position: sticky;
                top: 80px;
                right: 24px;
                border-radius: 6px;
                transition: 0.2s;
                z-index: 15;
                color: black;
            }
            .filter-categories-dropdown {
                width: 70%;
                padding: 6px;
                position: absolute;
                top: 38px;
                right: 0px;
                border-radius: 6px;
                background: rgba(255, 255, 255, 0.9) !important;
                transition: 0.2s;
                z-index: 15;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 4px;

                .research-category {
                    width: auto;
                }

                @media (max-width: 350px) {
                    width: calc(100% - 24px);
                }
            }

            .filter-categories,
            .filter-categories-dropdown,
            input {
                background: rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(3px);
                -webkit-backdrop-filter: blur(3px);
                box-sizing: content-box;
                box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.4);

                &:hover {
                    background: rgba(255, 255, 255, 0.9) !important;
                }
            }
        }

        .wtb-search-filter-stuck {
            padding: 0 8px;

            .wtb-search-icon {
                left: 16px;
            }
            .filter-categories-dropdown {
                right: 6px;
            }
        }
    }
    .what-to-buy-main-blog {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 24px 24px 24px;
        position: relative !important;
        gap: 36px;
    }
    .calendar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        min-height: 48px;
        border-radius: 12px;
        background: rgba(230, 231, 235, 0.7);
        padding-left: 16px;
        padding-right: 16px;
        margin-block: 16px;
        cursor: pointer;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            p {
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: rgba(43, 47, 56, 1);
            }
        }
    }
    .wtbm-tops {
        gap: 30px;

        button {
            margin-top: 16px;
            min-width: 126px;
            padding: 0 8px;
            height: 45px;
            border-radius: 12px;
            background: rgba(230, 231, 235, 0.8);
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: rgba(0, 15, 48, 1);

            &:hover {
                background: rgba(230, 231, 235, 1);
            }
            &:active {
                background: rgb(210, 211, 214);
            }
        }
    }
    .wtbm-strategy {
        display: flex;
        gap: 24px;
        padding-top: 12px;
        padding-bottom: 24px;
    }
}
