.depth-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-block: 16px;

    p {
        color: #2b2f38;
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 620;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.2);
        overflow: hidden;

        button {
            width: 40px;
            height: 30px;

            img {
                width: 24px;
            }

            &:hover {
                background-color: rgba(128, 128, 128, 0.4);
            }
        }
        .activeBtn {
            background-color: rgba(128, 128, 128, 0.7);
        }
    }
}
.depth-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .depth-main-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 0.5px solid #bfc4ce;
        position: sticky;
        top: 77px;
        background-color: #ffffff41;
        backdrop-filter: blur(10px);
        z-index: 10;

        p {
            width: 60px;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            &:nth-child(1) {
                color: #27ae60;
                text-align: left;
            }
            &:nth-child(2) {
                color: black;
                text-align: center;
            }
            &:nth-child(3) {
                color: #eb5757;
                text-align: right;
            }
        }
    }

    .depth-content {
        display: flex;
        flex-direction: column;
    }
}
.currency-info-bargaining {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
    text-align: left;
    div {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    img {
        width: 40px;
        height: 40px;
    }
    .price {
        font-size: 24px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
    }
    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(39, 174, 96, 1);
    }
    .loss {
        color: rgba(235, 87, 87, 1);
    }
    .gain-percent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .gains-percent {
            border-left: 2px solid rgba(191, 196, 206, 1);
            padding-left: 8px;
            margin-left: 8px;
            margin-top: 0;
            height: 20px;
            line-height: 20px;
        }
    }
    p {
        font-size: 11px;
        font-weight: 500;
        color: rgba(78, 85, 102, 1);
    }
}
