.promo-code-bottom-sheet {
    width: 100%;
    max-width: 450px;
    max-height: 70vh;
    height: auto;
    padding: 0px 0px 40px 0px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: editProfilImg 0.5s ease-out;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .gift {
        height: 110px;
        margin-block: 45px 35px;
    }
    .gift2 {
        animation: approved 1s;
    }

    .promo-code-bottom-sheet-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 16px 16px 0px 0px;
        background-color: rgba(249, 249, 249, 1);
        text-align: center;

        p {
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
        span {
            font-size: 16px;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
    }
    .promo-code-bottom-sheet-header-congrats {
        animation: approved 2.5s;
    }

    .promo-form-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        margin-block: 35px 50px;

        label {
            font-size: 14px;
            font-weight: 600;
            color: rgb(105, 111, 121);
            width: 100%;
            padding-left: 12%;
        }

        form {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            position: relative;

            input {
                width: 56%;
                height: 52px;
                padding-left: 24px;
                border-radius: 8px;
                border: 1px solid rgb(191, 196, 206);
                font-size: 16px;
                font-weight: 400;
                color: rgb(43, 47, 56);

                &:focus {
                    // outline: none;
                    outline: 2px solid rgb(43, 47, 56);
                    border-color: white;
                }
            }

            button {
                width: 20%;
                height: 52px;
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: 0.2px;
                line-height: 16px;
                padding: 0 8px;
                font-weight: 600;
                color: white;
                background-color: #f8009dcd;
                word-break: break-all;
            }
            span {
                position: absolute;
                left: 16.5%;
                top: 105%;
                color: #cf3838;

                &:after {
                    content: "";
                    background-image: url("../../../../assets/Warning.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    position: absolute;
                    left: -16%;
                    top: 0;
                    width: 16px;
                    height: 20px;
                    background-color: rgba(255, 255, 255, 0.5);
                    z-index: -1;
                    animation: error-message 1s ease-out;
                }
            }
        }
    }

    .approved-promo-code {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 58px;
        max-height: 80px;
        width: 78%;
        border: 2px solid #27ae60;
        background-color: #27ae5f04;
        border-radius: 8px;
        padding: 0 8px;
        gap: 8px;
        cursor: pointer;
        animation: approved 2.5s;
        overflow: hidden;

        img {
            width: 30px;
        }
    }
    @keyframes approved {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
@keyframes editProfilImg {
    from {
        max-height: 0;
    }
    to {
        max-height: 70vh;
    }
}
.outlet-promo-code-bottom-sheet {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 100;
    position: absolute;
}
