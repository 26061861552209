.edit-filter-card {
    width: 100%;
    max-width: 450px;
    height: 210px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: editFilterCard 0.25s ease-out;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .edit-filter-card-header {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: start;
        border-radius: 16px 16px 0px 0px;
        justify-content: space-between;
        padding: 24px 24px 10px 24px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        background-color: rgba(249, 249, 249, 1);
        p {
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
    }

    .edit-filter-row {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 24px;
        justify-content: flex-start;
        min-height: 56px;
        height: 56px;
        gap: 12px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
}
@keyframes editFilterCard {
    from {
        height: 0;
    }
    to {
        height: 210px;
    }
}
.outlet-edit-filter-card {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 105vh;
    top: -75px;
    left: 0;
    z-index: 100;
    position: absolute;
}
