.currency-modal {
    width: 100%;
    max-width: 454px;
    max-height: 60vh;
    height: auto;
    padding: 0px 0px 40px 0px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: currencyModal 0.5s ease-out;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .currency-modal-header {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: start;
        border-radius: 16px 16px 0px 0px;
        justify-content: space-between;
        padding: 24px 24px 10px 24px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        background-color: rgba(249, 249, 249, 1);
        p {
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
    }

    .edit-profile-row {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 24px;
        justify-content: flex-start;
        min-height: 56px;
        height: 56px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        gap: 12px;

        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
}
@keyframes currencyModal {
    from {
        max-height: 0;
    }
    to {
        max-height: 60vh;
    }
}
.outlet-currency-modal {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0;
    z-index: 100;
    position: absolute;
}
.currency-row {
    width: 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    border-bottom: 0.5px solid rgba(230, 231, 235, 1);
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        p {
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
    }

    img {
        width: 24px;
        height: 24px;
        border-radius: 4px;
    }
}
