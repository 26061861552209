.research-category {
    display: flex;
    width: 100%;
    height: 24px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    background: rgb(181, 181, 181, 0.4);
    padding: 8px;
    gap: 4px;

    img {
        display: none;
    }
    p {
        font-weight: 600;
        font-size: 11px;
        white-space: nowrap;
    }
}
.research-category-active {
    // background: rgb(55, 55, 55, 0.75);
    background: rgb(170, 170, 170, 0.9);
    padding: 0 8px 0 4px;

    img {
        display: flex;
        width: 14px;
        border-radius: 2px;
        filter: brightness(0.3);
    }

    p {
        filter: brightness(0.5);
    }

    // &:hover {
    //     img,
    //     p {
    //         filter: brightness(0.3);
    //     }
    // }
}
