.modalBtn {
    width: 332px;
    height: 45px;
    border-radius: 12px;
    background: #000f30;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    &:hover {
        background: rgba(0, 18, 58, 1);
    }
    &:active {
        background: rgba(0, 11, 35, 1);
    }
    &:disabled,
    &[disabled] {
        background: rgba(77, 89, 117, 1) !important;
        border: none;
        opacity: 0.4;
    }
}
