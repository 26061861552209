.currency-exchange-rate-icons {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 2px;
    border-radius: 100%;
    overflow: hidden;
    .exchange-rate-first,
    .exchange-rate-second {
        width: 20px;
        height: 40px;
        overflow: hidden;
    }
    .exchange-rate-second {
        display: flex;
        align-items: flex-start;
        rotate: 180deg;
        transform: rotateX(180deg);
    }
}
