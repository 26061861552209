/* add css module styles here (optional) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

.container {
  height: 50px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.dropdown {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;

  width: 100%;
  height: 56px;
  border: 1px solid #bfc4ce;
  border-radius: 12px;
  margin: 0;
  padding: 0;
  padding-left: 24px;
  transition: border 5s;
  background-color: inherit;

  &:active {
    border: 1px solid black;
    transition: 0s;
  }
}

.dropdown_items_wrapper {
  position: relative;
  transition: all 0.5s;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  margin: 8px 0px;
  transition: all 0.5s;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #bfc4ce;
}

.dropdown_items {
  padding: 0px;
  height: 200px;
  overflow-y: scroll;
}

.dropdown_items_larg {
  height: 350px;
}

.dropdown_items::-webkit-scrollbar {
  display: none;
}

.input_wrapper {
  position: relative;

  margin: 0px;
  padding-top: 0px;
}

.country_search {
  outline: none;
  width: 100%;
  height: 56px;
  padding: 0;
  padding-left: 72px;
  border-radius: 12px 12px 0 0;
  margin: 0;
  border-bottom: 1px solid #bfc4ce;
  background: inherit;
  background-image: url("../../assets/SearchMarket.svg");
  background-position: 24px 16px;
  background-repeat: no-repeat;
}

.country_search::placeholder {
  font-family: "Poppins";
  color: rgb(168, 168, 168);

  font-size: 16px;
  font-weight: 400;
  color: #4e5566;
}

/* width */
.dropdown_items::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.dropdown_items::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.dropdown_items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
.dropdown_items::-webkit-scrollbar-thumb:hover {
  background: #696969;
}

.dropdown_item {
  margin-bottom: 0px;
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bfc4ce;
  cursor: pointer;
  padding-left: 24px;
}

.dropdown_item:hover {
  background: #f2f3f5;
}
.dropdown_item:nth-last-child(1) {
  border-bottom: none;
}

.dropdown_item_title {
  margin-top: -2px;
  text-align: left;

  margin-left: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #2b2f38;
}

.selected_country {
  font-size: 16px;
  font-weight: 400;
  color: rgba(43, 47, 56, 1);
  margin-left: 24px;
}

.country_flag {
  height: 15px;
  width: 22px;
  border-radius: 2px;
}

/* .multiple-select-country-row{ */
/* 
} */
