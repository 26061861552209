.cameraAccessNotFoundModal {
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .textCAM {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        margin-block: 40px;
        text-align: center;
        width: 50%;
        max-width: 700px;
        p {
            font-size: 28px;
            font-family: "SpaceGrotesk";
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    .unableToResolveText {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        margin-block: 64px 88px;
        text-align: center;
        width: 50%;
        max-width: 700px;
        p {
            color: rgba(78, 85, 102, 1);
            font-family: "NotoSans";
            font-size: 16px;
            font-weight: 600;
        }
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    .warnings {
        width: 55%;
        max-width: 680px;
        height: auto;
        padding: 24px;
        border-radius: 24px;
        border: 2px solid rgba(47, 128, 237, 1);
        background-color: rgba(236, 244, 253, 1);

        ul {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            li {
                font-size: 14px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
            }
        }
    }

    .enableCameraBtn {
        width: 240px;
        height: 56px;
        font-size: 16px;
        font-weight: 600;
    }
    .stillNeedHelp {
        font-size: 14px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-decoration: none;
    }
    .notNowBtn {
        cursor: pointer;
        margin-top: 14px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
    }

    .goBack {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        justify-content: start;
        z-index: 21;
        width: auto;
        cursor: pointer;
        height: auto;
        gap: 16px;
        text-decoration: none;
        margin: 0;
        position: absolute;
        top: 24px;
        left: 54px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .backText {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(122, 132, 153, 1);
        }
    }
}
@media (max-width: 750px) {
    .cameraAccessNotFoundModal {
        height: auto;
        justify-content: flex-start;
        padding-top: 100px;
        padding-bottom: 50px;
        .goBack {
            left: 24px;
        }
        .textCAM {
            width: 80%;
        }
        .warnings {
            width: 80%;
            height: auto;
            padding: 24px;
            border-radius: 24px;
            border: 2px solid rgba(47, 128, 237, 1);
            background-color: rgba(236, 244, 253, 1);

            ul {
                gap: 14px;
            }
        }
        .unableToResolveText {
            margin-block: 54px 70px;
            width: 80%;
        }
    }
}
@media (max-width: 500px) {
    .cameraAccessNotFoundModal {
        margin: 0;
        justify-content: flex-start;
        padding-top: 55px;
        padding-bottom: 20px;

        .goBack {
            left: 24px;
            .backText {
                display: none;
            }
        }
        .warnings {
            width: 95%;
            height: auto;
            padding: 24px;
            border-radius: 24px;
            border: none;
            background-color: inherit;

            ul {
                gap: 16px;
                li {
                    color: rgba(78, 85, 102, 1);
                }
            }
        }
        .unableToResolveText {
            margin-block: 40px;
            width: 80%;
        }
    }
}

@media (max-height: 900px) and (max-width: 750px) {
    .cameraAccessNotFoundModal {
        box-shadow: none;
        justify-content: flex-start;
        padding-top: 55px;
        min-height: 700px;
    }
}

@media (max-height: 700px) and (max-width: 500px) {
    .cameraAccessNotFoundModal {
        justify-content: flex-start;
        min-height: 600px !important;
    }
}
