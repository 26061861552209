.radioCard {
    width: 82.2%;
    height: 72px;
    padding-left: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    user-select: none;
    gap: 16px;
    margin-bottom: 8px;
    background: #f2f3f5;

    @media (max-width: 750px) {
        background: rgba(249, 249, 249, 1);
        width: 100%;
        max-width: 540px;
    }

    input[type="radio"] {
        accent-color: #000f30;
        width: 18px;
        height: 18px;
    }

    p {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #000000 !important;
    }

    &:hover {
        background: #000f3014;

        input[type="radio"] {
            accent-color: #000f3014;
        }
    }
    &:active {
        background: #000f3026;
        input[type="radio"] {
            accent-color: #000f3026;
        }
    }
}
