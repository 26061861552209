.edit-rates-dropdown-container {
    position: relative;
}
.edit-rates-dropdown {
    width: 140px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #bfc4ce;
    border-radius: 12px;
    cursor: pointer;


    p {
        font-size: 16px;
        width: 68px;
        text-align: center;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
        padding: 0 24px 0 8px;
    }

    .dropdown-open-chevron {
        rotate: 180deg;
        transition: 0.3s;
    }
    .dropdown-close-chevron {
        rotate: 0deg;
        transition: 0.2s;
    }
}
.edit-rates-dropdown-content {
    margin-top: 8px;
    width: 140px;
    // height: 168px;
    border-radius: 12px;
    border: 1px solid #bfc4ce;
    background: rgb(251, 251, 251);
    position: absolute;
    animation: dropdown 0.4s ease-in-out;
    overflow: hidden;
    z-index: 10;

    div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        height: 56px;
        gap: 8px;
        border-bottom: 1px solid #bfc4ce;
        cursor: pointer;

        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
}
@keyframes dropdown {
    from {
        height: 0px;
    }
    to {
        height: 112px;
    }
}
