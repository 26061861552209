.fileArea {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 30%;

    .oploudFailedDefault {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(122, 132, 153, 1);
        width: auto;
    }
    .oploudFailedValidation {
        padding-left: 15px;
        padding-top: 2px;
        font-size: 12px !important;
        font-weight: 600 !important;
        color: rgba(235, 87, 87, 1) !important;
        width: 100%;
    }
    .addDocName {
        width: 324px;
        height: 56px;
        padding-left: 24px;
        border-radius: 8px;
        border: 1px solid rgba(191, 196, 206, 1);
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 15, 48, 1);
        margin-bottom: 16px;

        &::placeholder {
            font-weight: 400;
            font-size: 14px;
            color: rgba(122, 132, 153, 1);
        }
    }
    .checkboxFile {
        width: auto;
        margin-top: 25px;

        p {
            width: auto;
        }
    }
}

.oploudBlock {
    user-select: none;
    overflow: hidden;

    p{
        max-width: 70%;
        overflow: hidden;
    }
}
.oploudFileBox {
    width: 324px;
    height: 45px;
    border-radius: 12px;
    border: 1px solid rgba(0, 15, 48, 1);

    input {
        visibility: hidden;
        width: 0;
        height: 0;
    }
    label {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        cursor: pointer;
    }
    .drageFile {
        background: rgba(183, 184, 186, 0.7);
        transition: 0.5s;
    }
}
.oploudFileBoxDisabled {
    opacity: 0.5;
    cursor: not-allowed;

    label {
        cursor: not-allowed;
    }
}
.checkboxFileDisabled {
    opacity: 0.5;
    cursor: not-allowed !important;

    input {
        cursor: not-allowed !important;
    }
}
.oploudFileComplete {
    width: 324px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(230, 231, 235, 1);

    p {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: rgba(43, 47, 56, 1) !important;
    }
    .oploudComplete {
        display: block;
    }
    .removeFile {
        display: none;
        cursor: pointer;
    }
    &:hover {
        .oploudComplete {
            display: none;
        }
        .removeFile {
            display: block;
        }
    }
}
.oploudFileLoading {
    width: 324px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(230, 231, 235, 1);

    p {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: rgba(78, 85, 102, 1) !important;
    }
    #loading {
        width: 14px;
        height: 14px;
        position: relative;

        .outer-shadow,
        .inner-shadow {
            z-index: 4;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
        .inner-shadow {
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            margin-left: -5px;
            margin-top: -5px;
            border-radius: 100%;
            background-color: rgba(230, 231, 235, 1);
        }
        .hold {
            position: absolute;
            width: 100%;
            height: 100%;
            clip: rect(0px, 100px, 100px, 7px);
            border-radius: 100%;
            background-color: rgba(191, 196, 206, 1);
        }
        .fill,
        .dot span {
            background-color: rgba(56, 152, 224, 1);
        }
        .fill {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            clip: rect(0px, 7px, 14px, 0px);
        }
        .left .fill {
            z-index: 1;
            -webkit-animation: left 1s linear;
            -moz-animation: left 1s linear;
            animation: left 1s linear both;
        }
        .right {
            z-index: 3;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        .right .fill {
            z-index: 3;
            -webkit-animation: right 1s linear;
            -moz-animation: right 1s linear;
            animation: right 1s linear both;
            -webkit-animation-delay: 1s;
            -moz-animation-delay: 1s;
            animation-delay: 1s;
        }
        @keyframes left {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
        }
        @keyframes right {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
        }
    }
}
.oploudFileFailed {
    width: 324px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(230, 231, 235, 1);
    border: 1px solid rgba(235, 87, 87, 1);

    p {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: rgba(78, 85, 102, 1) !important;
    }
    .oploudFailed {
        margin-right: 16px;
    }
    .removeFile {
        cursor: pointer;
    }
}
