.question-item {
    margin: 12px 6px;
    text-align: left;
    width: 100%;

    &__header {
        margin-bottom: 12px;
    }

    &__title {
        font-size: 16px;
        color: #2b2f38;
    }

    &__info {
        font-size: 13px;
        color: #2b2f38;
    }

    &__options {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 6px;
        font-size: 14px;
    }
}
