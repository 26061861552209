.stories-card {
    cursor: pointer;
    width: 156px;
    min-width: 156px;
    height: 147px;
    padding: 0px 12px 12px 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    // background: linear-gradient(130.24deg, #f8d3eb -13.54%, #bbcffa 113.9%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    span {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
    }
}
