.register-birthday {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .register-birthday-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding-left: 24px;
        padding-right: 24px;

        .register-title {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-block: 20% 10%;
            width: 90%;
            color: rgba(43, 47, 56, 1);
        }
        .radioCard {
            width: 90%;
        }
    }

    .dateInputCards {
        display: flex;
        gap: 16px;
        position: relative;
    }
    .btn-wrapper {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 12px !important;
    }
    @media (max-width: 750px) {
        .radioCard {
            background: #f2f3f5;
            width: 100%;
            max-width: 540px;
        }
    }
}
