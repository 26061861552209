.termsAndConditions {
    width: 558px;
    height: auto;
    padding-bottom: 72px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
        margin-bottom: 40px;
        max-width: 80%;
    }
    .termsCheckboxCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        padding-left: 48px;
    }
    .registerNameBtn {
        margin-top: 48px;
    }
}
@media (max-width: 750px) {
    .termsAndConditions {
        width: 100%;
        max-width: 455px;
        padding: 0;
        background: inherit;
        box-shadow: none;
        
        p {
            max-width: 100%;
        }
        .termsCheckbox p {
            width: 100% !important;
            max-width: 100% !important;
        }
        .termsCheckboxCard {
            padding-left: 0px;
        }

        button {
            width: 100%;
        }
    }
}
@media (max-width: 500px) {
    .termsAndConditions {
        width: 100%;
        max-width: 455px;
        position: static;
        p {
            max-width: 100%;
        }
        .termsCheckboxCard {
            width: 100% !important;
            max-width: 100% !important;
        }
        button {
            width: 80%;
            max-width: 540px;
            position: absolute;
            bottom: 40px;
        }
    }
}
