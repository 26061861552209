.voice-message-container {
    width: auto;
    max-width: 65%;
    display: flex;
    // flex-direction: column;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
    height: auto;
    padding: 8px 12px 20px 12px;
    background: rgba(77, 89, 117, 1);
    border-radius: 12px;
    overflow: hidden;

    .voice-created-at {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.0075em;
        text-align: right;
        color: rgba(191, 196, 206, 1);
        position: absolute;
        bottom: 6px;
        right: 10px;
    }

    button {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: rgba(0, 0, 0, 0.15);
        background: #ffffff;
        width: 40px;
        min-width: 40px;
        height: 40px;
        img {
            width: 24px;
            height: 24px;
        }
        .voice-message-stoped {
            margin-left: 2.7px;
        }
    }
    .timeline-range-Vmessage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .timeline-Vmessage {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 3px 0 0 4px;

        div {
            display: flex;
            gap: 2px;

            span {
                font-size: 10px;
                font-weight: 500;
                line-height: 15px;
                letter-spacing: 0.0075em;
                text-align: right;
                color: rgb(249, 249, 249);
            }
        }
    }

    &.last-msg {
        border-radius: 12px 12px 0 12px;
    }
}

.voice-message-admin-container {
    background: rgba(226, 228, 233, 1);

    .voice-created-at {
        text-align: left;
        color: rgb(78, 85, 102);
    }
    .timeline-Vmessage {
        div {
            span {
                text-align: left;
                color: rgb(43, 47, 56);
            }
        }
    }
    button {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: rgba(0, 0, 0, 0.15);
        background: rgba(255, 255, 255, 0.6);
        width: 40px;
        min-width: 40px;
        height: 40px;
        img {
            width: 24px;
            height: 24px;
        }
        .voice-message-stoped {
            margin-left: 2.7px;
        }
    }
    &.last-msg {
        border-radius: 12px 12px 12px 0px;
    }
}

input {
    &[type="range"] {
        -webkit-appearance: none;
        -moz-apperance: none;
        appearance: none;
        width: 100%;
        height: 8px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.15);
        outline: none;
        border: none;
        cursor: pointer;
    }
    &[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: rgb(186, 186, 186);
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background: rgba(249, 249, 249, 0.5);
        }
    }
    &[type="range"]::-moz-range-thumb {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
    }
    &[type="range"]::-ms-thumb {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
    }
}

.voice-message-admin-box {
    justify-content: flex-start;
    border-radius: 12px 12px 12px 0px;
}

// /* IE */
// input[type="range"]::-ms-track {
//     width: 100%;
//     height: 8px;
//     cursor: pointer;
//     background: transparent;
//     border-color: transparent;
//     color: transparent;
// }
/*Chrome*/

// input[type="range"]::-webkit-slider-runnable-track{
//     width: 100%;
//     height: 8px;
//     border-radius: 5px;
//     cursor: pointer;
//     background: red;
//     // color: transparent;
// }
/* WebKit-based browsers like Chrome and Safari */

// .......... old version styles ..........

// .voice-message-box {
//     width: auto;
//     display: flex;
//     align-items: flex-end;
//     justify-content: flex-end;
//     gap: 8px;

//     margin-top: 10px;

//     .support-icon {
//         width: 40px;
//         height: 40px;
//     }

//     .voice-message-container {
//         width: auto;
//         max-width: 65%;
//         display: flex;
//         // flex-direction: column;
//         align-items: flex-end;
//         justify-content: flex-end;
//         gap: 4px;
//         height: auto;
//         padding: 8px 12px;
//         background: rgba(77, 89, 117, 1);
//         border-radius: 12px 12px 0px 12px;
//         // border-radius: 12px;

//         button {
//             border-radius: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background: rgba(0, 0, 0, 0.15);
//             width: 35px;
//             height: 35px;
//             img {
//                 width: 24px;
//                 height: 24px;
//             }
//             .voice-message-stoped {
//                 margin-left: 2.7px;
//             }
//         }
//         .timeline-range-Vmessage {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//         }
//         .timeline-Vmessage {
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-between;
//             width: 100%;
//             padding: 3px 0 0 4px;

//             div {
//                 display: flex;
//                 gap: 2px;

//                 span {
//                     font-size: 10px;
//                     font-weight: 500;
//                     line-height: 15px;
//                     letter-spacing: 0.0075em;
//                     text-align: right;
//                     color: rgb(249, 249, 249);
//                 }
//             }

//             p {
//                 font-size: 10px;
//                 font-weight: 500;
//                 line-height: 15px;
//                 letter-spacing: 0.0075em;
//                 text-align: right;
//                 color: rgba(191, 196, 206, 1);
//             }
//         }

//         &.last-msg {
//             border-radius: 12px 12px 0 12px;
//         }
//     }

//     .voice-message-admin-container {
//         background: rgba(226, 228, 233, 1);
//         border-radius: 12px 12px 12px 0px;

//         .timeline-Vmessage {
//             div {
//                 span {
//                     text-align: left;
//                     color: rgb(43, 47, 56);
//                 }
//             }
//             p {
//                 text-align: left;
//                 color: rgb(78, 85, 102);
//             }
//         }

//         &.last-msg {
//             border-radius: 12px 12px 12px 0px;
//         }
//     }

//     input {
//         &[type="range"] {
//             -webkit-appearance: none;
//             -moz-apperance: none;
//             appearance: none;
//             width: 100%;
//             height: 8px;
//             border-radius: 5px;
//             background: rgba(0, 0, 0, 0.15);
//             outline: none;
//             border: none;
//             cursor: pointer;
//         }
//         &[type="range"]::-webkit-slider-thumb {
//             -webkit-appearance: none;
//             width: 12px;
//             height: 12px;
//             border-radius: 50%;
//             background: rgb(186, 186, 186);
//             cursor: pointer;
//             transition: 0.3s;

//             &:hover {
//                 background: rgba(249, 249, 249, 0.5);
//             }
//         }
//         &[type="range"]::-moz-range-thumb {
//             width: 12px;
//             height: 12px;
//             border-radius: 50%;
//             background: #ffffff;
//             cursor: pointer;
//         }
//         &[type="range"]::-ms-thumb {
//             width: 12px;
//             height: 12px;
//             border-radius: 50%;
//             background: #ffffff;
//             cursor: pointer;
//         }
//     }
// }
// .voice-message-admin-box {
//     justify-content: flex-start;
//     border-radius: 12px 12px 12px 0px;
// }
