@import "../../../../styles/main.scss";
.modal-create-filter {
    padding: 24px;
    max-width: 327px;
    width: 88%;
    height: auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    overflow: hidden;

    .modal-create-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;

        p {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 600;
            color: #2b2f38;
        }

        .close {
            cursor: pointer;
        }
    }

    span {
        font-size: 16px;
        font-weight: 400;
        color: #2b2f38;
        text-align: left;
    }

    .filter-name-inpute {
        width: 100%;
        margin-block: 24px;
        @include flex-center;
        flex-direction: column;
        gap: 8px;
        input {
            width: 100%;
            height: 45px;
            padding: 12px;
            border-radius: 8px;
            border: 1px solid #bfc4ce;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            background-color: inherit;

            &::first-letter {
                text-transform: uppercase !important;
            }

            &::placeholder {
                color: #4e5566;
            }
        }

        label {
            width: 98%;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            @include flex-center;

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                color: #eb5757;
            }
        }
    }

    .create-filter-btns-wrapper {
        width: 100%;
        @include flex-center;
        justify-content: space-between !important;
        gap: 16px;

        .create-filter-white-btn {
            background-color: #e6e7eb;
            color: #000f30;

            &:disabled,
            &[disabled] {
                background: #e6e7eb !important;
                opacity: 0.4;
            }
        }
        button {
            overflow: hidden;
            max-width: 49%;
        }
    }
}
.create-filter-outlet {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
}
