.outlet-app-update-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.app-update-modal {
  padding: 24px;
  max-width: 400px;
  width: 88%;
  height: auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  text-align: center;
  position: absolute;

  p {
    font-family: "SpaceGrotesk";
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #2b2f38;
    margin-bottom: 12px;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    color: #2b2f38;
    margin-bottom: 24px;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      //   padding: 10px 20px;
      //   font-size: 16px;
      //   background-color: #007bff;
      //   color: #fff;
      //   border: none;
      //   border-radius: 5px;
      //   cursor: pointer;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    .app-update-modal-btn {
      background-color: #e6e7eb;
      color: #000f30;
    }
    button {
      overflow: hidden;
      max-width: 49%;
    }
    .app-update-modal-only-btn {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
