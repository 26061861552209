.review-details-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-block: 4px;
    gap: 12px;

    p {
        font-size: 14px !important;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: left;
    }
    span {
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        color: rgba(43, 47, 56, 1);
    }
}
