.brokerage-report {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .date-deadline-container {
        padding: 8px 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 15px;
    }

    .brokerage-report-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;

        .go-back-brokerage {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .brokerage-report-main {
        width: 100%;
        max-width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 24px 56px 24px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .brokerage-section-name {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 620;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            width: 100%;
            color: rgba(43, 47, 56, 1);
        }

        .report-date-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1px;
            margin-block: 16px 32px;

            .report-date-picker-container,
            .report-item-container {
                &:only-child {
                    border-radius: 12px !important;
                }
                &:first-child {
                    padding-top: 14px;
                    border-radius: 12px 12px 0 0;
                }
                &:nth-last-child(1) {
                    padding-bottom: 14px;
                    border-radius: 0 0 12px 12px;
                }
            }
        }
        .report-date-item-wrapper {
            width: 100%;
            min-height: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 1px;
            margin-block: 16px;
            margin-bottom: 70px;
            overflow-y: scroll;
            border-radius: 12px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .select-brokerage-language {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1px;
            margin-bottom: 32px;
            padding-block: 16px;

            .brokerage-language {
                width: 100%;
                height: 56px;
                background-color: white;
                box-shadow: 0px 2px 14px 1px #0000000f;
            }
        }
    }
}
