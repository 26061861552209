.restore-by-password-modal {
    padding: 24px;
    width: 380px;
    height: auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    text-align: center;

    .closeDiv {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .close {
            cursor: pointer;
        }
    }
    .closeConfirmDeal {
        .close {
            margin-bottom: 12px;
            cursor: pointer;
        }
    }

    form,
    .form-row,
    .input-container {
        width: 100%;
        margin: 0;

        input {
            width: 100%;
            text-align: left;
            padding-left: 12px;
            font-size: 14px;
            text-transform: none !important;
        }
    }

    .forgot-password-PIN-restor {
        width: 100%;
        text-align: left;
        margin-block: 2px 20px;

        span {
            cursor: pointer;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 600;
        color: #2b2f38;
        margin-bottom: 16px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: #4e5566;
        margin-bottom: 24px;
    }
}

.outlet {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
}
