.app-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-top: 68px;
  padding-bottom: 75px;
  background: rgba(242, 243, 245, 0.34);

  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //     display: none;
  // }

  .app-header {
    width: 100%;
    height: 68px;
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(251, 251, 251);
    padding-left: 24px;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    overflow: hidden;

    .layout-home-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
      width: 100%;

      .layout-home-header-profile-img {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
        border: 1px solid rgba(0, 0, 0, 0.232);
      }
    }

    p {
      font-family: "SpaceGrotesk";
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 15, 48, 1);
    }

    .account-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 24px;

      span {
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
      }
    }
    .chat-nav {
      span {
        font-size: 14px;
      }
    }
  }

  .app-navbar {
    width: 100%;
    // height: calc(75px + env(safe-area-inset-bottom, 0px));
    // padding-bottom: env(safe-area-inset-bottom, 0px);
    height: 75px;
    max-width: 450px;
    display: flex;
    background: rgba(0, 15, 48, 1);
    position: fixed;
    bottom: 0;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0%);

    .chat__page_container {
      position: relative;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      width: 25%;
      height: 100%;
      background: rgba(0, 15, 48, 1);
      transition: background 0.2s;

      &:hover {
        background: rgba(0, 18, 58, 1);
      }
      &:active {
        background: rgba(0, 11, 35, 1);
      }

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        justify-content: flex-start !important;
        padding-top: 8px !important;
      }

      // @supports not (-webkit-touch-callout: none) {
      //     /* CSS for other than iOS devices */
      //     justify-content: center;
      //     padding-top: 0px;
      // }
    }

    .active-page {
      border-top: 2px solid rgba(248, 0, 158, 1);
      padding-bottom: 2px;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: rgba(248, 0, 158, 1);
        text-align: center;
      }
    }
  }

  .modal-log-out {
    padding: 24px;
    width: 80%;

    button {
      width: 100% !important;
    }
  }
  .customOutlet {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
  }
}
