.proof-of-address {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .proof-of-address-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 12px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }

    .proof-of-address-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding-left: 24px;
        padding-right: 24px;

        .register-title {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-block: 20% 10%;
            width: 90%;
            color: rgba(43, 47, 56, 1);
        }

        .register-subtitle {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10%;
            width: 90%;
        }

        form {
            max-width: 100%;
            width: 100%;
            input {
                width: 100%;
            }

            .btn-wrapper {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
