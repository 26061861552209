.change-email {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .change-email-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 26px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
    }
    .change-email-main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
        position: relative;

        p {
            font-family: "SpaceGrotesk";
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            color: rgba(43, 47, 56, 1);
            margin-bottom: 12px;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }

        .change-email-form {
            max-width: 450px;
            width: 100%;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .form-row {
                width: 100%;
                input {
                    width: 100%;
                }

                .error {
                    color: rgb(190, 1, 13) !important;
                    margin-top: -5px;
                }
            }

           
        }
    }
}
