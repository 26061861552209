.action-btn {
    width: 46%;
    max-width: 180px;
    height: 58px;
    min-height: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    &:disabled {
        opacity: 0.6;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
}
.action-btn-light {
    background: rgba(230, 231, 235, 1);
    transition: 0.1s;
    &:active {
        background: rgb(228, 228, 228);
    }
}
.action-btn-dark {
    background: rgba(0, 15, 48, 1);
    transition: 0.1s;
    &:active {
        background: rgb(0, 12, 39);
    }
    p {
        color: rgba(249, 249, 249, 1);
    }
    span {
        color: rgba(249, 249, 249, 1);
    }
}
