.progressLarg {
    width: 40% !important;
    min-width: 558px;
    max-width: 750px;
}
.progress {
    display: flex;
    flex-direction: column;
    width: 558px;
    margin-block: 40px 70px;
    z-index: 10;

    .progressText {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        .activeText {
            font-size: 14px;
            font-weight: 600;
            color: #4e5566;
        }
        .passiveText {
            font-size: 14px;
            font-weight: 600;
            color: #7a8499;
        }
    }
}
.rs-progress-line {
    padding: 8px 0 0 0;
    display: flex;
    width: 100%;
}
.progress-circle {
    display: none;
}
.mobileInfo {
    display: none;
}
@media (max-width: 750px) {
    .progressLarg {
        width: auto !important;
        min-width: 0 !important;
    }
    .progress {
        margin-block: 0;
        z-index: 10;
        width: auto;
        position: absolute;
        top: 24px;
    }
    .progress-line {
        display: none;
    }
    .progressText {
        display: none !important;
    }
    .progress-circle {
        display: block;
        width: 50px;
    }
    .mobileInfo {
        display: block;
        position: absolute;
        top: 17px;
        width: 50px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: rgba(122, 132, 153, 1);
    }
}
