.language-row {
    width: 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    border-bottom: 0.5px solid rgba(230, 231, 235, 1);
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        p {
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
    }
}
