.pin-code-page {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .change-PIN-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 16px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .change-PIN-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 56px 24px 0 24px;
        gap: 24px;

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: rgba(78, 85, 102, 1);
        }

        .forgot-password {
            width: 100%;
            text-align: center;
            span {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .green {
        border: 1px solid rgba(39, 174, 96, 1);
    }
    .red {
        border: 1px solid rgba(235, 87, 87, 1);
        color: rgba(235, 87, 87, 1);
    }
    .error-verify {
        color: rgba(235, 87, 87, 1);
        font-size: 12px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
    }
    input {
        border-radius: 12px;
        width: 60px;
        height: 56px;
        padding: 0;
        font-size: 18px;
        text-align: center;
        margin-right: 12px;
        text-transform: uppercase;
        color: #494949;
        border: 1px solid rgba(191, 196, 206, 1);
        gap: 24px;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &:focus {
            appearance: none;
            outline: 0;
            border: 1px solid rgba(0, 15, 48, 1);
        }

        button {
            appearance: none;
            background: #0071e3;
            border-radius: 980px;
            text-align: center;
            border: none;
            color: #fff;
            margin: 8px;
            padding: 4px 11px;
        }

        p {
            font-size: 17px;
            line-height: 1.47059;
        }

        .props {
            border: 1px solid #d6d6d6;
            padding: 8px 16px;
        }

        .options {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .allowed-characters {
            text-align: left;
        }
    }
}

// .pin-code-page {
//     width: 558px;
//     height: auto;
//     padding-bottom: 72px;
//     border-radius: 24px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding-top: 48px;
//     background: rgba(255, 255, 255, 1);
//     box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
//     z-index: 10;
//     position: relative;

//     p {
//         font-family: "SpaceGrotesk";
//         font-size: 28px;
//         font-weight: 600;
//         color: rgba(43, 47, 56, 1);
//         text-align: center;
//         margin-bottom: 12px;
//         max-width: 80%;
//     }
//     span {
//         font-size: 16px;
//         font-weight: 400;
//         text-align: center;
//         max-width: 80%;
//         color: rgba(78, 85, 102, 1);
//         margin-bottom: 40px;
//     }

//     .green {
//         border: 1px solid rgba(39, 174, 96, 1);
//     }
//     .red {
//         border: 1px solid rgba(235, 87, 87, 1);
//         color: rgba(235, 87, 87, 1);
//     }
//     .error-verify {
//         color: rgba(235, 87, 87, 1);
//         font-size: 12px;
//         padding: 0;
//         margin: 0;
//         margin-top: 5px;
//     }

//     input {
//         border-radius: 12px;
//         width: 60px;
//         height: 56px;
//         padding: 0;
//         font-size: 18px;
//         text-align: center;
//         background: inherit;
//         margin-right: 12px;
//         text-transform: uppercase;
//         color: #494949;
//         border: 1px solid rgba(191, 196, 206, 1);

//         &:nth-last-child(1) {
//             margin-right: 0;
//         }

//         @media (max-width: 365px) {
//             margin-right: 4px;
//         }
//         @media (max-width: 305px) {
//             margin-right: 8px;
//             margin-top: 8px;

//             &:first-child {
//                 margin-left: 22.5%;
//             }
//             &:nth-last-child(2) {
//                 margin-left: 22.5%;
//             }
//         }

//         &:focus {
//             appearance: none;
//             outline: 0;
//             border: 1px solid rgba(0, 15, 48, 1);
//         }

//         button {
//             appearance: none;
//             background: #0071e3;
//             border-radius: 980px;
//             text-align: center;
//             border: none;
//             color: #fff;
//             margin: 8px;
//             padding: 4px 11px;
//         }

//         p {
//             font-size: 17px;
//             line-height: 1.47059;
//         }

//         .props {
//             border: 1px solid #d6d6d6;
//             padding: 8px 16px;
//         }

//         .options {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//         }

//         .allowed-characters {
//             text-align: left;
//         }
//     }
// }
// @media (max-width: 750px) {
//     .pin-code-page {
//         width: 100%;
//         max-width: 455px;
//         padding: 0;
//         background: inherit;
//         box-shadow: none;

//         p {
//             max-width: 100%;
//         }
//         span {
//             max-width: 100%;
//         }
//     }
// }
// @media (max-width: 500px) {
//     .pin-code-page {
//         width: 100%;
//         max-width: 455px;
//         position: static;

//         p {
//             max-width: 100%;
//         }
//         span {
//             max-width: 100%;
//         }
//     }
// }
