.auth-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;

    @media (max-width: 750px) {
        justify-content: start;
        margin-top: 40px;
    }
}
.authLayoutAccCreated {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 0.995;
    overflow: hidden;
}

@media (max-width: 550px) {
    .auth-layout {
        padding-left: 24px;
        padding-right: 24px;
    }
}
