.blogCard {
  max-width: 1072px;
  width: 100%;
  min-height: 194px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  user-select: none;
  position: relative;
  flex-direction: column-reverse;
  gap: 20px;
  transition: 0.3s;
  border-radius: 6px;

  .blogCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .blogCardHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    p {
      color: rgb(85, 87, 92);
      font-size: 12px;
      margin: 0px;
      cursor: pointer;

      &:hover {
        color: #2b2b2b;
      }
    }
  }

  .blogCardMain {
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: rgb(43, 45, 51);
      margin: 8px 0px;
      text-decoration: none;
      font-family: Navigo, Helvetica, "Helvetica New", Arial, sans-serif;
      cursor: pointer;
    }
    span {
      color: rgb(85, 87, 92);
      font-size: 12px;
      line-height: 22px;
      margin: 0px;
      width: inherit;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .blogCardFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: 14px;
    margin-top: 16px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      // appearance: none;
      border-radius: 8px;
      font-weight: 500;
      transition: 0.4s;
      font-size: 14px;
      line-height: 22px;
      padding: 0px 20px;
      height: 36px;
      min-width: 36px;
      color: rgb(43, 45, 51);
      background-color: transparent;
      border: 2px solid rgb(213, 213, 214);

      &:hover {
        border-color: rgb(43, 45, 51);
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      p {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: rgb(43, 45, 51);
        gap: 8px;
      }
    }
  }

  .cardImg {
    width: 100%;
    height: 222px;
    transition: 0s !important;
    border-radius: 12px;
    object-fit: cover;

    @media (max-width: 400px) {
      height: 200px ;
    }
    @media (max-width: 360px) {
      height: 180px ;
    }
  }
}
