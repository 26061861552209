.acc-tariffs {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .acc-tariffs-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;

        .go-back-tariffs {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .status-card {
        margin-top: 2px;
        p {
            font-size: 12px !important;
        }
    }
    .acc-tariffs-main {
        width: 100%;
        max-width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 8px 16px 8px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        table {
            border-spacing: 1;
            border-collapse: collapse;
            border-radius: 6px;
            overflow: hidden;
            width: 100%;

            // th {
            //     padding: 40px 16px;
            //     text-align: center !important;
            //     font-family: "SpaceGrotesk";
            //     font-size: 28px;
            // }

            tbody {
                td {
                    height: auto;
                    padding: 18px 18px 18px 0px;
                    text-align: right !important;
                    font-size: 16px;
                    color: rgba(43, 47, 56, 1);
                }
            }
            span {
                font-size: 12px;
            }
            .title {
                text-align: left !important;
                padding-left: 18px;
                font-size: 14px;
                color: rgba(43, 47, 56, 1);
                width: 80% !important;
            }
            .lightTitle {
                // color: rgba(122, 132, 153, 1);
                color: rgba(43, 47, 56, 1);
                font-weight: 600;
                font-size: 12px !important;
                width: 100%;
                padding: 18px 18px 0 18px;
            }
            .darkLine {
                td {
                    box-shadow: inset 0px 0px 100px 1px rgba(99, 99, 99, 0.1) !important;
                }
            }

            tbody tr {
                background: rgba(230, 231, 235, 0.4);
                &:last-child {
                    border: 0;
                }
            }
        }
    }
}
