.personal-info-card-label {
    width: 100%;
    text-align: left;
    color: #2b2f38;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
}
.personal-info-card {
    width: 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);
    gap: 10px;

    p {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
    .warning-icon {
        position: relative;

        &:after {
            content: "";
            background-image: url("../../../../assets/WarningAcc.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px;
            position: absolute;
            right: -14px;
            top: -4px;
            width: 16px;
            height: 14px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        max-width: 60%;

        span {
            font-size: 16px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            text-align: start;
            overflow: hidden;
        }
        img {
            cursor: pointer;
        }
    }
}
.security-card {
    cursor: pointer;
}
@media (max-width: 348px) {
    .personal-info-card-media {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding-block: 8px;

        div {
            max-width: 100%;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 300px) {
                span {
                    text-align: left;
                    width: 70%;
                    overflow: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
}

.personal-info-card-checkbox {
    $toggle-indicator-size: 24px;
    $track-height: $toggle-indicator-size + 6;
    $track-width: $toggle-indicator-size * 2;

    .toggle {
        align-items: center;
        border-radius: 100px;
        display: flex;
        font-weight: 700;
        margin-bottom: 16px;

        &:last-of-type {
            margin: 0;
        }
    }
    .toggle__input {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        // &:not([disabled]):active + .toggle-track,
        // &:not([disabled]):focus + .toggle-track {
        //     border: 1px solid transparent;
        //     box-shadow: 0px 0px 0px 2px black;
        // }

        &:disabled + .toggle-track {
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
    .toggle-track {
        background: rgba(230, 231, 235, 1);
        border: 1px solid rgba(122, 132, 153, 1);
        border-radius: 100px;
        padding: 0 4px 0 8px;
        cursor: pointer;
        width: $track-width;
        height: $track-height;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .toggle-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(122, 132, 153, 1);
        border-radius: $toggle-indicator-size;
        width: 14px;
        height: 14px;
        transition: 0.3s;
    }

    .checkMark {
        fill: rgba(0, 15, 48, 1);
        height: 16px;
        width: 16px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        margin: 0 0px 0.3px 0.4px;
    }
    .toggle__input:checked + .toggle-track {
        background: rgba(0, 15, 48, 1);
        border: 1px solid rgba(0, 15, 48, 1);
    }
    .toggle__input:checked + .toggle-track .toggle-indicator {
        background: rgba(242, 243, 245, 1);
        transform: translateX($track-width - $track-height - 6);
        width: 22px;
        height: 22px;

        .checkMark {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
}
