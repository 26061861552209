.loading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    animation: bacgroundL 0.18s linear;

    @keyframes bacgroundL {
        from {
            opacity: 0.7;
        }
        to {
            opacity: 1;
        }
    }

    .loader-mobile {
        display: block;
    }
    .loader {
        display: none;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: rgba(248, 0, 158, 1);
    }

    @media (max-width: 750px) {
        .loader-mobile {
            display: none;
        }
        .loader {
            position: absolute;
            display: block;
        }
        p {
            font-size: 10px;
            font-weight: 400;
            color: rgba(122, 132, 153, 1);
        }
    }
}

.circle-spinner {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    position: relative;
}

.loader-mobile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, #ef0199 94%, #0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%, #ef0199);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: loader 1.4s infinite linear;
}
.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, #ef0199 94%, #0000) top/9px 9px no-repeat,
        conic-gradient(#0000 30%, #ef0199);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: loader 1.4s infinite linear;
}
@keyframes loader {
    100% {
        transform: rotate(1turn);
    }
}

//....................../-UPGRADE-/......................//

// .dots {
//     display: inline-flex;

//     .dot {
//         display: inline-block;
//         width: 10px;
//         height: 10px;
//         background: #212121;
//         border-radius: 10px;
//         position: relative;
//         margin-left: 6px;
//     }

//     .z {
//         position: absolute;
//         transform: scale(0);
//         animation: scale 0.8s 0.2s forwards;
//     }
//     .f,
//     .s {
//         transform: translateX(0px);
//         animation: right 0.5s forwards;
//     }
//     .t {
//         background: transparent;
//     }
//     .l {
//         margin-left: 0;
//         position: absolute;
//         top: 0;
//         left: 0;
//         animation:
//             rightDown 0.4s 0.1s forwards linear,
//             drop 2s 0.4s forwards linear;
//     }
//     @keyframes scale {
//         100% {
//             transform: scale(1);
//         }
//     }

//     @keyframes right {
//         100% {
//             transform: translateX(16px);
//         }
//     }

//     @keyframes rightDown {
//         50% {
//             top: 4px;
//             left: 16px;
//         }
//         100% {
//             top: 12px;
//             left: 24px;
//         }
//     }

//     @keyframes drop {
//         100% {
//             transform: translate(70px, calc(35px + (100vh / 2)));
//         }
//     }
// }

// const dotsRef = useRef<HTMLDivElement | null>(null);

// useEffect(() => {
//   const animate = (element: HTMLElement, className: string) => {
//     element.classList.add(className);
//     setTimeout(() => {
//       element.classList.remove(className);
//       setTimeout(() => {
//         animate(element, className);
//       }, 500);
//     }, 2500);
//   };

//   if (dotsRef.current) {
//     animate(dotsRef.current, "dots--animate");
//   }
// }, []);

// <h1>
// Loading
// <div className="dots">
//   <span className="dot z"></span>
//   <span className="dot f"></span>
//   <span className="dot s"></span>
//   <span className="dot t">
//     <span className="dot l"></span>
//   </span>
// </div>
// </h1>
