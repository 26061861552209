@import "../../../styles/main.scss";

.chat-support {
  max-width: 452px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: rgba(242, 243, 245, 1);
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 20;

  .chat-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  .chat-support-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    min-height: 72px;
    background: rgba(242, 243, 245, 0.34);
    // backdrop-filter: blur(10px);
    .go-back-portfolio {
      cursor: pointer;
      position: absolute;
      left: 24px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #2b2f38;
    }
  }
  .chat-support-main {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 48px 24px 16px 24px;
    margin-bottom: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    // &::-webkit-scrollbar {
    //     width: 8px;
    //     background-color: #f5f5f5;
    // }
    // &::-webkit-scrollbar-thumb {
    //     border-radius: 4px;
    //     background-color: #555;
    // }
    // align-items: end;
    // justify-content: flex-end;
    // background-color: rgb(247, 112, 112);
  }
  .chat-support-main > :first-child {
    margin-top: auto !important;
  }
  .send-message-box {
    position: fixed;
    max-width: 450px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    background: inherit;
    justify-content: space-between;
    gap: 16px;
    padding: 12px 24px 32px 24px;
    bottom: 0;

    img {
      cursor: pointer;
    }
    .send-message-btn {
      padding-block: 4px;
    }
    .disabled-send-btn {
      filter: opacity(0.3) brightness(0.8);
      transition: 0.3;
    }
    textarea {
      resize: none;
      background-color: white;
      width: 100%;
      min-height: 48px;
      max-height: 64px;
      padding: 12px;
      border-radius: 12px;
      text-align: left;
      font-size: 16px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &::-moz-selection {
        color: black;
        background: rgba(43, 47, 56, 0.3);
      }
      &::selection {
        color: black;
        background: rgba(43, 47, 56, 0.3);
      }

      &:focus {
        outline: none;
      }
    }
    .recording__container {
      animation: record 0.7s ease-in-out;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: baseline;
      position: relative;

      .audio-recorder,
      .recording {
        background-color: white;
        width: 100%;
        min-height: 48px;
        height: 48px;
        padding: 12px 12px 12px 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 12px;
        font-size: 16px;
        // box-shadow: none !important;
        .audio-recorder-options,
        .audio-recorder-mic {
          display: none;
        }
        .audio-recorder-visualizer {
          @include flex-center;
          margin: 0px !important;

          canvas {
            width: 70%;
            height: 30px;
            filter: invert(0.5) sepia(1) saturate(3) hue-rotate(265deg) drop-shadow(0px 0px 40px #ff00a2);

            @media (max-width: 345px) {
              width: 55%;
            }
          }
        }
        .audio-recorder-status {
          font-size: 0;
          color: white;
          margin-left: 8px;
          animation-duration: 1.8s;
          flex-grow: unset;

          .audio-recorder-status-dot {
            height: 10px;
            width: 10px;
            border-radius: 100%;
          }
        }
        .audio-recorder-timer {
          position: absolute;
          color: #4e5566;
          font-size: 12px !important;
          order: 2;
          margin: 0px;
          margin-right: 5px;
        }
      }
      .delete-record {
        position: absolute;
        left: 12px;
        width: 32px;
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        background-color: inherit;
        background-image: url("../../../assets/DeleteVoice.svg");
        transition: 0.3s;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 22px;

        &:hover {
          background-image: url("../../../assets/DeleteVoiceHovered.svg");
        }
      }
    }
    @keyframes record {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .message-date {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(249, 249, 249, 1);
    position: fixed;
    z-index: 10;
    top: 80px;
    left: 0px;
    right: 0px;

    p {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 8px 16px;
      border-radius: 25px;
      text-align: center;
    }
  }
  .message-date-label {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(249, 249, 249, 1);
    // position: sticky;
    z-index: 10;
    top: 10px;
    width: 100%;
    margin: 8px 0;

    p {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 8px 16px;
      border-radius: 25px;
      text-align: center;
    }
  }
  .message-date-hide {
    animation: HideDate 1.2s;
    opacity: 0;
  }
  @keyframes HideDate {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      // display: none;
    }
  }

  .message-box {
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    gap: 8px;

    .support-icon {
      width: 40px;
      height: 40px;
    }

    &.message-admin-box,
    &.message-gpt-box {
      justify-content: flex-start;
    }
  }
  .command-question-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    gap: 8px;
    row-gap: 16px;
    padding-top: 16px;

    .qa-btn {
      position: absolute;
      left: 0;
      bottom: 0;
      font-weight: 600;
      cursor: pointer;
    }
    .qa-btn-false {
      color: rgba(77, 89, 117, 0.5);
    }
  }
  .page-down-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 24px;
    bottom: 115px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.95);
    cursor: pointer;
    overflow: hidden;

    img {
      width: 28px;
      opacity: 0.7;
    }

    &:hover {
      background: whitesmoke;
    }
  }
  .page-down-icon-hide {
    animation: HidePD 1s;
    height: 0px;
    width: 0px;
    opacity: 0;
  }
  .page-down-icon-static {
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 24px;
    bottom: 115px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.95);
    cursor: pointer;
    overflow: hidden;
    opacity: 1 !important;

    img {
      width: 28px;
      opacity: 0.7;
    }
    &:hover {
      background: rgb(248, 248, 248);
    }
  }

  @keyframes HidePD {
    0% {
      opacity: 1;
      height: 40px;
      width: 40px;
    }
    100% {
      height: 40px;
      width: 40px;
      opacity: 0;
    }
  }
}
