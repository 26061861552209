@import "../../../../styles/main.scss";
.bonus-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 285px;
    padding: 24px;
    gap: 20px;
    background-color: white;
    border-radius: 16px;
    position: relative;

    .bonus-points {
        position: absolute;
        right: 24px;
        top: -16px;
        background-color: rgba(115, 243, 94, 1);
        padding: 5.5px 8px;
        border-radius: 24px;
        height: 32px;
        max-width: 50%;
        min-width: 70px;

        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: rgba(43, 47, 56, 1);
        }
    }

    p {
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        color: rgba(43, 47, 56, 1);
    }
    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: rgba(78, 85, 102, 1);
    }
    a {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .timer-check {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        span {
            @include flex-center;
            gap: 6px;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            text-align: left;
            color: rgba(0, 15, 48, 1);
        }

        button {
            min-width: 90px;
            height: 45px;
            padding: 12px 24px;
            gap: 8px;
            border-radius: 12px;
            background-color: rgba(248, 0, 158, 1);
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.0075em;
            color: rgba(249, 249, 249, 1);

            &:hover {
                background-color: rgb(231, 4, 148);
            }
            &:active {
                background-color: rgb(231, 4, 148);
            }
        }
    }
}
