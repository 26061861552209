.sort-modal {
    width: 100%;
    max-width: 450px;
    max-height: 50vh;
    height: auto;
    padding: 0px 0px 40px 0px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: sort 0.5s ease-out;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .sort-modal-header {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: start;
        border-radius: 16px 16px 0px 0px;
        justify-content: space-between;
        padding: 24px 24px 10px 24px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        background-color: rgba(249, 249, 249, 1);
        p {
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
        span {
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            // color: rgba(122, 132, 153, 1);
            color: rgba(0, 15, 48, 1);
        }
    }

    .sort-row {
        width: 100%;
        height: 56px;
        min-height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 24px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        cursor: pointer;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
            }
        }
    }
    .sort-row-active {
        background: rgba(230, 231, 235, 1);
    }
    button {
        width: 85%;
        margin-top: 16px;
        height: 52px;
        min-height: 52px;
        overflow: hidden;
    }
    .sort-configs-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        gap: 6px;
        button {
            width: 75%;
            height: 52px;
            min-height: 52px;
        }
        .reset-sort-config {
            width: 25%;
            height: 52px;
            min-height: 52px;
            text-align: center;
            word-break: break-all;
            line-height: 18px;
        }
    }
}
@keyframes sort {
    from {
        max-height: 0;
    }
    to {
        max-height: 50vh;
    }
}
.outlet-sort-modal {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0;
    z-index: 100;
    position: absolute;
}
