.sign-business-contract {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .sign-business-contract-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 78px;
        min-height: 78px;
        margin-bottom: 12px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }

    .sign-business-contract-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 32px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .register-title {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-block: 20% 10%;
            width: 90%;
            color: rgba(43, 47, 56, 1);
        }

        h1 {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            width: 90%;
        }
        // .warnings {
        //     margin-block: 40px 32px;
        //     width: 40%;
        //     max-width: 555px;
        //     height: 98px;
        //     padding: 24px;
        //     border-radius: 24px;
        //     border: 2px solid rgba(47, 128, 237, 1);
        //     background-color: rgba(236, 244, 253, 1);

        //     ul {
        //         padding-left: 20px;
        //         li {
        //             font-size: 14px;
        //             font-weight: 500;
        //             color: rgba(43, 47, 56, 1);
        //         }
        //     }
        // }
        .documents {
            width: 100%;
            height: auto;
            padding: 0 !important;
            border-radius: 36px;
            background: inherit;
            counter-reset: section;

            .confirmCorrectness {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-top: 20px;

                h2 {
                    font-size: 20px;
                    line-height: 24px;
                }
                h3 {
                    margin-block: 8px 20px;
                    font-size: 14px;
                }
                .signContractBtn {
                    width: 100%;
                }
            }
        }
    }

    .documentRow {
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;

        span {
            width: 100%;
            text-align: left;
        }
        .fileArea {
            width: 100%;
        }
        .oploudBlock,
        .oploudFileBox {
            width: 100% !important;
        }
    }

    .warnings {
        width: 100%;
        padding: 18px 24px;
        border-radius: 24px;
        border: 2px solid rgba(47, 128, 237, 1);
        background-color: rgba(236, 244, 253, 1);
        margin-block: 12px -12px;

        ul {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            margin: 0;
            gap: 4px;
            li {
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                color: rgba(43, 47, 56, 1);
            }
        }
    }
    .personal-address-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-block: 24px ;
        p {
            font-size: 16px;
            font-weight: 400;
            color: rgb(43, 47, 56);
            display: flex;
            width: 100%;
            span{
                margin-right: 8px;
            }
        }
    }

    .dateInputCards {
        display: flex;
        gap: 16px;
        position: relative;
    }
    .btn-wrapper {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 12px !important;
    }
    @media (max-width: 750px) {
    }
}

// ! OLD STYLES (FULL SCREEN) !

// .signContractPage {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     margin-bottom: 32px;

//     h1 {
//         font-family: "SpaceGrotesk";
//         font-size: 28px;
//         line-height: 31px;
//         font-weight: 600;
//         color: rgba(43, 47, 56, 1);
//         margin-bottom: 40px;
//     }
//     .warnings {
//         margin-block: 40px 32px;
//         width: 40%;
//         max-width: 555px;
//         height: 98px;
//         padding: 24px;
//         border-radius: 24px;
//         border: 2px solid rgba(47, 128, 237, 1);
//         background-color: rgba(236, 244, 253, 1);

//         ul {
//             padding-left: 20px;
//             li {
//                 font-size: 14px;
//                 font-weight: 500;
//                 color: rgba(43, 47, 56, 1);
//             }
//         }
//     }
//     .documents {
//         width: 1140px;
//         height: auto;
//         padding-block: 24px 64px;
//         border-radius: 36px;
//         background: rgba(249, 249, 249, 1);
//         box-shadow: rgba(51, 51, 79, 0.12);
//         counter-reset: section;

//         .confirmCorrectness {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             text-align: center;
//             margin-top: 40px;

//             h2 {
//                 font-size: 28px;
//                 line-height: 30px;
//                 font-weight: 600;
//                 color: rgba(43, 47, 56, 1);
//             }
//             h3 {
//                 margin-block: 16px 40px;
//                 font-size: 14px;
//                 font-weight: 500;
//                 color: rgba(78, 85, 102, 1);
//             }
//             .signContractBtn {
//                 width: 360px;
//                 // border-radius: 12px;
//             }
//         }
//     }
// }
// @media (max-width: 1250px) {
//     .signContractPage {
//         .documents {
//             width: 90% !important;
//             height: auto;
//             padding-block: 24px 64px;
//             border-radius: 36px;
//             background: rgba(249, 249, 249, 1);
//             box-shadow: rgba(51, 51, 79, 0.12);
//             counter-reset: section;

//             .confirmCorrectness {
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 justify-content: center;
//                 text-align: center;
//                 margin-top: 40px;

//                 h2 {
//                     font-size: 28px;
//                     line-height: 30px;
//                     font-weight: 600;
//                     color: rgba(43, 47, 56, 1);
//                 }
//                 h3 {
//                     margin-block: 16px 40px;
//                     font-size: 14px;
//                     font-weight: 500;
//                     color: rgba(78, 85, 102, 1);
//                 }
//                 .signContractBtn {
//                     width: 360px;
//                     // border-radius: 12px;
//                 }
//             }
//             .documentRow {
//                 // background: rgba(249, 249, 249, 1);
//                 // box-shadow: rgba(51, 51, 79, 0.12);
//                 span {
//                     width: 63%;
//                     margin-right: 20px;
//                 }
//             }
//         }
//         .warnings {
//             margin-block: 40px 24px;
//             width: 80%;
//             max-width: 555px;
//             height: auto;
//         }
//     }
// }
// @media (max-width: 1024px) {
//     .signContractPage {
//         .warnings {
//             margin-block: 40px 24px;
//             width: 80%;
//             max-width: 555px;
//             height: auto;
//         }
//         .documents {
//             width: 100% !important;
//             height: auto;
//             padding-block: 24px 64px;
//             border-radius: 36px;
//             background: inherit;
//             box-shadow: none;

//             .confirmCorrectness {
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 justify-content: center;
//                 text-align: center;
//                 margin-top: 40px;
//             }
//             .documentRow {
//                 justify-content: space-between;
//                 span {
//                     width: 63%;
//                     margin: 0;
//                 }
//                 .oploudFileBox,
//                 .oploudFileComplete,
//                 .addDocName {
//                     width: 100%;
//                 }
//                 .oploudBlock,
//                 .oploudFileBox {
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 750px) {
//     .signContractPage {
//         margin-bottom: 0px;
//         margin-top: 0px;

//         .warnings {
//             margin-block: 40px 16px;
//             width: 75%;
//             max-width: 555px;
//         }
//         .documents {
//             width: 75% !important;
//             padding: 0;

//             .documentRow {
//                 flex-direction: column;

//                 span {
//                     width: 100%;
//                 }
//                 .fileArea {
//                     width: 100%;
//                 }
//                 .oploudBlock,
//                 .oploudFileBox {
//                     width: 100% !important;
//                 }

//                 p{
//                     width: 100%;
//                 }
//             }

//         }
//     }
// }
// @media (max-width: 500px) {
//     .signContractPage {
//         margin-bottom: 0px;
//         margin-top: 0px;

//         .warnings {
//             margin-block: 40px 16px;
//             width: 100%;
//             max-width: 555px;
//         }
//         .documents {
//             width: 100% !important;
//             padding: 0;

//             .confirmCorrectness {

//                 .signContractBtn {
//                     width: 100%;
//                 }
//             }

//             .documentRow {
//                 padding-left: 0;
//                 padding-right: 0;
//                 flex-direction: column;

//                 span {
//                     width: 100%;
//                 }
//                 .fileArea {
//                     width: 100%;
//                 }
//                 .oploudBlock,
//                 .oploudFileBox {
//                     width: 100% !important;
//                 }
//             }
//         }
//     }
// }
