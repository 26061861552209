.status-card {
    display: flex;
    align-items: center;
    justify-content: center;   
    margin-top: 8px; 
    img{
        margin-right: 8px;
    }
    p {
        font-size: 14px !important;
        font-weight: 600;
        color: rgba(78, 85, 102, 1) !important;
    }
}
