.strategy-card {
    width: 100%;
    height: 160px;
    min-height: 160px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &.strategy-card-1 {
        background: linear-gradient(76.95deg, #099773 -1.99%, #43b692 110.64%);
    }
    &.strategy-card-2 {
        background: linear-gradient(256.2deg, #6eee87 -7.74%, #5fc52e 100%);
    }
    &.strategy-card-3 {
        background: linear-gradient(77deg, #ff0f7b -0.99%, #f89b29 106.54%);
    }

    .strategy-card-asset {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        img {
            width: 24px;
            height: 24px;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #f9f9f9;
        }
    }
    .strategy-card-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;

        .strategy-card-payment-details {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            span {
                height: 26px;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0em;
                text-align: center;
                padding: 8px 8px;
                background: #00000026;
                border-radius: 12px;
                color: rgba(255, 255, 255, 0.861);
            }
            p {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #f9f9f9;
            }
        }
        .strategy-card-profit {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 5px;

            p {
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
                color: #f9f9f9;
            }
            span {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #f9f9f9dc;
            }
        }
    }
    .st-background-icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .custom-st-background-icon {
        position: absolute;
        width: 101%;
        left: -0.5px;
        top: -1px;
        height: 101%;
        object-position: left;
        object-fit: cover;
    }
}
