.assignments-page {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    overflow-x: hidden;
    top: 0;
    z-index: 20;

    .assignments-page-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        min-height: 72px;
        margin-bottom: 12px;

        .go-back-assignments {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        .history-assignments {
            cursor: pointer;
            position: absolute;
            right: 24px;
            width: 20px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .assignments-page-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 24px;
        padding-right: 24px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .assignments-page-footer {
        width: 100%;
        height: 100px;
        bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        background-color: rgb(255, 255, 255);
        // box-shadow: 0px 0px 12px 3px #00000020;
        box-shadow: 0px 0px 12px 3px #d4d4d480;
        // border-top: 1.5px solid #d4d4d4;
        border-radius: 16px 16px 0 0;
        padding: 24px;
        transition: 0.4s;

        .assignments-page-footer-main {
            display: none;
        }

        .assignments-page-footer-box {
            width: 100%;
            height: 45px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            background: #fef0d8;
            font-weight: 600;
            padding: 16px;

            img {
                width: 24px;
                height: 24px;
                padding: 2.5px;
                padding-top: 2.2px;
                border-radius: 100%;
                background: #ffa833;
                fill: white;
            }

            &:hover,
            &:active {
                background: #ffebc8;
                // background: #ffedce;
            }
        }
    }
    .assignments-page-footer-opened {
        width: 100%;
        height: calc(100% - 72px);
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: absolute;
        background-color: rgb(255, 255, 255);
        // box-shadow: 0px 0px 12px 3px #00000020;
        box-shadow: 0px 0px 12px 3px #d4d4d430;
        // border-top: 1.5px solid #d4d4d4;
        border-radius: 16px 16px 0 0;
        padding: 24px;
        padding-bottom: 0;
        transition: 0.3s;

        .assignments-page-footer-box {
            width: 100%;
            height: 45px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            background: #fef0d8;
            font-weight: 600;
            padding: 16px;

            img {
                width: 24px;
                height: 24px;
                padding: 2.5px;
                padding-top: 2.2px;
                border-radius: 100%;
                background: #ffa833;
                fill: white;
                rotate: 180deg;
            }

            &:hover,
            &:active {
                background: #ffebc8;
                // background: #ffedce;
            }
        }

        .assignments-page-footer-main {
            width: 98%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            margin-top: 20px;
            padding-bottom: 24px;
        }
    }
}
