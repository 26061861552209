.dateBox {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .registerNameBtn {
        margin-top: 56px;
    }
    label {
        font-size: 14px;
        font-weight: 600;
        color: #4e5566;
    }
    .large {
        width: 140px !important;
    }
    .date-error-large {
        max-width: 140px !important;
    }
    .dateInput {
        width: 100px;
        height: 56px;
        text-align: center;
        border-radius: 12px;
        border: 1px solid #bfc4ce;

        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #2b2f38;
        margin-bottom: 56px;

        &:hover {
            background-color: #f2f3f5;
        }
        &:focus {
            border: 2px solid #000f30;
        }
    }
    .date-error-message {
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        color: #eb5757;
        max-width: 100px;
        margin-bottom: 30px;
    }
    .input-error {
        margin-bottom: 0px;
        border: 2px solid #eb5757;

        &:focus {
            border: 2px solid #eb5757;
        }
    }
    .input-success {
        border: 2px solid #000f30;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input {
        &:focus {
            outline: none;
        }
        &:active {
            outline: none;
        }
    }
}
@media (max-width: 750px) {
    .dateBox {
        width: 30%;
        
        .date-error-large {
            max-width: 140px !important;
        }
        .dateInput {
            width: 100%;
            margin-bottom: 0;
        }
        .large {
            width: 100% !important;
        }
        .date-error-message {
            max-width: 140px;
            margin: 0;
        }
    }
}
