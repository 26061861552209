.forgetPasswordPage {
    height: auto !important;
    padding-bottom: 24px;

    .password-login-choose-acc-type {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin-bottom: 24px;
        margin-top: -24px;
        // background-color: red;

        button {
            height: 46px;
            width: 40%;
            max-width: 185px;
            overflow: hidden;

            &:nth-child(1) {
                border-radius: 12px 2px 2px 12px;
            }
            &:nth-child(2) {
                border-radius: 2px 12px 12px 2px;
            }
        }
        .active-type-acc {
            background-color: rgb(0, 15, 48);
            color: white;
        }
    }
}
