.blogPage {
    max-width: 450px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    position: absolute;
    top: 0;
    z-index: 40;
    overflow-x: hidden;

    .blog-page-go-back {
        cursor: pointer;
        position: absolute;
        top: 24px;
        left: 24px;
        width: 31px;
        height: 30px;
        border-radius: 100%;
        padding: 3px 3.5px 3px 2.5px;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        transition: 0.1s;
        z-index: 15;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);

        img {
            filter: brightness(0);
        }

        &:hover {
            background-color: white;
        }
    }

    .blog-content-wrapper {
        width: 100%;
        height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        // padding: 32px 24px;
        padding: 64px 24px 30.5px 24px;
        z-index: 10;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .blogPageHeader {
        width: 100%;
        border-radius: 24px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        gap: 0px;
        margin-bottom: 24px;

        .blogPageIconAbsolute {
            width: 150%;
            height: 300px;
            position: absolute;
            filter: blur(200px) brightness(1.2);
            opacity: 0.8;
            top: -150px;
            left: -25%;
            z-index: 0;
        }

        .blogPageIcon {
            width: 100%;
            height: 170px;
            border-radius: 8px;
            object-fit: cover;
            z-index: 2;
        }

        .blogPageHeaderLeft {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            padding: 8px 6px;
            z-index: 2;

            .blogCategoryWrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                span {
                    color: rgb(76, 76, 76);
                    font-size: 12px;
                    margin: 0px;
                }
            }
            p {
                font-family: Navigo, Helvetica, "Helvetica New", Arial, sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                margin-block: 8px 5%;
                color: rgb(43, 45, 51);
                border-radius: 6px;
            }

            div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 14px;

                p {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    line-height: 12px;
                    color: rgb(43, 45, 51);
                    gap: 8px;
                    font-weight: 400;
                }
            }
        }
    }

    .blogPageSubtitleSection {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15%;
        padding-block: 40px !important;
        max-width: 1072px;
        flex-direction: column-reverse;
        padding: 0 2px;

        .blogPageSubtitle {
            font-weight: 400;
            width: 100%;
            font-size: 16px;
            line-height: 22px;
            text-align: justify;
        }

        div {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 4px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            width: 100%;
            border-bottom: 1.5px solid rgb(233, 234, 234);

            span {
                font-family: "ALS Hauss", Helvetica, "Helvetica New", Arial, sans-serif;
                font-size: 14px;
                line-height: 22px;
            }

            p {
                font-family: "ALS Hauss", Helvetica, "Helvetica New", Arial, sans-serif;
                font-size: 16px;
                font-weight: 900;
                line-height: 26px;
            }
        }
    }

    .blogPageContent {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 12px;
        // max-width: 1072px;

        // .T-bank {
        //     background-color: white;
        //     padding: 32px;
        //     border-radius: 12px;
        // }

        h1,
        h2,
        h3,
        h4 {
            margin-bottom: 32px;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
        }

        p {
            line-height: 22px;
            font-size: 16px;
            margin-bottom: 24px;
            text-align: justify;
        }

        a {
            // color: #f5a3e6;
            color: #f8009e;
            text-decoration: none;
        }

        video {
            margin: auto;
            width: 60%;
            margin-bottom: 32px;

            @media (max-width: 750px) {
                width: 100%;
            }
        }

        .blogPageSectionImg {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;

            img {
                width: 60%;
                border-radius: 8px;

                @media (max-width: 750px) {
                    width: 100%;
                }
            }
        }

        .blogPageSectionImgs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 46px;
            // gap: 20px;

            img {
                width: 49%;
                border-radius: 8px;
            }
        }

        .blogPageSectionInformation,
        blockquote {
            display: flex;
            align-items: flex-start;
            position: relative;
            font-size: 14px;
            border-radius: 8px;
            padding: 16px 24px 16px 16px;
            width: 100%;
            gap: 12px;
            margin-bottom: 32px;
            color: rgb(43, 45, 51);
            background-color: rgb(235, 235, 235);

            p {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
                text-align: left !important;
            }

            &::before {
                content: "";
                background-image: url("../../../assets/BlogInformation.svg");
                background-size: cover;
                width: 22px;
                min-width: 22px;
                height: 22px;
                min-height: 22px;
            }
        }

        ul,
        ol {
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 16px !important;
            list-style-position: outside;
            margin-bottom: 35px;

            li {
                text-align: justify;
                font-size: 16px;
                font-weight: 400;
                // color: rgb(78, 85, 102);
                color: rgb(43, 45, 51);

                &::marker {
                    // color: #5d083e37;
                    color: #fc4fbc6d;
                    // color: #fc4fbcb3;
                }

                img {
                    border-radius: 6px;
                    width: 100%;
                }
            }
        }

        figure {
            width: 100%;
            height: auto;
            max-height: 250px !important;
            border-radius: 8px;

            div {
                width: 100% !important;
                height: auto !important;
                max-height: 250px !important;
            }

            img {
                border-radius: 6px;
                width: 100%;
                max-height: 250px !important;
                object-fit: contain;
            }

            figcaption{
                font-style: italic;
            }

            margin-bottom: 20px;
        }

        // .blogPageSectionImgs {
        //     width: 100%;
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        //     margin-bottom: 46px;
        //     // gap: 20px;

        //     img {
        //         width: 49%;
        //         border-radius: 8px;
        //     }
        // }

        img {
            margin-block: 12px;
            border-radius: 6px;
            width: 100%;
            max-height: 250px !important;
            object-fit: contain;
        }
    }

    .disclaimer-blog-btn {
        cursor: pointer;
        max-width: 180px;
        height: 40px;
        min-height: 40px;
        padding: 8.25px 20px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        overflow: hidden;
        text-decoration: none;
        color: black;
        border-radius: 24px;
        border: 1px solid #dbdbdb;
        transition: 0.2s ease-in-out;

        &:hover {
            border-color: black;
        }
    }
    .page-up {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid #dbdbdb;
        background-color: white;
        position: absolute;
        bottom: 24px !important;
        right: 24px;
        overflow: hidden;
        transition: all 0.3s;
        z-index: 10;

        img {
            transition: 0.5s;
            width: 24px;
            height: 24px;
        }

        &:hover {
            border-color: #0c0c0c;

            img {
                filter: brightness(0%);
            }
        }
    }

    @keyframes pageUp {
        0% {
            border-color: #dbdbdb;
        }
        50% {
            border-color: #0000006c;
            box-shadow: 0px 0px 8px 1px #00000040;
        }
        100% {
            border-color: #dbdbdb;
        }
    }
    @keyframes pageUpImg {
        50% {
            filter: brightness(50%);
        }
    }
}
