.notification-nav-icon {
    width: 16px !important;
    height: 16px !important;
    border-radius: 100%;
    background: rgba(253, 42, 176, 0.9) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 28%;
    bottom: 28%;
    animation: notif 10s infinite;

    p {
        color: black;
        font-weight: 600;
        font-size: 12px !important;
    }
}
@keyframes notif {
    0% {
        opacity: 0;
    }
    12% {
        opacity: 1;
    }
    88% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.notification-icon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
    animation: notif 10s infinite;

    p {
        color: #000f30 !important;
        font-weight: 600;
        font-size: 12px !important;
    }
}
