.portfolio-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-block: 8px 20px;

    .category-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: rgba(43, 47, 56, 1);
        margin-bottom: 6px;
    }

    button {
        margin-top: 16px;
        width: 126px;
        height: 45px;
        border-radius: 12px;
        background: rgba(230, 231, 235, 0.8);
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        text-align: center;
        color: rgba(0, 15, 48, 1);
        padding: 0 4px;

        &:hover {
            background: rgba(230, 231, 235, 1);
        }
        &:active {
            background: rgb(210, 211, 214);
        }
    }
}
