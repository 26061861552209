.input-container {
    position: relative;

    input {
        padding-right: 75px;
    }

    input[type="password"] {
        letter-spacing: 0.5px;
        font-size: 18px;
    }

    i {
        position: absolute;
        right: 30px;
        top: 17px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
        z-index: 15;
        cursor: pointer;
    }


}
