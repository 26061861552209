.portfolio-row-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 8px 2px;
    gap: 10px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);
    cursor: pointer;
    position: relative;

    &:nth-last-child(1) {
        border-bottom: none;
    }
    .price-quantity-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        text-align: left;
        z-index: 4;

        img {
            width: 40px;
            height: 40px;
            max-width: 40px;
        }

        .price-quantity {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 1px;

            p {
                margin: 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
            }
            span {
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
        }
    }

    .portfolio-price-percent {
        display: flex;
        flex-direction: column;
        text-align: right;
        z-index: 4;

        .portfolio-profit-loss {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin: 0;
        }
        span {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.005em;
        }
        .profit {
            color: rgba(39, 174, 96, 1);
        }
        .loss {
            color: rgba(235, 87, 87, 1);
        }
    }

    .non-trading-background {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-image: url("../../../../assets/NotSellableItemBg.png");
        background-position: center;
        background-size: cover;
        opacity: 0.7;
    }

    .not-sellable-price-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;

        .not-sellable {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            padding-top: 2px;

            img {
                cursor: pointer;
                height: 20px !important;
                width: 60px;
                opacity: 0.8;
            }

            .not-sellable-popup-wrapper {
                transition: 0.3s;
                transition-delay: 0.2s;
                opacity: 0;
                position: absolute;
                bottom: 90%;
                z-index: 15;

                .not-sellable-popup {
                    min-height: 17px;
                    max-height: 50px;
                    max-width: 200px;
                    min-width: 150px;
                    padding: 4px;
                    border-radius: 5px;
                    background-color: #131722ab;
                    font-size: 0.65em;
                    color: white;
                    line-height: 12px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    white-space: wrap;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -4px;
                        width: 4px;
                        height: 4px;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid #131722ab;
                    }
                }
            }
        }
        &:hover {
            .not-sellable-popup-wrapper {
                bottom: 110%;
                opacity: 1;
            }
        }
    }
}
