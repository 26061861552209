.researchCard {
  height: 300px;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.25s;

  .researchHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-wrap: wrap;
    gap: 8px;

    p {
      padding: 5px 10px 4.5px 10px;
      margin: 0;
      border-radius: 10px;
      font-size: 8px;
      line-height: 8px;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      color: #2b2f38;
      background: #ffffff;
      border: 1px solid #fffff0;
      transition: 0.4s;
      cursor: pointer;

      &:hover {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
        transition: 0.4s;
      }
    }
    img {
      height: 24px;
    }
    .lockBlack {
      display: none;
    }
    .lockWhite {
      display: block;
    }
  }

  .researchContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    p,
    span {
      color: #fff;
      text-shadow: 1px 1px #0c0c0c;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
    .offcut {
      display: block;
      width: 40%;
      height: 1px;
      background: #fffff0;
    }

    .bottom-block {
      text-transform: uppercase;
      font-size: 9px;
      margin-top: 12px;
    }
  }

  .mobile-research-background {
    display: none;
  }

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
  }
}
