.filter-section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
    padding: 0 24px 0 24px;

    p {
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 620;
        line-height: 26px;
        color: rgba(43, 47, 56, 1);
    }

    button {
        width: 132px;
        height: 45px;
        border-radius: 12px;
        background: rgba(230, 231, 235, 1);
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.0075em;
        text-align: center;
        color: rgba(0, 15, 48, 1);
        transition: 0.2s;

        &:hover {
            background: rgb(221, 221, 221);
        }
        &:active {
            background: rgb(207, 207, 207);
        }
    }
}
