.registration {
    width: 50%;
    max-width: 1000px;
    height: auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;
    margin: 40px 0;

    input:focus {
        outline: none;
        border-color: black;
    }

    .registration-go-back {
        padding-bottom: 15px;

        @media (max-width: 1180px) {
            padding-left: 20px;
        }

        @media (max-width: 1000px) {
            padding-left: 0px;
        }
    }
    .page-title {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
        margin-bottom: 35px;
    }
    label {
        font-size: 14px;
        font-weight: 600;
        width: 80%;
        color: rgba(78, 85, 102, 1);
    }
    .validation {
        color: rgb(190, 1, 13);
        margin-top: -5px;
        position: absolute;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: left;
        margin-top: 8px;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        gap: 18px;

        .registration-field {
            width: 100%;
            margin: 0;

            input {
                width: 100%;
            }
        }
        .registration-password-field {
            width: 100%;
            margin: 0;

            div {
                width: 100%;
            }

            input {
                width: 100%;
            }
        }
        .registration-terms-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
            label {
                width: 100%;
            }
            p {
                width: 100% !important;
                max-width: 98% !important;
            }
        }
        .registration-btn {
            width: 100%;
            margin-top: 20px;
        }
    }

    .name-lastname-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;

        .registration-field {
            width: 49%;

            input {
                width: 100%;
            }
        }
    }
    .registration-phone-input-wrapper {
        position: relative;

        label {
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            color: rgba(78, 85, 102, 1);
        }

        .phone-verify-btn {
            position: absolute;
            background-color: inherit;
            right: 16px;
            top: 45px;
            font-weight: 600;
        }

        .react-international-phone-input-container {
            margin-top: 8px;
        }
        .react-international-phone-country-selector-button__button-content {
            width: 100px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12.5px;
            border: 1px solid rgba(191, 196, 206, 1);
            border-radius: 8px 0 0 8px;
        }
        .react-international-phone-country-selector-button {
            width: 100px;
            height: 56px;
            border: none;
        }
        .react-international-phone-country-selector-button__dropdown-arrow {
            border-top: var(--react-international-phone-country-selector-arrow-size, 0px) solid
                var(--react-international-phone-country-selector-arrow-color, #777);
            border-right: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
            border-left: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
            transition: all 0.2s ease-out;
            background-image: url("../../../assets/Shape.svg");
            width: 14px;
            height: 8px;
            background-repeat: no-repeat;
            margin: 0;
        }
        .react-international-phone-input-container .react-international-phone-input {
            height: 56px;
            width: 100%;
            border: 1px solid rgba(191, 196, 206, 1);
            border-radius: 0 8px 8px 0;
            font-size: 16px;
            padding-left: 24px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
        .react-international-phone-country-selector-dropdown {
            margin-top: 16px;
            height: 400px;
            border: 1px solid rgba(191, 196, 206, 1);
            border-radius: 8px;
            padding: 0;
            &::-webkit-scrollbar {
                display: none;
            }
            .react-international-phone-country-selector-dropdown__list-item {
                border-top: 1px solid rgba(191, 196, 206, 1);
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 24px;

                &:first-child {
                    border-top: none;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(43, 47, 56, 1);
                    margin: 0;
                }
                .react-international-phone-country-selector-dropdown__list-item-dial-code {
                    margin-left: 10px;
                }
            }
        }
    }
    .otp-code-input {
        height: 56px;
        padding-left: 24px;
        border-radius: 8px;
        border: 1px solid rgb(191, 196, 206);
        font-size: 16px;
        font-weight: 400;
        color: rgb(43, 47, 56);
    }
    .registration-otp-code-input-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 4px 8px 2px;

            p {
                margin: 0;
                font-weight: 600;

                span {
                    font-weight: 400;
                }

                &:last-child {
                    cursor: pointer;
                }
            }
        }

        .resend-otp-code {
            position: absolute;
            background-color: inherit;
            right: 16px;
            bottom: 18px;
            font-weight: 600;
            cursor: pointer;
            background-color: rgb(255, 255, 255);
        }
    }

    @media (max-width: 1500px) {
        width: 55%;
    }
    @media (max-width: 1500px) {
        width: 65%;
    }
    @media (max-width: 1300px) {
        width: 70%;
    }
    @media (max-width: 1000px) {
        width: 75%;
    }
    @media (max-width: 880px) {
        width: 78%;
    }
    @media (max-width: 750px) {
        width: 100%;
        height: auto;
        padding-top: 0;
        margin: 0;
        background: inherit;
        box-shadow: none;
        justify-content: center;

        form {
            width: 100%;

            input {
                width: 100%;
                background: inherit;
            }
        }
        .react-international-phone-country-selector {
            background-color: inherit !important;
            button {
                background-color: inherit !important;
            }
        }
        .react-international-phone-input {
            background-color: inherit !important;
        }
        .react-international-phone-country-selector-dropdown {
            background-color: rgb(242, 243, 245) !important;
        }
        .resend-otp-code {
            background-color: rgb(242, 243, 245) !important;
            padding-left: 10px;
        }
    }
    @media (max-width: 550px) {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        background: inherit;
        box-shadow: none;
        justify-content: center;

        form {
            width: 100%;

            input {
                width: 100%;
                background: inherit;
            }
        }
        .react-international-phone-input {
            width: 100% !important;
        }
        .registration-terms-section {
            margin-top: 10px;
            p,
            a {
                font-size: 12.5px !important;
                line-height: 16px !important;
            }
        }
        .name-lastname-wrapper {
            flex-direction: column;
            gap: 18px;

            .registration-field {
                width: 100%;
            }
        }

        .recaptcha-policy {
            position: absolute;
            bottom: -36px !important;
            font-size: 8px;
            text-align: center;
        }
    }

    .recaptcha-policy {
        position: absolute;
        bottom: 16px;
        font-size: 8px;
        text-align: center;
    }
}
