.filter-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);
    padding-block: 16px;
    min-height: 56px;
    gap: 10px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;

    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(43, 47, 56, 1);
    }
    .filter-field-value {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        span {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0em;
            color: rgba(122, 132, 153, 1);
            text-align: right;
            white-space: nowrap;

            @media (max-width: 450px) {
                white-space: wrap;
            }
        }
    }
}

.filter-field-checkbox {
    $toggle-indicator-size: 26px;
    $track-height: $toggle-indicator-size + 6;
    $track-width: $toggle-indicator-size * 2;

    .toggle {
        align-items: center;
        border-radius: 100px;
        display: flex;
        font-weight: 700;
        margin-bottom: 16px;

        &:last-of-type {
            margin: 0;
        }
    }
    .toggle__input {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        // &:not([disabled]):active + .toggle-track,
        // &:not([disabled]):focus + .toggle-track {
        //     border: 1px solid transparent;
        //     box-shadow: 0px 0px 0px 2px black;
        // }

        &:disabled + .toggle-track {
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
    .toggle-track {
        background: rgba(230, 231, 235, 1);
        border: 1px solid rgba(122, 132, 153, 1);
        border-radius: 100px;
        padding: 0 4px 0 8px;
        cursor: pointer;
        width: $track-width;
        height: $track-height;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .toggle-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(122, 132, 153, 1);
        border-radius: $toggle-indicator-size;
        width: 16px;
        height: 16px;
        transition: 0.3s;
    }

    .checkMark {
        fill: rgba(0, 15, 48, 1);
        height: 18px;
        width: 18px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    .toggle__input:checked + .toggle-track {
        background: rgba(0, 15, 48, 1);
        border: 1px solid rgba(0, 15, 48, 1);
    }
    .toggle__input:checked + .toggle-track .toggle-indicator {
        background: rgba(242, 243, 245, 1);
        transform: translateX($track-width - $track-height - 6);
        width: 24px;
        height: 24px;

        .checkMark {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
}
