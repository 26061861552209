.date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.005em;
        text-align: left;
        color: rgba(78, 85, 102, 1);
        margin-bottom: 4px;
    }

    .react-datepicker__input-container {
        max-width: 156px;
        max-height: 48px;
        border-radius: 8px;
        border: 0.5px solid rgba(191, 196, 206, 1);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 16px;
            width: 24px;
            height: 24px;
            padding: 0 !important;
        }
        .date-picker {
            width: 100%;
            background-color: inherit;
            padding: 14px 0 14px 52px;
            border-radius: 8px;
            z-index: 2;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            color: rgba(43, 47, 56, 1);

            // &:focus{
            //     outline: none;
            // }
        }
    }
    .react-datepicker {
        border-radius: 12px;
        border: none;
        box-shadow: 0px 0px 7px 1px rgb(0, 0, 0);
        overflow: hidden;
    }
    .react-datepicker__navigation {
        margin-top: 5px;
    }
    .react-datepicker__header {
        background-color: white;
        padding-top: 10px;
        padding-bottom: 0;

        .react-datepicker__current-month {
            font-size: 20px;
        }
    }
    .react-datepicker__month {
        .react-datepicker__day--selected {
            background-color: rgba(248, 0, 158, 0.4);
            color: rgba(248, 0, 158, 1);
        }
        .react-datepicker__day--keyboard-selected {
            background-color: rgba(248, 0, 158, 0.12);
        }
    }
    // .react-datepicker-popper {
    // }
}
.report-date-picker-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
    width: 100%;
    background: white;
    position: relative;
    padding-left: 100px;

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(43, 47, 56, 1);
        position: absolute;
        left: 16px;
        max-width: 50%;
    }

    .react-datepicker__input-container {
        max-width: 156px;
        max-height: 48px;
        border-radius: 8px;
        border: 0.5px solid rgba(191, 196, 206, 1);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 16px;
            width: 24px;
            height: 24px;
            padding: 0 !important;
        }
        .date-picker {
            width: 100%;
            background-color: inherit;
            padding: 14px 0 14px 52px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            color: rgba(43, 47, 56, 1);

            &::selection {
                color: rgb(51, 51, 51);
                background: rgba(248, 0, 158, 0.2);
            }
        }
    }
    .react-datepicker {
        border-radius: 12px;
        border: none;
        box-shadow: 0px 0px 7px 1px rgb(0, 0, 0);
        overflow: hidden;
    }

    .react-datepicker__navigation {
        margin-top: 12px;
    }
    .react-datepicker__header {
        background-color: white;
        padding-top: 10px;
        padding-bottom: 0;

        .react-datepicker__current-month {
            font-size: 20px;
        }
    }
    .react-datepicker__month {
        .react-datepicker__day--selected {
            background-color: rgba(248, 0, 158, 0.4);
            color: rgba(248, 0, 158, 1);
        }
        .react-datepicker__day--keyboard-selected {
            background-color: rgba(248, 0, 158, 0.12);
        }
    }

    .calendar-year {
        width: 160px;

        .react-datepicker__year-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1px;
        }
        .react-datepicker__navigation {
            margin-top: 8.4 px;
        }
        .react-datepicker__header{
            padding-block: 8px;
        }
        .react-datepicker__year-text--selected {
            background-color: rgba(248, 0, 158, 0.4) !important;
            color: rgba(248, 0, 158, 1);
        }
        .react-datepicker__year-text--keyboard-selected {
            background-color: rgba(248, 0, 158, 0.12);
        }
    }
}
