.report-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 20px;
  width: 100%;
  background: white;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(43, 47, 56, 1);
    left: 16px;
  }

  img {
    cursor: pointer;
    width: 22px;
  }
}

.report-sign-contract {
  width: 100%;
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  img {
    height: 18px;
    margin-right: 5px;
    fill: #F8009E;
  }
  
  a {
    font-size: 14px;
    color: rgba(0, 15, 48, 1);
  }
}
