.createAcc {
    width: 555px;
    height: 504px;
    padding: 64px;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;

    div {
        display: flex;
    }

    .joinUs {
        font-family: "SpaceGrotesk";
        font-size: 40px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    span {
        margin: 10px 0 56px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }

    .joinCards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .navLinkLoginMobile {
        display: none;
        margin-top: 40px;

        p,
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
        span {
            text-decoration: underline;
        }
    }

    @media (max-width: 750px) {
        width: auto !important;
        height: auto !important;
        padding: 0px 24px 0px 24px !important;
        background: inherit !important;
        box-shadow: none !important;
        span {
            max-width: 100%;
        }

        .joinCards {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100% !important;
        }

        .navLinkLoginMobile {
            display: none;
            margin-top: 40px;

            p,
            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
            span {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 550px) {
        .navLinkLoginMobile {
            display: block;
        }
        .joinUs {
            font-size: 28px;
        }
    }
}
