@import "../../../styles/main.scss";
.stories-container {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed !important;
    top: 0;
    z-index: 40;

    .stories-main {
        padding-block: 50px 64px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        position: static;

        .stories-link-btn {
            cursor: pointer;
            position: absolute;
            z-index: 1000;
            left: 23px;
            top: 50px;
            width: auto;
            padding: 0 10px 0 8px;
            height: 30px;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.1);
            background-image: linear-gradient(rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0.1));
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: initial;
            font-family: "SpaceGrotesk";
            transition: 0.3s;
            box-shadow: 0px 2px 4px -2px #000000;
            transition: 0.3s;

            img {
                width: 18px;
                height: 18px;
                margin-right: 4px;
                transition: 0.3s;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.5);

                img {
                    margin-left: 2px;
                    margin-right: 2px;
                }
            }
            &:active {
                transition: 0.2s;
                left: 26px;
                box-shadow: 0px 2px 2px -2px #000000;
            }
        }

        .close-stories {
            cursor: pointer;
            position: absolute;
            right: 23px;
            top: 50px;
            width: 23px;
            height: 23px;
            z-index: 1000;
        }

        .stories-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 76px;
        }

        .text-stories-block {
            display: flex;
            width: 100%;
            flex-direction: column;
            text-align: left;
            gap: 24px;
            padding: 0 24px 0 24px;
            max-height: 70%;

            p {
                color: #f9f9f9;
                font-family: "NotoSans";
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
                text-align: left;
            }
            h4 {
                color: #ffffff;
                font-size: 18px;
                line-height: 24px;
            }
            span {
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
            }
            a {
                position: absolute;
                bottom: 24px;
                left: 0px;
                width: 100%;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                color: black;
                text-decoration: underline;
                cursor: pointer;
                z-index: 1000;
            }
            ul {
                padding: 32px 0 24px 24px;
                li {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 400;
                    margin-top: 12px;
                }
            }

            // @media (max-height: 850px) {
            //     p {
            //         font-size: 22px;
            //         line-height: 26px !important;
            //     }
            //     span {
            //         font-size: 15px;
            //     }
            //     h4 {
            //         font-size: 17px;
            //         line-height: 24px;
            //     }

            //     ul {
            //         li {
            //             font-size: 17px;
            //             margin-top: 10px;
            //         }
            //     }
            // }
            // @media (max-height: 750px) {
            //     p {
            //         font-size: 20px;
            //         line-height: 24px !important;
            //     }
            //     span {
            //         font-size: 14px;
            //     }
            //     h4 {
            //         font-size: 16px;
            //         line-height: 22px;
            //     }

            //     ul {
            //         padding: 20px 0 20px 24px;
            //         li {
            //             font-size: 16px;
            //             margin-top: 10px;
            //         }
            //     }
            // }
            // @media (max-height: 715px) {
            //     p {
            //         font-size: 18px;
            //         line-height: 24px !important;
            //     }
            //     span {
            //         font-size: 14px;
            //     }
            //     h4 {
            //         font-size: 15.5px;
            //         line-height: 20px;
            //     }

            //     ul {
            //         padding: 12px 0 12px 24px;
            //         li {
            //             font-size: 15.5px;
            //             margin-top: 8px;
            //         }
            //     }
            // }
            // @media (max-height: 670px) {
            //     gap: 16px;

            //     p {
            //         font-size: 16px;
            //         line-height: 22px !important;
            //     }
            //     span {
            //         font-size: 14px;
            //     }
            //     h4 {
            //         font-size: 14px;
            //         line-height: 18px;
            //     }

            //     ul {
            //         padding: 10px 0 10px 24px;
            //         li {
            //             font-size: 14px;
            //             margin-top: 6px;
            //         }
            //     }
            // }
        }
    }

    .asset-stories-main {
        // position: relative;
        padding: 20.5vh 24px 0px 24px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #f9f9f9;

        .close-stories {
            cursor: pointer;
            position: absolute;
            right: 23px;
            top: 50px;
            width: 23px;
            height: 23px;
            z-index: 1000;
            border-radius: 5px;
            // background-color: #2b2f38;
        }

        .asset-story-header {
            position: absolute;
            top: 0;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;
            height: 20.5vh;
            max-height: 20.5vh;
            padding: 24px;
            border-radius: 0px 0px 24px 24px;
            overflow: hidden;

            .story-asset {
                @include flex-center;
                gap: 12px;
                border-radius: 8px;
                z-index: 10;

                img {
                    width: 56px;
                    min-width: 56px;
                    height: 56px;
                    min-height: 56px;
                    filter: brightness(1) !important;
                }
                .story-asset-name {
                    text-align: left;

                    p {
                        font-family: "SpaceGrotesk";
                        font-size: 20px;
                        line-height: 26px;
                        max-height: 56px;
                        overflow: hidden;
                        letter-spacing: 0em;
                        // color: #f9f9f9;
                        color: white;
                        margin: 0;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        color: #f9f9f9;
                    }
                }
            }
        }

        p {
            font-family: "SpaceGrotesk";
            width: 100%;
            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: #2b2f38;
            margin-block: 24px 16px;
        }
        .wtb-story-description {
            padding-block: 24px 64px;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #2b2f38;
            flex-grow: 1;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
