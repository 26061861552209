.openCameraBtn {
    width: 460px;
    height: 56px;
    border: 1px solid rgba(0, 15, 48, 1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: rgba(242, 243, 245, 1);
    z-index: 25;

    font-size: 16px;
    font-weight: 600;
    color: rgba(43, 47, 56, 1);

    &:hover {
        background: rgba(221, 222, 224, 0.45);
    }
}
