.depth-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    border-bottom: 0.5px solid #bfc4ce;
    gap: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:nth-last-child(1) {
        border-bottom: none;
    }

    div {
        width: 45%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        p {
            font-weight: bold;
        }

        &:nth-child(1) {
            padding-left: 16px;
            justify-content: flex-start;

            span {
                left: 0;
                background: rgba(39, 174, 96, 0.25);
                border-radius: 0 12px 12px 0;
            }
            p {
                color: #27ae60;
                text-align: left;
            }
        }
        &:nth-last-child(1) {
            padding-right: 16px;
            justify-content: flex-end;

            span {
                right: 0;
                background: rgb(235, 87, 87, 0.25);
                border-radius: 12px 0 0 12px;
            }
            p {
                color: #eb5757;
                text-align: right;
            }
        }
    }

    p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: black;
        text-align: center;
    }
}
