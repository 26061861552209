.enterTheCode {
    width: 558px;
    height: auto !important;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    gap: 0;
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        max-width: 90%;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    span {
        margin: 10px 0 40px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }
    .codeTextRe {
        margin-top: 70px;
    }
    .sendCodeAgain {
        font-size: 14px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }
    .resendCode {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
        cursor: pointer;
    }
    .editYourNumber {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
        cursor: pointer;
        padding-bottom: 10px;
    }

    .green {
        border: 1px solid rgba(39, 174, 96, 1);
    }
    .red {
        border: 1px solid rgba(235, 87, 87, 1);
        color: rgba(235, 87, 87, 1);
    }
    .error-verify {
        color: rgba(235, 87, 87, 1);
        font-size: 12px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
    }
    input {
        border-radius: 12px;
        width: 60px;
        height: 56px;
        padding: 0;
        font-size: 18px;
        text-align: center;
        margin-right: 12px;
        text-transform: uppercase;
        color: #494949;
        border: 1px solid rgba(191, 196, 206, 1);
        gap: 24px;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &:focus {
            appearance: none;
            outline: 0;
            border: 1px solid rgba(0, 15, 48, 1);
        }

        button {
            appearance: none;
            background: #0071e3;
            border-radius: 980px;
            text-align: center;
            border: none;
            color: #fff;
            margin: 8px;
            padding: 4px 11px;
        }

        p {
            font-size: 17px;
            line-height: 1.47059;
        }

        .props {
            border: 1px solid #d6d6d6;
            padding: 8px 16px;
        }

        .options {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .allowed-characters {
            text-align: left;
        }
    }
}
@media (max-width: 750px) {
    .enterTheCode {
        width: 100% !important;
        max-width: 455px !important;
        padding-top: 0 !important;
        background: inherit !important;
        box-shadow: none !important;

        p {
            width: 100%;
            max-width: 100% !important;
        }
        span {
            max-width: 100% !important;
        }

        .codeTextRe {
            margin-top: 38px !important;
        }
        .editYourNumber {
            margin-top: 0;
        }

        input {
            border-radius: 12px;
            width: 60px;
            height: 56px;
            padding: 0;
            font-size: 18px;
            text-align: center;
            margin-right: 12px;
            text-transform: uppercase;
            color: #494949;
            background: inherit;
            border: 1px solid rgba(191, 196, 206, 1);
            gap: 24px;

            &:focus {
                appearance: none;
                outline: 0;
                border: 1px solid rgba(0, 15, 48, 1);
            }

            button {
                appearance: none;
                background: #0071e3;
                border-radius: 980px;
                text-align: center;
                border: none;
                color: #fff;
                margin: 8px;
                padding: 4px 11px;
            }

            p {
                font-size: 17px;
                line-height: 1.47059;
            }

            .props {
                border: 1px solid #d6d6d6;
                padding: 8px 16px;
            }

            .options {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .allowed-characters {
                text-align: left;
            }
        }
    }
}
