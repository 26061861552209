.verification-steps-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 78px;
    margin-bottom: 12px;
    position: relative;

    .go-back {
        cursor: pointer;
        position: absolute;
        left: 24px;
    }

    p {
        font-size: 16px;
        font-weight: 600;
        color: #2b2f38;
    }

    .progressLarg {
        width: auto !important;
        min-width: 0px !important;
        max-width: none !important;
    }
    .progress {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: auto !important;
        margin-block: 0px !important;
        z-index: 10;
        position: absolute !important;
        right: 24px !important;
    }

    .progress-circle {
        display: block;
        width: 40px;
    }
    .mobileInfo {
        display: block;
        position: absolute !important;
        width: auto !important;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: #2b2f38;
        letter-spacing: 0.7px;
    }

    @media (max-width: 750px) {
        .progress {
            top: 19px !important;
        }

        .mobileInfo {
            top: 14px;
        }
    }
}
