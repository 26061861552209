.investingExperience {
    width: 558px;
    height: auto;
    padding-bottom: 72px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        max-width: 80%;
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
        margin-bottom: 40px;
    }

    .registerNameBtn {
        margin-top: 48px;
    }
}
@media (max-width: 750px) {
    .investingExperience {
        width: 100%;
        max-width: 520px;
        padding-top: 0;
        height: auto;
        background: inherit;
        box-shadow: none;
        
        .button {
            width: 100%;
        }
    }
}

@media (max-width: 500px) {
    .investingExperience {
        position: static;

        .button {
            width: 88%;
            position: absolute;
            bottom: 40px;
        }
    }
}
