.assignments-form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 18px;

    label {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.75%;
        color: #4e5566;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        text-align: left;

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-size: 12px;
            line-height: 16px;
            color: #a2a4a9;
            text-align: right;

            img {
                filter: grayscale(5);
                opacity: 0.4;
            }
        }
    }

    input {
        width: 100%;
        height: 45px;
        padding: 0 16px;
        border: 1.5px solid #bfc4ce;
        border-radius: 6px;
        background-color: transparent;

        &:focus {
            outline: none;
            border-color: #000f30;
        }
    }

    ul {
        visibility: hidden;
        position: absolute;
        max-height: 150px;
        overflow-y: scroll;
        top: 100%;
        left: -1.5px;
        list-style-type: none;
        width: calc(100% + 3px);
        border: 1.5px solid #bfc4ce;
        border-radius: 6px;
        transition: 0.1s;
        background-color: #f9f9f9;
        margin: 0 !important;
        padding: 0 !important;
        overflow-x: hidden !important;
        z-index: 10;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #f9f9f9;
            visibility: hidden;
        }
        &::-webkit-scrollbar-track {
            background-color: #f9f9f9;
            border-radius: 15px;
            visibility: hidden;
        }
        &::-webkit-scrollbar-thumb {
            visibility: visible;
            background-color: #0000001f;
            box-shadow: inset 0px 0px 10px 2px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            border-radius: 0px;
        }
    }

    .assignments-form-dropdown {
        position: relative;
        width: 100%;
        height: 45px;
        padding: 0 16px;
        border: 1.5px solid #bfc4ce;
        border-radius: 6px;
        background-color: #f9f9f9;

        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        transition: 0.3s;

        p {
            width: 100%;
            text-align: left;
            overflow: hidden;
        }

        .assignments-dropdown-icon {
            rotate: -90deg;
            transition: 0.3s;
        }

        ul {
            li {
                padding: 7px 16px;
                text-align: left;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 20px;
                }

                &:hover {
                    background-color: #00000008;
                }
            }
        }

        &:hover {
            border-radius: 6px 6px 0 0;

            .assignments-dropdown-icon {
                rotate: 90deg;
            }
            // border-color: #000f30;
            ul {
                transition: 0.3s;
                visibility: visible;
                border-radius: 0 0 6px 6px;
            }
        }
    }

    .assignments-form-dropdown-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .assignments-form-dropdown-item {
            position: relative;
            width: 48%;
            height: 45px;
            padding: 0 16px;
            border: 1.5px solid #bfc4ce;
            border-radius: 6px;
            background-color: #f9f9f9;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            transition: 0.3s;

            input {
                width: 100%;
                border: none !important;
                outline: none !important;
            }

            p {
                width: 100%;
                font-size: 15px;
                text-align: left;
                overflow: hidden;
            }

            .assignments-dropdown-icon {
                rotate: -90deg;
                transition: 0.3s;
            }

            ul {
                max-height: 150px;

                li {
                    padding: 7px 18px;
                    text-align: left;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    word-break: break-all;

                    span {
                        font-size: 12px;
                    }

                    img {
                        width: 18px;
                    }

                    &:hover {
                        background-color: #00000008;
                    }
                }
            }

            &:hover {
                border-radius: 6px 6px 0 0;

                .assignments-dropdown-icon {
                    rotate: 90deg;
                }
                // border-color: #000f30;
                ul {
                    transition: 0.3s;
                    visibility: visible;
                    border-radius: 0 0 6px 6px;
                }
            }
        }

        .assignments-form-dropdown-input-item {
            position: relative;
            width: 48%;
            height: 45px;
            border: 1.5px solid #bfc4ce;
            border-radius: 6px;
            background-color: #f9f9f9;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            transition: 0.3s;

            input {
                width: 100%;
                border: none !important;
                outline: none !important;
            }

            ul {
                max-height: 150px;

                li {
                    padding: 7px 18px;
                    text-align: left;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    word-break: break-all;

                    span {
                        font-size: 12px;
                    }

                    img {
                        width: 18px;
                    }

                    &:hover {
                        background-color: #00000008;
                    }
                }
            }

            .assignments-form-asset-validation {
                color: #eb5757;
                position: absolute;
                top: calc(100% + 1.5px);
                left: 2px;
                font-size: 12px;
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
