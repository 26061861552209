.joinCard {
    display: flex;
    align-items: center;
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    height: 101px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;

    .text {
        display: flex;
        flex-direction: column;
        align-items: start;
        max-width: 264px;
        gap: 3px;

        p {
            font-size: 18px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }

        span {
            font-size: 14px;
            margin: 0;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
            text-align: left;
        }
    }

    .variantIcon {
        margin-right: 24px;
        margin-left: 25.5px;
    }

    .vector {
        position: absolute;
        right: 24px;
        transition: 0.13s;
    }

    &:hover .vector {
        right: 18px;
    }
    &:hover {
        background-color: rgba(216, 217, 220, 0.55);
    }
}
.joinCardLink {
    text-decoration: none;
    width: 100%;

    &:hover {
        text-decoration: none;
    }
}
@media (min-width: 550px) and (max-width: 750px) {
    .joinCard {
        background: rgba(249, 249, 249, 1);
        width: 100%;
        height: 80px;

        .text {
            max-width: 80%;
        }
    }
}
@media (max-width: 550px) {
    .joinCard {
        background: rgba(249, 249, 249, 1);
        width: 100%;
        height: 130px;

        .text {
            width: 50%;
            // max-width: 160px;
        }
    }
}