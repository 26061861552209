.main-layout {
  background-color: rgba(242, 243, 245, 1);
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;

  .goBackNavbar2 {
    display: none;
    width: auto;
    padding: 0;
  }
  .navbar {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    z-index: 15;
    height: auto;
    padding: 36px 80px 0 56px;

    .navbarRight {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      list-style: none;
    }
    .language {
      padding-right: 64px;
      padding-bottom: 40px;
      background-image: url("../../../assets/Language.svg");
      background-position: left;
      background-repeat: no-repeat;
      background-size: 25px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-image: url("../../../assets/Language2.svg");
      }
      .languageH {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        position: absolute;
        list-style-type: none;

        top: 33px;
        padding: 26px 30px 26px 28px;
        border-radius: 12px;
        gap: 20px;

        li {
          display: flex;
          align-items: center;
          justify-content: baseline;
          gap: 12px;
          padding: 0;
          color: var(--primary);

          img {
            width: 16px;
            height: 16px;
          }

          &.active {
            font-weight: 600;
          }

          &:hover {
            transition: 0s;
            font-weight: 600;
          }
        }
      }
      &:hover .languageH {
        display: flex;
      }
    }
    .navLinkLogin {
      text-decoration: none;
      p {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
      }
    }
  }
  .navbar2 {
    padding: 24px 56px 0 56px;
    align-items: center;

    .language {
      padding-right: 0px;
      padding-left: 64px;
      background-position: right;

      .languageH {
        top: 35px;
        right: 0px;
      }
    }
  }
  .navbar3 {
    padding: 24px 56px 0 56px;

    .language {
      display: none;
    }
  }
  .logo-small {
    display: none;
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .navbar {
      padding: 30px 56px 0 32px;
      align-items: center;

      .logo {
        width: 201px;
        height: 24px;
      }
    }
    .navbar2 {
      padding: 20px 32px 0 32px;
    }
    .navbar3 {
      padding: 24px 32px 0 32px;
    }
  }
  @media (min-width: 500px) and (max-width: 750px) {
    .navbar {
      padding: 30px 24px 0 24px;
      align-items: center;

      .logo {
        width: 201px;
        height: 24px;
      }
      .navbarRight {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap-reverse;

        .language {
          padding: 0;
          padding-left: 50px;
          padding-bottom: 40px;
          background-position: right;
          order: 1;
          .languageH {
            right: 0;
            top: 35px;
            padding: 26px 44px 26px 36px;
            gap: 24px;
          }
        }
      }
    }
    .navbar2 {
      padding: 20px 32px 0 32px;

      .goBackNavbar2 {
        display: block !important;
        .goBackNavbar2Text {
          display: block;
        }
      }
      .logo {
        display: none;
      }
    }
    .navbar3 {
      padding: 24px 32px 0 32px;
    }
  }
  @media (max-width: 500px) {
    .navbar {
      padding: 30px 24px 0 24px;
      align-items: center;

      .logo {
        display: none;
      }
      .logo-small {
        display: block;
      }
      .navbarRight {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap-reverse;

        .language {
          padding: 0;
          padding-left: 50px;
          padding-bottom: 40px;
          background-position: right;
          order: 1;
          .languageH {
            right: 0;
            top: 35px;
            padding: 26px 44px 26px 36px;
            gap: 24px;
          }
        }
      }
    }
    .navLinkLogin {
      display: none;
    }
    .navbar2 {
      padding: 29px 32px 0 32px;

      .goBackNavbar2 {
        display: block !important;
        .goBackNavbar2Text {
          display: none;
        }
      }
      .logo {
        display: none;
      }
      .logo-small {
        display: none;
      }
    }
    .navbar3 {
      padding: 24px 32px 0 32px;
    }
    .navbarAuth {
      padding: 24px 32px 0 32px;

      .goBackNavbar2 {
        display: block !important;
        .goBackNavbar2Text {
          display: none !important;
        }
      }
      .logo {
        display: none;
      }
      .logo-small {
        display: none;
      }
    }
  }
  .backgroundElement {
    position: absolute;
    mix-blend-mode: overlay;
    z-index: 0;
  }
  .backgroundElementTop {
    top: 0;
    right: 0;
  }
  .backgroundElementBottom {
    left: 0;
    bottom: 0;
  }
}
@media (max-width: 750px) {
  .acc-created-layout {
    background-color: rgba(0, 15, 48, 1) !important;
  }
}
