.strategy-charts {
    width: 100%;
    min-height: 425px;
    height: 425px;
    max-height: 425px;
    // border-bottom: 0.5px solid #bfc4ce;
    position: relative;
}

.strategy-chart-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // .highcharts-title{
    //     text-align: left !important;
    //     font-size: 16px !important;
    //     padding: 100px !important;
    // }
    .highcharts-halo {
        border-radius: 100%;
        fill: rgb(200, 200, 200);
    }
    .highcharts-label {
        display: none;
    }
    .highcharts-tooltip {
        display: block;
    }
    .highcharts-background {
        fill: #f9f9f9;
        // opacity: 0;
    }
    .highcharts-credits,
    .highcharts-axis-title {
        display: none;
    }

    // .highcharts-legend,
    // .highcharts-legend-box {
    //     width: 100%;
    //     fill: blue;
    //     margin-left: -50px !important;
    //     padding: 0;
    // }
    // .highcharts-legend-item {
    //     .highcharts-graph {
    //         stroke-width: 14px;
    //         stroke-linecap:butt;
    //         // stroke-height: 10px;
    //         border-radius: 100%;
    //     }
    // }

    // .highcharts-point-up {
    //     stroke: #27ae60;
    // }
    // .highcharts-point-down {
    //     stroke: #eb5757;
    // }
    // .highcharts-button-box {
    //     &:nth-child(0) {
    //         outline: 2px solid red;
    //     }
    // }
}
