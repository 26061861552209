.contacts-modal {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .contacts-modal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .contacts-modal-main {
        width: 100%;
        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px 24px 0 24px;
    }
}
