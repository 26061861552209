.home {
    width: 100%;
    max-width: 450px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .stories {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        gap: 16px;
        padding: 0 24px 0 24px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .available-balance {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 16px;
        padding: 24px;
        span {
            font-size: 12px;
            font-weight: 400;
            color: rgba(124, 125, 129, 1);
        }
        .available-balance-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 18px;

            img {
                cursor: pointer;
                opacity: 0.8;
                width: 30px;
                height: 30px;
                margin-bottom: 3px;
                transition: 0.1s;

                &:active {
                    width: 32px;
                    height: 32px;
                    margin-left: -2px;
                }
            }
            p {
                margin-left: 48px;
            }
        }
        p {
            cursor: pointer;
            word-break: break-all;
            text-align: center;
            padding-block: 8px 16px;
            font-size: 40px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
        .balance-progres {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 14px;
                font-weight: 600;
                color: rgba(39, 174, 96, 1);
                margin-left: 8px;

                &.red {
                    color: rgba(235, 87, 87, 1);
                }
            }
            p {
                padding: 0;
                font-size: 14px;
                font-weight: 600;
                color: rgba(78, 85, 102, 1);
            }
        }
        &.hiden-balance :is(.balance-progres, p) {
            filter: blur(8.7px);
        }
    }

    .show-hide-balance {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 24px;
        z-index: 4;
    }
    .rates {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .ratesHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
            }
            img {
                cursor: pointer;
            }
        }

        .ratesFooter {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 0.5px solid rgba(191, 196, 206, 1);
            border-bottom: 0.5px solid rgba(191, 196, 206, 1);
            // padding-block: 8px;
            .marquee {
                min-width: 0 !important;
            }
            p {
                padding: 0 13px 0 13px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
                margin: 0;
            }
            span {
                color: rgba(39, 174, 96, 1);
                // color: rgba(235, 87, 87, 1);
            }
        }
    }
    .portfolio {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .portfolio-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
            }
            button {
                width: 93px;
                height: 45px;
                border-radius: 12px;
                background: rgba(230, 231, 235, 1);
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 15, 48, 1);
            }
        }
        .portfolio-cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 17px;
            @media (max-width: 370px) {
                gap: 8px;
                row-gap: 12px;
            }

            .empty-portfolio {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 8px 0 8px;

                p {
                    font-family: "SpaceGrotesk";
                    font-size: 18px;
                    font-weight: 500;
                    color: rgba(43, 47, 56, 1);
                }
                span {
                    cursor: pointer;
                    color: rgba(248, 0, 158, 1);
                }

                @media (max-width: 320px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }

    .adviser {
        padding: 24px;

        .adviser-container {
            width: 100%;
            height: 207px;
            border-radius: 16px;
            padding: 16px 16px 24px 16px;
            display: flex;
            flex-direction: column;
            background: white;
        }

        .adviser-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 48px;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
            }
            img {
                cursor: pointer;
            }
        }
        .adviser-main {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            .flag {
                position: absolute;
                bottom: 0;
                left: 18%;
                z-index: 2;
            }
            .progress-frame {
                width: 100%;
            }
        }
        .adviser-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            button {
                width: 47%;
                min-width: 100px;
                height: 45px;
                border-radius: 12px;
                background: rgba(230, 231, 235, 1);
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 15, 48, 1);
            }
        }
    }
    .accounts {
        padding: 24px;

        .accounts-container {
            width: 100%;
            min-width: 100%;
            height: auto;
            min-height: 100px;
            border-radius: 16px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            background: white;
        }

        .accounts-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
            }
            button {
                min-width: 76px;
                padding: 0 8px;
                height: 45px;
                border-radius: 12px;
                background: rgba(230, 231, 235, 1);
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 15, 48, 1);
            }
        }
        .account-card:nth-last-child(1) {
            border: none;
        }
    }
    .home-currency-section {
        height: auto;
        min-height: 50px;
        max-height: 350px;
        border-radius: 16px;
        padding: 12px 16px 0px 16px;
        display: flex;
        margin: 0 24px;
        flex-direction: column;
        background: white;
        overflow: hidden;

        .home-currency-section-header {
            width: 100%;
            height: 50px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 600;
                color: rgb(43, 47, 56);
            }
        }

        .home-currency-wrapper {
            height: auto;
            min-height: 300px;
            padding-block: 12px;
            display: flex;
            flex-direction: column;
            background: white;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .market-asset {
            min-height: 0px;
            height: 48px;

            .currency-exchange-rate-icons {
                gap: 1 !important;

                .exchange-rate-first {
                    width: 14px;
                    height: 28px;
                    img {
                        width: 28px;
                        height: 28px;
                    }
                }

                .exchange-rate-second {
                    width: 14px;
                    height: 28px;
                    img {
                        width: 28px;
                        height: 28px;
                    }
                }
            }

            .asset-info div {
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
                span {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            .price-gains-currency {
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            &:nth-last-child(1) {
                border: none;
            }

            &:hover {
                background-color: rgba(128, 128, 128, 0.05);
                // background-color: rgba(128, 128, 128, 0.02);
                // box-shadow:
                //     rgba(128, 128, 128, 0.2) 0px 4px 8px -2px,
                //     rgba(128, 128, 128, 0.05) 0px 0px 0px 1px;
            }
        }
    }
    .welcomeModal{
        .close{
            margin-bottom: 5px;
        }
    }
}
