.change-password {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 770px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .change-password-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 16px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .change-password-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;

        .warnings {
            margin-block: 4px 40px;
            width: 100%;
            max-width: 402px;
            padding: 16px;
            border-radius: 16px;
            border: 2px solid rgba(47, 128, 237, 1);
            background-color: rgba(236, 244, 253, 1);
            text-align: left;

            ul {
                padding-left: 20px;
                margin: 0;
                li {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(43, 47, 56, 1);
                }
            }
        }
        form {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .form-row {
                width: 100%;
            }
            .input-container {
                width: 100%;

                input {
                    width: 100%;
                }
            }
            .first-field {
                margin-bottom: 0;
                input {
                    margin-bottom: 16px;
                }
                .error {
                    position: absolute;
                    bottom: -6px;
                }
            }
            .forgot-password {
                width: 100%;
                text-align: right;
                span {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
