@import "../../../styles/main.scss";

.exchange-status-btn {
    @include flex-center;
    width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 6px;
    transition: 0.2s;
    cursor: pointer;

    img {
        width: 18px !important;
        height: 18px !important;
    }
    // background: #22ab94;
}
.exchange-status-icon {
    @include flex-center;
    padding-top: 1px;
    width: 18px;
    height: 18px;
    min-height: 18px;
    border-radius: 6px;
    background-color: inherit !important;
    position: relative;

    .asset-field-status-wrapper {
        transition: 0.5s;
        opacity: 0;
        position: absolute;
        top: -18px;
        z-index: 9;

        .asset-field-status {
            width: auto;
            height: 17px;
            border-radius: 5px;
            background-color: #131722ab;
            font-size: 0.65em;
            padding: 0 4px;
            color: white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            &::after {
                content: "";
                position: absolute;
                bottom: -3px;
                width: 3px;
                height: 3px;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                border-top: 3px solid #131722ab;
            }
        }
    }
    img {
        width: 18px !important;
        height: 18px !important;
    }

    &:hover {
        .asset-field-status-wrapper {
            opacity: 1;
        }
    }
}
