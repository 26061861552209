.share-btn {
    border: none !important;
    background-color: inherit;
    img {
        border-radius: 100%;
        border: 1px solid #dbdbdb;
        box-sizing: content-box;
        padding: 7px;
        height: 24px;

        transition: 0.2s ease-in-out;

        &:hover {
            border-color: black;
        }
    }
}
