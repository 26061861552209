.range-slider-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: 8px;
    width: 45%;
    z-index: 1000;

    label {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.0075em;
        text-align: left;
        color: #4e5566;
    }
    input[type="number"] {
        width: 100%;
        height: 56px;
        border-radius: 12px;
        border: 1px solid #bfc4ce;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2b2f38;

        &:focus {
            outline: none !important;
            border: 2px solid #000000;
        }
        &:disabled {
            background-color: white;
            opacity: 1;
            cursor: default;
        }
    }

    input[type="text"] {
        width: 100%;
        height: 56px;
        border-radius: 12px;
        border: 1px solid #bfc4ce;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2b2f38;

        &:focus {
            outline: none !important;
            border: 2px solid #000000;
        }
        &:disabled {
            background-color: white;
            opacity: 1;
            cursor: default;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2b2f38;
        position: absolute;
        padding: 2px 16px 2px 2px;
        right: 2px;
        bottom: 14px;
        z-index: 1;
        background-color: white;
    }

    .readonly-range-input {
        &:focus {
            border: 1px solid #bfc4ce !important;
        }
    }

    .market-cap-dropdown-wrapper {
        width: 100%;
        max-height: 100px !important;
        position: absolute;
        top: 88%;
        border: 1px solid #bfc4ce;
        border-top: 1px solid #bfc4ce;
        border-radius: 0 0 12px 12px;
        z-index: 100;
        transition-delay: 2s;
        overflow: hidden;
        background-color: #ffffff;

        .market-cap-dropdown {
            width: 100%;
            max-height: 90px !important;
            padding: 8px 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            background-color: #ffffff;
            overflow-x: hidden;
            overflow-y: scroll;

            p {
                position: static;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: #2b2f38;
                background-color: inherit;
                width: 100%;
                padding: 0;
                margin: 0;
                text-align: left;

                &:hover {
                    color: #f8009e;
                }
            }

            &::-webkit-scrollbar {
                width: 3px;
                height: 80%;
            }
            &::-webkit-scrollbar-track {
                background-color: #000f301e;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #f8009e;
                box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background-color: #d30086;
            }
        }
    }
}
