.takePhotoType {
    background-color: rgba(0, 9, 28, 1);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    padding: 40px 24px;
    position: absolute;
    z-index: 25;

    .textTPM {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        margin-block: 10px 56px;
        text-align: center;
        max-width: 480px;

        p {
            font-size: 28px;
            font-family: "SpaceGrotesk";
            font-weight: 600;
            color: rgba(249, 249, 249, 1);
            text-align: center;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(249, 249, 249, 0.8);
        }
    }

    .cameraBox {
        padding: 4px;
        width: 420px;
        height: 68%;
        max-height: 590px;
        border-radius: 32px;
        background-image: url("../../../../assets/PhotoFrameLarge.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .videoCover {
            border-radius: 22px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        .video {
            min-height: 100%;
            border-radius: 20px;
            max-height: 100%;
        }
    }

    .cameraBoxShort {
        padding: 5px;
        width: 440px;
        height: 290px;
        border-radius: 32px;
        background-image: url("../../../../assets/PhotoFrame.svg");

        .videoCover {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        .video {
            border-radius: 20px;

            min-height: 200% !important;
        }
    }

    .takePhotoBtn {
        width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 100%;
        outline: 10px solid rgba(249, 249, 249, 0.15);
        z-index: 26;
        transition: 0.2s;

        &:hover {
            background-color: rgba(249, 249, 249, 0.8);
        }
    }
    .cameraBtn {
        width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 100%;
        outline: 10px solid rgba(249, 249, 249, 0.15);
    }
    .goBack {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        justify-content: start;
        z-index: 21;
        width: auto;
        cursor: pointer;
        height: auto;
        gap: 16px;
        text-decoration: none;
        margin: 0;
        position: absolute;
        top: 24px;
        left: 34px;
        filter: brightness(0) invert(1);

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .backText {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(122, 132, 153, 1);
        }
    }
    .switchCamera {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        min-height: 50px;
        border-radius: 100%;
        outline: 8px solid rgba(249, 249, 249, 0.15);
        z-index: 26;
        margin: 0;
        margin-right: 0 !important;
        img {
            width: 22px;
            height: 22px;
        }
        &:hover {
            background-color: rgba(249, 249, 249, 0.8);
        }
    }
    .cameraBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-top: 74px;
    }
}
@media (max-width: 750px) {
    .takePhotoType {
        justify-content: center;
        padding: 40px 24px;

        .goBack {
            display: flex !important;
            left: 24px;
        }
        .cameraBox {
            width: 340px;
            height: 68%;
            max-height: 480px;
            background-size: 98% 100%;
            background-position: center;
            padding: 4px 6px;

            .videoCover {
                border-radius: 20px;
            }
        }
        .cameraBoxShort {
            width: 340px;
            height: 227px;
        }
        .cameraBtns {
            margin-top: 80px;
        }
        .switchCamera {
            display: flex;
        }
        .takePhotoBtn {
            margin: 0px !important;
        }
    }
}
@media (max-width: 500px) {
    .takePhotoType {
        padding-bottom: 20px;
        padding-top: 55px;
        justify-content: flex-start;

        .textTPM {
            margin-block: 0px 24px;
            span {
                line-height: 19px;
            }
        }

        .goBack {
            .backText {
                display: none;
            }
        }
        .cameraBox {
            width: 320px;
            height: 68%;
            max-height: 455px;
            background-size: 98% 100%;
            background-position: center;
            padding: 4px 6px;

            .videoCover {
                border-radius: 20px;
                position: relative;
            }
        }
        .cameraBoxShort {
            width: 327px;
            height: 218px;
        }
        .cameraBtns {
            position: absolute;
            bottom: 50px;
        }
    }
}

@media (max-width: 500px) and (min-height: 680px) {
    .takePhotoType {
        padding-bottom: 20px;
        padding-top: 55px;
        justify-content: flex-start;

        .cameraBtns {
            position: absolute;
            bottom: 80px !important;
        }
    }
}

@media (max-height: 900px) and (max-width: 750px) and (min-width: 500px) {
    .takePhotoType {
        box-shadow: none;
        justify-content: center;
        min-height: 700px;
    }
}
@media (max-height: 725px) and (max-width: 750px) {
    .takePhotoType {
        padding-bottom: 50px;
    }
}

@media (max-height: 650px) and (max-width: 500px) {
    .takePhotoType {
        justify-content: flex-start;
        min-height: 650px;

        .cameraBtns {
            position: absolute;
            bottom: 50px;
        }
    }
}
