.edit-rates {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .edit-rates-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 12px;

        img {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }

    .edit-rates-card {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 11.5px;

        .swap {
            width: 24px;
            height: 24px;
            cursor: pointer;

            &:hover {
                filter: brightness(0);
            }
        }
        @media (max-width: 335px) {
            width: 150px !important;
        }
    }
}
