.strategy-invest-deal-container {
  .price-box {
    margin: 0px !important;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-block: 0px !important;
    input {
      height: 22px !important;
      font-size: 22px !important;
    }
  }
  .deal-main {
    label {
      margin-top: 34px;
      margin-bottom: 8px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      color: #4e5566;
    }
    .current-investment-amount {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      label {
        margin-top: 24px;
        margin-bottom: 0px;
      }

      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        p {
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
          color: #2b2f38;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          color: #4e5566;
          padding-left: 8px;
          border-left: 1px solid #bfc4ce;
        }
      }
    }
  }

  .bargaining-alert {
    width: 100%;
    margin-block: 8px;
    padding-block: 8px;
    padding-left: 12px;
    border: 1px solid #eb5757;
    background: #fdeded;
    border-radius: 12px;
    text-align: left;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #eb5757;
    }
  }

  .capital-protection-checkbox {
    margin-top: 0 !important;
  }
  .custom-strategy-checkbox {
    margin-top: 10px !important;
  }
}
