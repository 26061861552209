.account {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: rgba(249, 249, 249, 0.34);
    flex-grow: 1;

    .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .user-image {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 80px;
            height: 80px;
            margin-bottom: 24px;
            position: relative;
            border-radius: 100%;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     background-image: url("../../../assets/ChristmasHat.svg");
            //     background-size: 56px;
            //     width: 56px;
            //     height: 56px;
            //     right: -22px;
            //     top: -18px;
            // }

            .profile-img-active {
                object-fit: cover;
                box-shadow: 0 0 2px rgb(0, 0, 0);
            }

            .editImg {
                display: none;
                position: absolute;
                bottom: 0;
                right: -8px;
            }
            .profile-img {
                border-radius: 100%;
                object-fit: cover;
                width: 80px;
                height: 80px;
            }

            &:hover {
                .editImg {
                    display: block;
                }
            }
        }
        p {
            font-family: "SpaceGrotesk";
            padding: 0 24px;
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
    }
    .acc-verification-status {
        font-family: "SpaceGrotesk";
        font-size: 13px;
        font-weight: 600;
        color: rgb(235, 87, 87);
        width: 100%;
        padding: 0 32px;
        text-align: center;
        margin-bottom: 24px;
    }
    .account-options {
        width: 100%;
        max-width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: white;
        border-radius: 32px 32px 0px 0px;
    }
    .part-title {
        width: 100%;
        margin-top: 24px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(78, 85, 102, 1);
    }

    .take-profile-photo {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 110vh;
        width: 100%;
        background-color: rgba(32, 32, 32, 0.7);
        left: 0;
        z-index: 100;

        video {
            width: 80%;
            border-radius: 10%;
        }

        button {
            margin-top: 40px;
            width: 64px;
            height: 64px;
            min-height: 64px;
            border-radius: 100%;
            outline: 10px solid rgba(249, 249, 249, 0.15);
            z-index: 26;
            transition: 0.2s;

            &:hover {
                background-color: rgba(249, 249, 249, 0.8);
            }
        }
    }
    .acc-take-photo {
        min-width: 330px !important;

        // position: absolute;
        top: -68px !important;

        @media (max-height: 830px) {
            .cameraBtns {
                margin-top: 20%;
                position: static;
            }
        }
        @media (max-height: 700px) {
            .cameraBtns {
                margin-top: 10%;
                position: static;
            }
        }
    }

    .verificationRequestModal {
        .closeDiv {
            img {
                margin-bottom: 3px;
            }
        }
        span {
            margin-bottom: 15px;
        }
    }

    // todo figure this out
    // .Toastify__toast-container {
    //     width: 100% !important;
    // }
}
