.usingPassword {
  z-index: 10;
  width: 555px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 24px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);

  .password-login-choose-acc-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-bottom: 25px;

    button {
      height: 46px;
      width: 40%;
      overflow: hidden;
      background-color: rgba(211, 211, 211, 0.523);

      &:nth-child(1) {
        border-radius: 12px 2px 2px 12px;
      }
      &:nth-child(2) {
        border-radius: 2px 12px 12px 2px;
      }
    }
    .active-type-acc {
      background-color: rgb(0, 15, 48);
      color: white;
    }
  }
}
@media (max-width: 750px) {
  .usingPassword {
    width: 100% !important;
    max-width: 455px !important;
    height: auto;
    padding-top: 0;
    background: inherit;
    box-shadow: none;
    justify-content: center;

    p {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
    form {
      width: 100%;

      .input-container {
        width: 100%;
      }

      input {
        width: 100%;
      }

      .button {
        width: 100%;
      }
    }
  }
}
@media (max-width: 500px) {
  .logoAndTitle {
    img {
      display: none;
    }
  }
}
