.account-option-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);
    background: inherit;
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    .warning-icon {
        position: relative;

        &:after {
            content: "";
            background-image: url("../../../../assets/WarningAcc.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px;
            position: absolute;
            right: -14px;
            top: -4px;
            width: 16px;
            height: 14px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}
