.modalDocType {
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 20;


    .examplePhotoBox {
        padding: 48px 32px;
        background-color: rgba(0, 11, 35, 0.35);
        border-radius: 8px;
        z-index: 21;
    }
    .examplePhotoBoxID {
        padding: 36px 44px;
        z-index: 21;
    }
    .textPDM {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;
        margin-block: 40px;
        text-align: center;
        max-width: 460px;
        z-index: 21;

        p {
            font-size: 28px;
            font-family: "SpaceGrotesk";
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    .goBack {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        justify-content: start;
        z-index: 21;
        width: auto;
        cursor: pointer;
        height: auto;
        gap: 16px;
        text-decoration: none;
        margin: 0;
        position: absolute;
        top: 24px;
        left: 54px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .backText {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(122, 132, 153, 1);
        }
    }
    .backgroundElement {
        position: absolute;
        mix-blend-mode: overlay;
    }
    .backgroundElementTop {
        top: 0;
        right: 0;
    }
    .backgroundElementBottom {
        left: 0;
        bottom: 0;
    }
}
@media (max-width: 750px) {
    .modalDocType {
        box-shadow: none;
        justify-content: center;

        .examplePhotoBox {
            img {
                width: 267px;
                height: 365px;
            }
        }
        .examplePhotoBoxID {
            img {
                width: 228px;
                height: 140px;
            }
        }
        .textPDM {
            margin-top: 70px;
            p {
                font-size: 24px;
            }
        }
        .openCameraBtn {
            position: absolute;
            bottom: 96px;
            width: 80%;
        }
        .goBack {
            display: flex !important;
            left: 24px;
        }
        .doctype {
            background: rgba(249, 249, 249, 1);
        }
    }
}
@media (max-width: 500px) {
    .modalDocType {
        margin-top: 0;
        justify-content: flex-start;
        padding-top: 55px;

        .examplePhotoBox {
            padding: 40px 30px;

            img {
                width: 240px;
                height: 340px;
            }
        }
        .examplePhotoBoxID {
            padding: 36px 44px;
            margin-top: 20%;
            img {
                width: 228px;
                height: 140px;
            }
        }

        .goBack {
            .backText {
                display: none;
            }
        }
        .textPDM {
            margin-top: 25px;
            max-width: 80%;
        }
        .openCameraBtn {
            width: 80%;
            position: absolute;
            bottom: 40px;
        }
        .goBack {
            left: 24px;
        }
        .doctype {
            background: rgba(249, 249, 249, 1);
        }
    }
}

@media (max-height: 900px) and (max-width: 750px) {
    .modalDocType {
        box-shadow: none;
        justify-content: flex-start;
        padding-top: 55px;
        min-height: 700px;
        .examplePhotoBox {
            img {
                width: 240px;
                height: 340px;
            }
        }
        .examplePhotoBoxID {
            img {
                width: 228px;
                height: 140px;
            }
        }
        .textPDM {
            margin-block: 20px;
        }
        .openCameraBtn {
            position: absolute;
            bottom: 50px;
            width: 80%;
        }
    }
}
@media (max-height: 600px) and (max-width: 750px) {
    .modalDocType {
        .openCameraBtn {
            width: 80%;
            position: absolute;
            bottom: 20px !important;
        }
    }
}
@media (max-height: 700px) and (max-width: 500px) {
    .modalDocType {
        justify-content: flex-start;
        min-height: 600px !important;
        .openCameraBtn {
            width: 80%;
            position: absolute;
            bottom: 20px !important;
        }
        .examplePhotoBoxID {
            margin-top: 0%;
        }
    }
}
