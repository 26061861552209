.create-filter {
    max-width: 452px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    padding-top: 93px;
    padding-bottom: 110px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .create-filter-header {
        background-color: #f9f9f9 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 450px;
        height: 93px;
        min-height: 93px;
        position: fixed;
        z-index: 25;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        .rotate-screen {
            // cursor: pointer;
            position: absolute;
            right: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .create-filter-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative !important;
        padding-left: 24px;
        padding-right: 24px;
        // overflow-y: scroll;
        // height: auto;
        // -ms-overflow-style: none;
        // scrollbar-width: none;
        // &::-webkit-scrollbar {
        //     display: none;
        // }

        .filter-title {
            font-family: "SpaceGrotesk";
            font-size: 24px;
            width: 100%;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(43, 47, 56, 1);
            margin-block: 8px;
        }
    }
    .btn-wrapper {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        position: fixed;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        max-width: 450px;
        bottom: 40px;
        z-index: 10;
        left: 50%;
        transform: translate(-50%, 0%);
    }
}
