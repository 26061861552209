.dividents-details-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 8px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);

    .dividents-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 0.5px solid rgba(191, 196, 206, 1);
        margin-top: 8px;

        p {
            font-size: 18px;
            font-weight: 600;
            color: rgb(43, 47, 56);
        }
        span {
            font-size: 16px;
            color: rgb(43, 47, 56);
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .header-DD {
        p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
        p:nth-last-child(1) {
            font-size: 18px;
            font-weight: 600;
        }
    }
    .footer-DD {
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }

    &:nth-last-child(1) {
        border-bottom: none;
    }
}
