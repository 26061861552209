.message-text {
    width: auto;
    max-width: 75%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
    height: auto;
    padding: 8px 12px;
    background: rgba(77, 89, 117, 1);
    border-radius: 12px;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(249, 249, 249, 1);
        user-select: text !important;
        word-break: break-word;

        &::-moz-selection {
            color: black;
            background: rgba(255, 255, 255, 0.5);
        }

        &.last-msg {
            border-radius: 12px 12px 0 12px;
        }
        &::selection {
            color: black;
            background: rgba(255, 255, 255, 0.5);
        }
    }
    span {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.0075em;
        text-align: right;
        color: rgba(191, 196, 206, 1);
    }
    &.last-msg {
        border-radius: 12px 12px 0 12px;
    }
}
.message-admin-text {
    // flex-direction: row-reverse;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background: rgba(226, 228, 233, 1);

    p {
        text-align: left;
        color: rgba(43, 47, 56, 1);

        user-select: text !important;
        &::-moz-selection {
            color: black;
            background: rgba(43, 47, 56, 0.5);
        }
        &::selection {
            color: black;
            background: rgba(43, 47, 56, 0.5);
        }
    }
    span {
        text-align: left;
        color: rgba(78, 85, 102, 1);
    }

    &.last-msg {
        border-radius: 12px 12px 12px 0px;
    }
}
