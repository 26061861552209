.termsCheckbox {
    display: flex;
    align-items: center;
    justify-content: baseline;
    user-select: none;
    gap: 18px;
    cursor: pointer;

    p {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 14px;
        font-weight: 400 !important;
        color: #2b2f38;
        text-align: left !important;
        max-width: 85% !important;
    }
    a {
        font-size: 14px;
        font-weight: 400 !important;
        color: #2f80ed;
        text-decoration: underline;
        margin-left: 4px;
    }
    .brokerage-services {
        color: #f8009e;
    }

    input[type="checkbox"] {
        accent-color: #000f30;
        width: 19px;
        height: 19px;
        cursor: pointer;
    }
}
