.citizenship-verification {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .citizenship-verification-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding-left: 24px;
        padding-right: 24px;

        .citizenship-title {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-block: 20% 10%;
            width: 90%;
            color: rgba(43, 47, 56, 1);
        }

        .request-verification-number {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;

            input {
                width: 100%;
                height: 56px;
                padding-left: 24px;
                border-radius: 8px;
                border: 1px solid rgba(191, 196, 206, 1);
                font-size: 16px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);

                &::placeholder {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(122, 132, 153, 1);
                }

                &:focus {
                    outline: 1px solid rgb(43, 47, 56);
                    border-color: rgb(43, 47, 56);
                }
            }

            span {
                width: 98.5%;
                text-align: left;
                color: rgba(235, 87, 87, 1);
                font-size: 12px;
                padding: 0;
                margin: 0;
                animation: validation 1s ease;
            }

            @keyframes validation {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }

        .has-usa-checkbox {
            margin-top: 6px;
            width: 98%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 14px;
            text-align: left;

            .checkbox-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 24px;

                .termsCheckbox {
                    margin-top: 0px;
                }
            }
        }
    }
}
