.change-phone {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    overflow: hidden;

    .change-phone-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 26px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
    }
    .change-phone-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;

        p {
            font-family: "SpaceGrotesk";
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            color: rgba(43, 47, 56, 1);
            margin-bottom: 12px;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }

        .form-change-phone {
            width: 100%;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            label {
                font-size: 14px;
                font-weight: 600;
                width: 100% !important;
                text-align: left;
                color: rgba(78, 85, 102, 1);
            }
            .validation {
                color: rgb(190, 1, 13);
                margin-top: -5px;
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                text-align: left;
                margin-top: 8px;
            }
            .react-international-phone-input-container {
                margin-top: 8px;
            }
            .react-international-phone-country-selector-button__button-content {
                width: 100px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12.5px;
                border: 1px solid rgba(191, 196, 206, 1);
                border-radius: 12px 0 0 12px;
            }
            .react-international-phone-country-selector-button {
                width: 100px;
                height: 56px;
                border: none;
            }
            .react-international-phone-country-selector-button__dropdown-arrow {
                border-top: var(--react-international-phone-country-selector-arrow-size, 0px) solid
                    var(--react-international-phone-country-selector-arrow-color, #777);
                border-right: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
                border-left: var(--react-international-phone-country-selector-arrow-size, 0px) solid transparent;
                transition: all 0.2s ease-out;
                background-image: url("../../../assets/Shape.svg");
                width: 14px;
                height: 8px;
                background-repeat: no-repeat;
                margin: 0;
            }
            .react-international-phone-input-container .react-international-phone-input {
                height: 56px;
                max-width: 300px;
                width: 300px;
                // width: 356px;
                border: 1px solid rgba(191, 196, 206, 1);
                border-radius: 0 12px 12px 0;
                font-size: 16px;
                padding-left: 24px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
            .react-international-phone-country-selector-dropdown {
                // width: 456px;
                width: 402px;
                margin-top: 16px;
                height: 400px;
                border: 1px solid rgba(191, 196, 206, 1);
                border-radius: 12px;
                padding: 0;
                &::-webkit-scrollbar {
                    display: none;
                }
                .react-international-phone-country-selector-dropdown__list-item {
                    border-top: 1px solid rgba(191, 196, 206, 1);
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 24px;

                    &:first-child {
                        border-top: none;
                    }

                    span {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(43, 47, 56, 1);
                        margin: 0;
                    }
                    .react-international-phone-country-selector-dropdown__list-item-dial-code {
                        margin-left: 10px;
                    }
                }
            }

            @media (max-width: 750px) {
                width: 100%;
                max-width: 100% !important;

                .react-international-phone-input-container .react-international-phone-input {
                    max-width: 300px;
                    min-width: 200px;
                }
                .react-international-phone-country-selector-dropdown {
                    width: 402px !important;
                }
            }
            @media (max-width: 450px) {
                .react-international-phone-input-container .react-international-phone-input {
                    width: 227px !important;
                }
                .react-international-phone-country-selector-dropdown {
                    width: 327px !important;
                }
                label {
                    width: 327px !important;
                }
                .validation {
                    width: 327px !important;
                }
            }
            @media (max-width: 370px) {
                .react-international-phone-input-container .react-international-phone-input {
                    width: 200px !important;
                }
                .react-international-phone-country-selector-dropdown {
                    width: 300px !important;
                }
                label {
                    width: 300px !important;
                }
                .validation {
                    width: 300px !important;
                }
            }
        }
    }
}
