.chat {
    width: 100%;
    max-width: 450px;
    gap: 16px;
    padding: 20vh 24px 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(249, 249, 249, 0.34);
}
