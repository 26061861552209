.message-file {
    min-width: 200px;
    height: 68px;
    width: 50%;
    min-height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 5px 8px;
    background: rgba(77, 89, 117, 1);
    border-radius: 12px;
    position: relative;
    text-decoration: none !important;
    overflow: hidden;
    cursor: pointer;

    // span {
    //     max-width: 38px;
    //     font-size: 10px;
    //     font-weight: 500;
    //     line-height: 15px;
    //     letter-spacing: 0.0075em;
    //     text-align: center;
    //     // color: rgba(191, 196, 206, 1);
    //     color: rgb(253, 253, 253);
    //     background-color: rgba(0, 0, 0, 0.35);
    //     padding: 0 6px 0 6px;
    //     border-radius: 10px;
    //     position: absolute;
    //     bottom: 6px;
    //     right: 8px;
    // }

    .created-at {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.0075em;
        color: rgba(191, 196, 206, 1);
        position: absolute;
        right: 12px;
        bottom: 8px;
    }

    .file-type {
        width: 54px;
        min-width: 54px;
        height: 54px;
        min-height: 54px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.15);
        background-repeat: no-repeat;
        background-position: -55px -55px;
        background-image: linear-gradient(
            135deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 30%,
            rgba(255, 255, 255, 0.4) 47%,
            rgba(255, 255, 255, 0) 50%
        );
        // background-position: center;

        // color: rgba(236, 234, 234, 0.7);
        color: rgba(255, 255, 255, 0.8);

        font-weight: 700;
        font-size: 18px;
        font-family: "SpaceGrotesk";
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }
    .file-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
            font-size: 16px;
            color: rgba(249, 249, 249, 1);
            overflow: hidden;

            &:first-letter {
                text-transform: uppercase;
            }
        }
        span {
            font-size: 10px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.0075em;
            color: rgba(191, 196, 206, 1);
        }
    }
    &.last-msg {
        border-radius: 12px 12px 0 12px;
    }
    &:hover {
        transition-delay: 1s;

        .file-type {
            // animation: shine 1s ease-in-out;
            background-position: 55px 55px;
            transition-duration: 0.7s;
        }
        // @keyframes shine {
        //     0%,
        //     100% {
        //         opacity: 1;
        //     }
        //     50% {
        //         opacity: 0.7;
        //     }
        // }
    }
}
.message-admin-file {
    background: rgba(226, 228, 233, 1);

    .file-type {
        color: rgba(27, 27, 27, 0.7);
    }
    .file-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
            color: rgba(43, 47, 56, 1);
        }
        span {
            color: rgba(78, 85, 102, 1);
        }
    }
    .created-at {
        color: rgba(78, 85, 102, 1) !important;
    }

    &.last-msg {
        border-radius: 12px 12px 12px 0px;
    }
}
@media (max-width: 345px) {
    .message-file {
        width: 70px;
        min-width: 70px;
        .file-info {
            display: none;
        }
    }
}
