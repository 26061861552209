.currency-btn-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    span {
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: rgba(78, 85, 102, 1);
    }

    .currency-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        width: 100%;
        padding: 0px 24px 0px 24px;
        border-radius: 12px;
        border: 1px solid rgba(191, 196, 206, 1);

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
        }

        img {
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }
    }
}
