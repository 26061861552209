.new-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  border-radius: 6px;
  position: relative;

  .chart__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 2px;
    position: relative !important;

    .range-btns {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      row-gap: 8px;

      button:nth-last-child(1) {
        flex-grow: 1;
        max-width: 90px;
        min-width: 58px;
      }
      @media (max-width: 398px) {
        gap: 8px;
        button:nth-last-child(1) {
          flex-grow: 1;
          max-width: none;
        }
      }
    }

    .switch-chart-type {
      position: absolute;
      // top: 0px;
      bottom: -32px;
      // top: 55px;
      left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 26px;
      height: 26px;
      background: rgba(0, 15, 48, 0.5);
      border-radius: 6px;
      z-index: 5;
      transition: 0.3s;

      &:hover {
        background: rgba(0, 15, 48, 1);
      }
    }
  }

  .chart-view {
    width: 100%;
    height: 345px;
    min-height: 345px;
    max-height: 345px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  }

  .tradingView-license {
    width: 100%;
    color: #2b2f38;
    text-align: left;
    padding: 1px 4px;
    font-size: 11px;
    line-height: 14px;

    a {
      color: #333;
      text-decoration: underline;
    }
  }
}
