.account-card {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // overflow: hidden;
    gap: 6px;
    border-bottom: 0.5px solid rgba(230, 231, 235, 1);

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    span {
        color: rgba(43, 47, 56, 1);
        font-size: 16px;
        font-weight: 400;
    }
    p {
        font-size: 16px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
    }

    .hidden-price {
        filter: blur(8px);
        padding-right: 4px;
    }
}
