.bond-details {
    max-width: 452px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(251, 251, 251);
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 50;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .bond-details-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 93px;
        min-height: 93px;
        margin-bottom: 12px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        .rotate-screen {
            // cursor: pointer;
            position: absolute;
            right: 24px;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            // row-gap: ;
            p {
                font-size: 16px;
                font-weight: 600;
                color: #2b2f38;
            }
            span {
                max-width: 85%;
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
        }
    }
    .tab-bar {
        display: flex;
        gap: 16px;
        overflow: scroll;
        width: 100%;
        padding: 20px 24px;
        position: sticky;
        min-height: 77px;
        top: 0px;
        z-index: 10;
        background: rgba(251, 251, 251);

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .bond-details-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 24px 120px 24px;

        .bond-info-bargaining {
            display: flex;
            margin-top: 16px;
            margin-bottom: 16px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 16px;
            text-align: left;
            div {
                display: flex;
                align-items: center;
                gap: 8px;
            }
            .price {
                font-size: 24px;
                font-weight: 600;
                color: rgba(0, 15, 48, 1);
            }
            span {
                font-size: 14px;
                font-weight: 600;
                color: rgba(39, 174, 96, 1);
            }
            .loss {
                color: rgba(235, 87, 87, 1);
            }
            .gain-percent {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .gains-percent {
                    border-left: 2px solid rgba(191, 196, 206, 1);
                    padding-left: 8px;
                    margin-left: 8px;
                    margin-top: 0;
                    height: 20px;
                    line-height: 20px;
                }
            }
            p {
                font-size: 11px;
                font-weight: 500;
                color: rgba(78, 85, 102, 1);
            }
        }
        // .charts {
        //     width: 100%;
        //     height: 325px;
        //     position: relative;
        //     .chart-type-icon {
        //         position: absolute;
        //         cursor: pointer;
        //         width: 22px;
        //         height: 22px;
        //         background: rgba(0, 15, 48, 1);
        //         border-radius: 100%;
        //         top: 9px;
        //         left: 9px;
        //     }
        // }
        .transaction-info-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-block: 24px;
            .transaction-info-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 32px 8px 0px;
                border-bottom: 0.5px solid rgba(191, 196, 206, 1);

                p {
                    max-width: 65px;
                    margin: 0;
                    padding: 0;
                    font-size: 10px;
                    font-weight: 500;
                    text-align: center;
                    text-transform: capitalize;
                    color: rgba(78, 85, 102, 1);
                }
            }
        }
        // ..
        .dividents-title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                color: rgba(78, 85, 102, 1);
            }
        }
        // ..
        .indicators-title {
            width: 100%;
            text-align: left;
            margin-block: 16px;
            p {
                font-size: 20px;
                font-weight: 620;
                color: rgba(43, 47, 56, 1);
            }
        }
        // ..
        .review-title {
            width: 100%;
            text-align: left;
            margin-block: 16px;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 620;
                color: rgba(43, 47, 56, 1);
            }
        }
        .description-span {
            margin-bottom: 16px;
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
        .description-details {
            padding-block: 16px;
            width: 100%;
        }
        .info-details {
            width: 100%;
        }
        // ..
        .nearest-coupon {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            width: 100%;
            background-color: white;
            border-radius: 16px;
            padding: 16px 16px 32px 16px;
            margin-bottom: 32px;
            .title-NC {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 620;
                color: rgba(43, 47, 56, 1);
            }
            .main-NC {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;
                div {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &:first-child {
                        p {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(43, 47, 56, 1);
                        }
                        span {
                            font-size: 16px;
                            font-weight: 600;
                            text-align: right;
                            color: rgba(43, 47, 56, 1);
                        }
                    }
                    &:nth-last-child(1) {
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            color: rgba(78, 85, 102, 1);
                        }
                        span {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: right;
                            color: rgba(78, 85, 102, 1);
                        }
                    }
                }
            }
            .nkd-progress {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .nkd-progress-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .nkd-progress-header-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(78, 85, 102, 1);
                        }

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                    span {
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: rgba(248, 0, 158, 1);
                    }
                }
                .progress-line-bond {
                    padding: 0;
                    .rs-progress-line-inner,
                    .rs-progress-line-bg {
                        height: 6px;
                    }
                    .rs-progress-line-inner {
                        background: rgba(254, 230, 245, 1);
                    }
                }
            }
        }
    }

    .action-btns-wrapper {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        position: fixed !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 440px;
        bottom: 40px;
    }

    .highcharts-axis-title {
        display: none !important;
    }
    .highcharts-credits {
        display: none !important;
    }

    .bargaining-alert {
        width: 100%;
        margin-block: 8px;
        padding-block: 8px;
        padding-left: 12px;
        border: 1px solid #eb5757;
        background: #fdeded;
        border-radius: 12px;
        text-align: left;

        span {
            font-size: 14px;
            font-weight: 500;
            color: #eb5757;
        }
    }

    .reliability-rating {
        width: 100%;
        .rr-text {
            width: 100%;
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            color: rgba(43, 47, 56, 1);
            margin-bottom: 8px;
        }
    }
}
