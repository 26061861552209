.reliability-rating-row {
    width: 100%;
    padding: 0 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
        font-size: 16px !important;
        font-weight: 400 !important;
        text-align: left !important;
        color: rgba(78, 85, 102, 1) !important;
    }
}
