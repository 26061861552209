.assignments-history-field-wrapper {
    width: 100%;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    .assignments-history-field {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #bfc4ce;
        padding: 10px 0px;

        &:hover {
            border-bottom: 0.5px solid rgb(43, 47, 56);
            transition: 0.3s;
        }

        p {
            font-size: 15px;
            font-weight: 600;
            color: rgb(43, 47, 56);
        }

        span {
            font-size: 13px;
            font-weight: 400;
            color: #4e5566;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 18px;
                margin-left: 4px;
            }
        }

        .field-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            min-height: 42px;
        }
        .field-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: right;
            min-height: 42px;

            .status-date {
                display: flex;
                text-wrap: nowrap;

                @media (max-width: 345px) {
                    text-wrap: wrap;
                }
            }
            .status-field {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;

                .status-field {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                }
                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: right;
                }
                img {
                    width: 18px;
                }
            }
        }
    }
}

.assignments-pending-field-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #bfc4ce;
    width: 100%;
    text-decoration: none;
    padding: 10px 0px;

    .assignments-history-field {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: none;
        padding: 0px !important;

        &:hover {
            border-bottom: none;
        }
    }

    .pending-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;

        button {
            width: 48%;
            height: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            transition: 0.1s;

            font-size: 14px;
            font-weight: 600;
        }
    }

    &:hover {
        border-bottom: 0.5px solid rgb(43, 47, 56);
        transition: 0.3s;
    }
}
