.enterTheCodeAccount {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    overflow: hidden;

    .enterTheCodeAccount-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 26px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
    }

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
        max-width: 80%;
    }
    span {
        margin: 10px 0 40px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }
    .codeTextRe {
        margin-top: 38px;
        margin-bottom: 36px;
    }
    .sendCodeAgain {
        font-size: 14px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }
    .resendCode {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
        cursor: pointer;
    }
    .editYourNumber {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 15, 48, 1);
        cursor: pointer;
        padding: 0;
        margin: 0;
    }

    .green {
        border: 1px solid rgba(39, 174, 96, 1);
    }
    .red {
        border: 1px solid rgba(235, 87, 87, 1);
        color: rgba(235, 87, 87, 1);
    }
    .error-verify {
        color: rgba(235, 87, 87, 1);
        font-size: 12px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
    }
    input {
        border-radius: 12px;
        width: 60px;
        height: 56px;
        padding: 0;
        font-size: 18px;
        text-align: center;
        margin-right: 12px;
        text-transform: uppercase;
        color: #494949;
        border: 1px solid rgba(191, 196, 206, 1);
        gap: 24px;
        
        &:nth-last-child(1) {
            margin-right: 0;
        }
        &:focus {
            appearance: none;
            outline: 0;
            border: 1px solid rgba(0, 15, 48, 1);
        }

        button {
            appearance: none;
            background: #0071e3;
            border-radius: 980px;
            text-align: center;
            border: none;
            color: #fff;
            margin: 8px;
            padding: 4px 11px;
        }

        p {
            font-size: 17px;
            line-height: 1.47059;
        }

        .props {
            border: 1px solid #d6d6d6;
            padding: 8px 16px;
        }

        .options {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .allowed-characters {
            text-align: left;
        }
    }
}
