.replenishment-details {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: rgba(78, 85, 102, 1);
    }

    .details-row {
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px dashed rgba(191, 196, 206, 1);

        span {
            text-align: left;
            overflow-wrap: break-word;
            word-break: break-all;
            width: 80%;
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
        .check-img {
            padding-block: 0.5px;
        }
    }
    button {
        background: none;
    }
}
