.modal {
    padding: 24px;
    max-width: 380px;
    width: 80%;
    height: auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    // border: 2px solid black;
    text-align: center;

    button {
        width: 100% !important;
    }

    .closeDiv {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .close {
            margin-bottom: 29px;
            cursor: pointer;
        }
    }
    .closeConfirmDeal {
        .close {
            margin-bottom: 12px;
            cursor: pointer;
        }
    }

    p {
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 600;
        color: #2b2f38;
        margin-bottom: 16px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: #4e5566;
        margin-bottom: 24px;
    }
    .secondBtn {
        margin-top: 16px;
        background: white;
        border: 1px solid rgba(0, 15, 48, 1);
        color: rgba(43, 47, 56, 1);
    }
    .authCode-wrapper {
        margin-block: 24px 40px;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            flex: 50%;
            gap: 12px;
            row-gap: 8px;

            @media (max-width: 400px) {
                input {
                    width: 55px;
                    height: 56px;
                }
            }
            @media (max-width: 380px) {
                gap: 8px;
                input {
                    width: 50px;
                    height: 50px;
                }
            }
            @media (max-width: 340px) {
                gap: 5px;
                input {
                    width: 46px;
                    height: 46px;
                }
            }
            @media (max-width: 320px) {
                gap: 12px;
                row-gap: 8px;
                input {
                    width: 57px;
                    height: 56px;
                }
            }
        }

        .error-verify {
            color: rgba(235, 87, 87, 1);
            font-size: 12px;
            padding: 0;
            margin: 0;
            margin-top: 5px;
        }
        input {
            border-radius: 12px;
            width: 57px;
            height: 56px;
            padding: 0;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #494949;
            border: 1px solid rgba(191, 196, 206, 1);
            gap: 24px;

            &:focus {
                appearance: none;
                outline: 0;
                border: 1px solid rgba(0, 15, 48, 1);
            }

            button {
                appearance: none;
                background: #0071e3;
                border-radius: 980px;
                text-align: center;
                border: none;
                color: #fff;
                margin: 8px;
                padding: 4px 11px;
            }

            p {
                font-size: 17px;
                line-height: 1.47059;
            }

            .props {
                border: 1px solid #d6d6d6;
                padding: 8px 16px;
            }

            .options {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .allowed-characters {
                text-align: left;
            }
        }
    }
}
.closedModal {
    display: none;
}
.outlet {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1010;
}

.deposit-fields-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 28px;

    .deposit-modal-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        label {
            font-size: 14px;
            font-weight: 600;
            color: rgba(105, 111, 121, 1);
        }
        input {
            width: 100%;
            height: 52px;
            padding-left: 16px;
            border-radius: 8px;
            border: 1px solid rgba(191, 196, 206, 1);
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);

            &::placeholder {
                font-size: 16px;
                font-weight: 400;
                color: rgba(122, 132, 153, 1);
            }

            &:focus {
                outline: none;
                border: 2px solid #000000;
                padding-left: 15px;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    .info-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
        cursor: pointer;
        padding: 0 4px;

        label {
            // width: 60px;
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            color: rgba(105, 111, 121, 1);
        }
        div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            span {
                margin: 0 !important;
                font-size: 14px;
                font-weight: 400;
                color: #4e5566;
                text-align: right;

                // &:hover {
                //     text-decoration: underline;
                // }
            }
            img {
                width: 22px;
                height: 22px;
                border-radius: 25%;
            }
        }

        &:hover {
            opacity: 0.5;
        }
    }
}
