.registerEmail {
    width: 558px;
    height: 536px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    span {
        margin: 10px 0 56px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }
    label {
        font-size: 14px;
        font-weight: 600;
        width: 80%;
        color: rgba(78, 85, 102, 1);
    }
    .validation {
        color: rgb(190, 1, 13);
        margin-top: -5px;
        position: absolute;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: left;
        margin-top: 8px;
    }
}
@media (max-width: 750px) {
    .registerEmail {
        width: 100%;
        max-width: 455px;
        height: auto;
        padding-top: 0;
        background: inherit;
        box-shadow: none;
        justify-content: center;
        
        label {
            width: auto;
        }
        p {
            width: 100%;
            max-width: 100%;
        }
        span {
            max-width: 100%;
        }

        form {
            width: 100%;
        }
        .form-row {
            width: 100%;

            input {
                width: 100%;
                background: inherit;
            }
        }
        .button {
            width: 100%;
        }
    }
}
