.chat__typing-animation {
  display: inline-block;
}

.chat__typing-animation-dot {
  animation: blink 1.4s infinite both;
  display: inline-block;
  margin: 0 2px;
  font-size: 32px;
  line-height: 8px;
}

.chat__typing-animation-dot:nth-child(1) {
  animation-delay: 0s;
}
.chat__typing-animation-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.chat__typing-animation-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
