.login {
    width: 555px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 1;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
    }
    .loginBtns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    .navLinkLoginMobile {
        display: none;
        margin-top: 40px;

        p,
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
        span {
            text-decoration: underline;
        }
    }

    @media (max-width: 500px) {
        .navLinkLoginMobile {
            display: block;
        }
    }
}
.forgotPassword {
    font-size: 14px;
    font-weight: 400;
    color: rgba(78, 85, 102, 1);
    margin-top: 36px;
    text-decoration: underline;
    cursor: pointer;
}
@media (max-width: 750px) {
    .login {
        width: 100% !important;
        max-width: 455px !important;
        height: auto;
        padding-top: 0;
        background: inherit;
        box-shadow: none;
        justify-content: center;
        
        p {
            width: 100%;
            max-width: 100%;
        }
        span {
            max-width: 100%;
        }
        .logoAndTitle {
            img {
                display: none;
            }
        }

        .loginBtns {
            width: 100%;
            gap: 24px;
            .button{
                width: 100%;
            }
        }
    }
}
