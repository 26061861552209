@import "../../../styles/main.scss";

.fractional-status {
    width: 100%;
    max-width: 450px;
    max-height: 50vh;
    height: auto;
    padding: 12px 16px 24px 16px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: status 0.5s ease-out;

    .fractional-status-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        margin-bottom: 4px;

        .fractional-status-symbol {
            @include flex-center;
            width: 28px;
            height: 28px;
            min-height: 28px;
            border-radius: 8px;

            img {
                width: 28px !important;
                height: 28px !important;
                filter: brightness(0.95);
            }
        }
        p {
            text-align: left;
            font-size: 20px;
            font-family: "SpaceGrotesk";
            font-weight: 900;
            color: rgb(149, 152, 161);
        }
    }
    .status-info {
        width: 100%;
        font-size: 16px;
        color: #2b2f38;
        text-align: left;
        padding: 0 12px 0 34px;
        font-weight: 400;
    }
}
@keyframes status {
    from {
        max-height: 0;
    }
    to {
        max-height: 50vh;
    }
}
.outlet-fractional-status-modal {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0px;
    z-index: 100;
    position: absolute;
}
