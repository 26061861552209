.doctype {
    display: flex;
    align-items: center;
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    height: 59px;
    border-radius: 12px;
    padding-left: 24px;
    cursor: pointer;
    position: relative;

    p {
        font-size: 18px !important;
        font-weight: 600 !important;
        color: rgba(43, 47, 56, 1) !important;
    }

    .vector {
        position: absolute;
        right: 28px;
        transition: 0.13s;
    }

    &:hover .vector {
        right: 22px;
    }
    &:hover {
        background-color: rgba(216, 217, 220, 0.55);
    }
}
