.exchange-source-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .exchange-source {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;
        width: auto;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        padding: 0px 12px;
        border: 1px solid #e0e3eb;
        border-radius: 8px;
        gap: 8px;
        position: relative;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #2b2f38;
            font-family: "SpaceGrotesk";
            position: relative;
            display: flex;
            align-items: center;
            padding-right: 11px;

            &:after {
                content: "";
                width: 3px;
                height: 3px;
                background-color: #2b2f38;
                position: absolute;
                right: 0;
                border-radius: 100%;
            }
        }
        .imgLess {
            padding-right: 0px;

            &:after {
                content: "";
                display: none;
            }
        }
        img {
            border-radius: 100%;
            width: 17px !important;
            height: 17px !important;
        }
    }
    .source-name-wrapper {
        transition: 0.5s;
        opacity: 0;
        position: absolute;
        top: -26px;
        z-index: 10;
        right: 0;

        .source-name {
            width: auto;
            height: 20px;
            border-radius: 4px;
            background-color: #131722ab;
            font-size: 0.7em;
            padding: 0 6px;
            max-width: 200px;
            color: white;
            position: relative;
            display: flex;
            justify-content: center;
            white-space: nowrap;

            &::after {
                content: "";
                position: absolute;
                right: 15%;
                bottom: -4px;
                width: 4px;
                height: 4px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #131722ab;
            }
        }
    }

    &:hover {
        .source-name-wrapper {
            transition: 0.3s;
            opacity: 1;
        }
    }
}
