.market-asset {
    width: 100%;
    height: auto;
    min-height: 72px;
    padding-block: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(230, 231, 235, 1);
    cursor: pointer;

    .asset-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        width: 70%;

        img {
            height: 40px;
            max-width: 40px;
            width: 40px;
            object-fit: contain;
            // -webkit-filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0 rgba(0, 0, 0, 0.5));
            // filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 0 rgba(0, 0, 0, 0.5));
        }

        .asset-stock-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 4px;
        }

        div {
            display: flex;
            flex-direction: column;
            text-align: left;
            p {
                font-size: 16px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
            }
            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
        }
    }
    .asset-info-span {
        -webkit-line-clamp: 2 !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .price-gains-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    .price-gains {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        text-align: right;
        row-gap: 4px;

        p {
            font-size: 18px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            white-space: nowrap;
        }

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: right;
            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(39, 174, 96, 1);
            }
            .gains-percent {
                border-left: 1px solid rgba(191, 196, 206, 1);
                padding-left: 6px;
                margin-left: 6px;
                height: 12px;
                line-height: 12px;
            }
            &.loss {
                span {
                    color: rgba(235, 87, 87, 1) !important;
                }
            }
        }

        .price-gains-currency {
            display: flex;
            flex-direction: row;
            justify-content: center;
            p {
                margin: 0;
                font-size: 16px;
            }
        }
    }

    .about-asset {
        margin: 0 8px 0 8px;
        height: 100%;
        cursor: pointer;
    }

    @media (max-width: 320px) {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .price-gains {
            width: 100%;
        }
    }

    &:nth-last-child(1) {
        border-bottom: none;
    }
}
