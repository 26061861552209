.history-search {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .history-search-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        min-height: 72px;
        animation: search 1s;

        img {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .history-search-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 502px;
        padding: 0 24px 24px 24px;
        animation: search 1s;
        overflow-y: scroll;
        height: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        form {
            margin-block: 8px;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;

            input {
                padding-right: 46px;
                padding-left: 16px;
                width: 100%;
                height: 48px;
                min-height: 48px;
                border-radius: 16px;
                border: 0.5px solid #e6e7eb;
                background: rgba(255, 255, 255, 1);
                font-size: 16px;
                font-weight: 400;
                color: #2b2f38;
                cursor: text;

                &:focus {
                    outline: none;
                    box-shadow: 0 2px 4px -4px black;
                }
            }

            img {
                cursor: pointer;
                position: absolute;
                right: 21px;
            }
        }
        .history {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-top: 16px;
            animation: search 1s;

            .history-header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #4e5566;
                }
                p {
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.0075em;
                    color: #000f30;
                }
            }
            .history-row {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                height: 48px;
                min-height: 48px;

                span {
                    overflow: hidden;
                    max-width: 65%;
                    width: auto;
                    position: relative;
                }
            }
        }
        .search-query-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            animation: search 1s;

            .query-type {
                width: 100%;
                background-color: white;
                border-radius: 16px;
                margin-block: 8px;
                position: relative;
                button {
                    width: 50%;
                    height: 44px;
                    min-height: 44px;
                    border-radius: 16px;
                    background-color: inherit;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    color: #2b2f38;
                    transition: 0s;
                }
                .active-btn {
                    color: #f9f9f9;
                    transition:
                        all 0.7s,
                        color 0.3s;
                    background: #000f30;
                }
            }

            .history-part-title {
                width: 100%;
                text-align: left;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.015em;
                color: #4e5566;
                margin-top: 8px;
            }
        }
    }
    .empty-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 20%;
        gap: 4px;

        img {
            width: 64px;
            height: 61px;
            margin-bottom: 13px;
        }
        p {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: rgba(78, 85, 102, 1);
        }
    }
}
@keyframes search {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
