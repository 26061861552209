.save-to-favorites-star {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 24px;
    width: auto;
    background: inherit;
    border-radius: 100%;

    img {
        width: 21px;
    }
}
