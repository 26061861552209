.enterTheCode {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    padding-top: 0;

    .enterTheCode-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 65px;
        margin-bottom: 12px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        .rotate-screen {
            // cursor: pointer;
            position: absolute;
            right: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .enterTheCode-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
    }
}

// .enterTheCode {
//     width: 558px;
//     height: 536px;
//     border-radius: 24px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding-top: 48px;
//     background: rgba(255, 255, 255, 1);
//     box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
//     gap: 0;
//     z-index: 10;
//     position: relative;

//     p {
//         font-family: "SpaceGrotesk";
//         font-size: 28px;
//         font-weight: 600;
//         color: rgba(43, 47, 56, 1);
//         text-align: center;
//     }
//     span {
//         margin: 10px 0 40px 0;
//         font-size: 16px;
//         font-weight: 400;
//         color: rgba(78, 85, 102, 1);
//         text-align: center;
//         max-width: 80%;
//     }
//     .codeTextRe {
//         margin-top: 70px;
//     }
//     .sendCodeAgain {
//         font-size: 14px;
//         font-weight: 400;
//         color: rgba(78, 85, 102, 1);
//     }
//     .resendCode {
//         font-size: 16px;
//         font-weight: 600;
//         color: rgba(0, 15, 48, 1);
//         cursor: pointer;
//     }
//     .editYourNumber {
//         font-size: 16px;
//         font-weight: 600;
//         color: rgba(0, 15, 48, 1);
//         cursor: pointer;
//     }

//     .green {
//         border: 1px solid rgba(39, 174, 96, 1);
//     }
//     .red {
//         border: 1px solid rgba(235, 87, 87, 1);
//         color: rgba(235, 87, 87, 1);
//     }
//     .error-verify {
//         color: rgba(235, 87, 87, 1);
//         font-size: 12px;
//         padding: 0;
//         margin: 0;
//         margin-top: 5px;
//     }
//     input {
//         border-radius: 12px;
//         width: 60px;
//         height: 56px;
//         padding: 0;
//         font-size: 18px;
//         text-align: center;
//         margin-right: 12px;
//         text-transform: uppercase;
//         color: #494949;
//         border: 1px solid rgba(191, 196, 206, 1);
//         gap: 24px;

//         &:nth-last-child(1) {
//             margin-right: 0;
//         }
//         &:focus {
//             appearance: none;
//             outline: 0;
//             border: 1px solid rgba(0, 15, 48, 1);
//         }

//         button {
//             appearance: none;
//             background: #0071e3;
//             border-radius: 980px;
//             text-align: center;
//             border: none;
//             color: #fff;
//             margin: 8px;
//             padding: 4px 11px;
//         }

//         p {
//             font-size: 17px;
//             line-height: 1.47059;
//         }

//         .props {
//             border: 1px solid #d6d6d6;
//             padding: 8px 16px;
//         }

//         .options {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//         }

//         .allowed-characters {
//             text-align: left;
//         }
//     }
// }
