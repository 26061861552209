.price-type {
    width: 100%;
    max-width: 452px;
    max-height: 60vh;
    height: auto;
    padding: 0px 0px 40px 0px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: priceType 0.5s ease-out;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .price-type-header {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: fixed;
        border-radius: 16px 16px 0px 0px;
        padding: 21px 24px 16px 0px;
        background-color: rgba(249, 249, 249, 1);
        img {
            cursor: pointer;
        }
    }
    .price-type-main {
        width: 100%;
        padding: 46px 24px 0px 24px;
    }
    .price-type-row {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 12px;
        min-height: 72px;
        border-bottom: 0.5px solid #bfc4ce;
        gap: 12px;
        text-align: left;

        p {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #2b2f38;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;   
            color: #4e5566;
        }
    }
}
@keyframes priceType {
    from {
        max-height: 0;
    }
    to {
        max-height: 60vh;
    }
}
.outlet-price-type {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0;
    z-index: 100;
    position: absolute;
}
