.open-chat-btn {
    width: 100%;
    height: auto;
    min-height: 100px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: white;
    cursor: pointer;
    position: relative;

    &:hover {
        background: rgba(242, 243, 245, 1);
    }
    &:active {
        background: rgba(230, 231, 235, 1);
    }

    div {
        display: flex;
        flex-direction: column;
        text-align: left;

        p {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: rgba(78, 85, 102, 1);
        }
    }
}
