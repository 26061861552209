.form-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 24px;

    label {
        font-size: 14px;
        font-weight: 600;
        color: rgba(105, 111, 121, 1);
    }
    input {
        width: 456px;
        height: 56px;
        padding-left: 24px;
        border-radius: 8px;
        border: 1px solid rgba(191, 196, 206, 1);
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);

        &::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: rgba(122, 132, 153, 1);
        }
    }
    .error {
        margin: 0;
        font-size: 14px !important;
        text-align: left;
    }
}
