.withdrawal-amount {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    label {
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
    }
    input {
        width: 100%;
        height: 56px;
        border-radius: 8px;
        padding: 16px 24px 16px 24px;
        border: 1px solid rgba(191, 196, 206, 1);
        -moz-appearance: textfield;
        background-color: inherit;

        &:focus {
            outline: none;
            border: 2px solid black;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .wrong-value {
        border: 1.7px solid rgba(235, 87, 87, 1);
        color: rgba(235, 87, 87, 1);

        &:focus {
            outline: none;
            border: 2px solid rgba(235, 87, 87, 1);
        }
    }
}
.customWithdrawalOutlet {
    max-width: 452px;
}
.modalWithdrawal {
    width: 90%;
    max-width: 400px;

    .close {
        margin-bottom: 5px !important;
    }
    span {
        width: 90%;
    }
    button {
        width: 100%;
    }
}
