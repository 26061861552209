.submitPhotoModal {
    background-color: rgba(242, 243, 245, 1);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 30;
    top: 0;

    span {
        margin-top: 38px;
        cursor: pointer;
        font-size: 28px;
        font-size: 16px;
        font-weight: 600;
        z-index: 31;
        color: rgba(0, 15, 48, 1);
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        margin-block: 40px;
        z-index: 31;
    }
    button {
        z-index: 31;
        min-height: 56px;
    }
    .docPhoto {
        height: 68%;
        max-height: 590px;
        overflow: hidden;
        z-index: 31;
        width: 420px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 22px;

        img {
            width: auto;
            height: 100%;
            max-height: 590px;
            border-radius: 20px;
        }
    }
    .docPhotoShort {
        width: 440px;
        height: 290px;
        max-height: 290px;
        overflow: hidden;
        z-index: 31;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 22px;

        img {
            width: auto;
            height: 100%;
            max-height: 290px;
            border-radius: 20px;
        }
    }
    .goBack {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        justify-content: start;
        z-index: 21;
        width: auto;
        cursor: pointer;
        height: auto;
        gap: 16px;
        text-decoration: none;
        margin: 0;
        position: absolute;
        top: 24px;
        left: 54px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .backText {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(122, 132, 153, 1);
        }
    }
    .backgroundElement {
        position: absolute;
        mix-blend-mode: overlay;
    }
    .backgroundElementTop {
        top: 0;
        right: 0;
    }
    .backgroundElementBottom {
        left: 0;
        bottom: 0;
    }
}
@media (max-width: 750px) {
    .submitPhotoModal {
        justify-content: center;
        padding: 0;
        padding: 0 24px 0 24px;

        span {
            margin-top: 38px;
        }
        p {
            text-align: center;
            margin-block: 10% 17%;
        }

        .goBack {
            display: flex !important;
            left: 24px;
        }
        .docPhoto {
            width: 340px;
            height: 68%;
            max-height: 480px;
            height: 68%;
            img {
                border-radius: 20px;
            }
        }
        .docPhotoShort {
            width: 340px;
            height: 227px;

            img {
                width: auto;
                height: 100%;
                max-height: 225px;
            }
        }

        button {
            width: 80%;
            max-width: 540px;
            height: 56px;
        }
    }
}
@media (max-width: 500px) {
    .submitPhotoModal {
        padding-bottom: 20px;
        padding-top: 65px;
        padding-bottom: 56px;
        justify-content: flex-end;

        .goBack {
            .backText {
                display: none;
            }
        }
        .docPhoto {
            width: 320px;
            height: 70%;
            max-height: 455px;
        }
        .docPhotoShort {
            width: 327px;
            height: 218px;
            margin-bottom: 20%;

            img {
                width: auto;
                height: 100%;
                max-height: 218px;
            }
        }
        p {
            text-align: center;
            margin-block: 7% 14%;
        }

        button {
            width: 100%;
        }
    }
}
@media (max-height: 900px) and (max-width: 750px) {
    .submitPhotoModal {
        box-shadow: none;
        justify-content: flex-start;
        padding-top: 55px;
        padding-bottom: 20px;
        min-height: 700px;

        p {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
            margin-block: 40px;
            z-index: 31;
        }
    }
}

@media (max-height: 700px) and (max-width: 500px) {
    .submitPhotoModal {
        justify-content: flex-start;
        min-height: 600px !important;
        .openCameraBtn {
            width: 80%;
            position: absolute;
            bottom: 20px !important;
        }
        .examplePhotoBoxID {
            margin-top: 0%;
        }
    }
}
