.research-page {
    max-width: 450px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 40;

    .research-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .research-page-go-back {
            cursor: pointer;
            position: absolute;
            top: 24px;
            left: 24px;
            width: 31px;
            height: 30px;
            border-radius: 100%;
            padding: 3px 3.5px 3px 2.5px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            transition: 0.1s;
            z-index: 15;
            box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);

            img {
                filter: brightness(0);
            }

            &:hover {
                background-color: white;
            }
        }
        .research-page-content-top {
            width: 100%;
            height: 250px !important;
            min-height: 250px !important;
            max-height: 250px !important;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 24px;
            gap: 4px;
            position: relative;

            .research-page-content-top-categories {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                p {
                    margin: 0;
                    font-size: 7px;
                    padding: 4px 8px 3.5px 8px;
                    border-radius: 10px;
                    line-height: 8px;
                    letter-spacing: normal;
                    text-align: center;
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 0.5px 0.5px #0c0c0c;
                    border: 1px solid #fffff0;
                    transition: 0.4s;
                    //todo oppen
                    // cursor: pointer;

                    &:hover {
                        background: transparent;
                        border: 1px solid #fff;
                        color: #2b2f38;
                        background: #fffff0;
                        transition: 0.4s;
                        text-shadow: 0.5px 0.5px #00000000;
                    }
                }
            }

            h1 {
                text-align: left;
                color: #f9f9f9;
                max-width: 85%;
                font-size: 18px;
                line-height: 28px;
                text-shadow: 1px 1px #0c0c0c;
            }

            .headerBottomContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    color: #f9f9f9;
                    font-size: 10px;
                    text-shadow: 1px 1px #0c0c0c;
                }
                a {
                    width: 16px;
                    height: 16px;
                    // background-image: url("../../assets/Print.svg");
                    background-position: center;
                    background-size: 16px;
                    transition: 0.2s;

                    // &:hover {
                    //     background-image: url("../../assets/PrintPink1.svg");
                    // }
                }
            }
        }
        .authors {
            width: 90%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 18px;
            padding-block: 16px;
            flex-direction: column;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 16px;

            .authors-field {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 14px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                    object-fit: cover;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    gap: 1px;

                    p {
                        font-size: 16px;
                        color: #0c0c0c;
                        text-align: left;
                    }
                    span {
                        text-align: left;
                        font-size: 12px;
                        line-height: 16px;
                        color: #7f7f7f;
                    }
                }
            }
        }
        .research-page-material {
            max-width: 800px;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;

            #pdf-controls,
            #pdf-page-info {
                display: none;
            }
            #pdf-page-wrapper {
                margin: 0;
            }
            .react-pdf__Document {
                margin: 0;
            }
            .react-pdf__Page__canvas {
                width: 100% !important;
                height: auto !important;
            }
            .textLayer {
                display: none;
            }
            .react-pdf__Page {
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
                min-height: 0px !important;
                max-height: 100% !important;
            }
            .react-pdf__Page__canvas {
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
                height: auto !important;
            }
            .react-pdf__Page__annotations,
            .react-pdf__Page__textContent {
                height: 0px !important;
                max-height: 0px !important;
            }
            #pdf-toggle-pagination {
                display: none !important;
            }
            #pdf-controls {
                display: none !important;
            }
            #pdf-page-info {
                display: none !important;
            }
        }
        .pleaseLogIn {
            width: 100%;
            max-width: 800px;
            margin-block: 30px;
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 30px 90px 30px 30px;

            p {
                font-size: 24px;
                margin-bottom: 24px;
            }
            span {
                font-size: 15px;
                margin-bottom: 30px;
            }

            form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                position: relative;
            }
            input {
                width: 100%;
                background-color: inherit;
                padding: 6px 2.5px;
                color: #0c0c0c;
                border-bottom: 1px solid #e0e0e0;
                font-size: 15px;
                line-height: 18px;
                position: relative;

                // &:after {
                //     content: "";
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     width: 20px;
                //     height: 20px;
                //     background-color: red;
                //     z-index: 100;
                // }

                &:active {
                    outline: none;
                    border-bottom: 1px solid #747474;
                }
                &:focus {
                    outline: none;
                    border-bottom: 1px solid #747474;
                }
            }

            // input[type="text"] {
            //     &::after {
            //         // content: "Введите ваш номер телефона";
            //         // position: absolute;
            //         // top: 0;
            //         // font-size: 12px;
            //         // width: 100%;
            //         // height: 12px;
            //         // background-color: red;
            //     }
            // }

            div {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-top: 30px;

                button {
                    background: #333;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 5px;
                    text-decoration: none;
                }

                p {
                    padding: 0;
                    margin: 0;
                    font-size: 12px;

                    a {
                        padding-left: 6px;
                        color: #f8009e;
                        transition: 0.4s;

                        &:hover {
                            color: #c9008c;
                        }
                    }
                }
            }

            // @media (max-width: 580px) {
            //     .pleaseLogIn {
            //         margin-block: 20px;
            //         padding: 20px;

            //         p {
            //             font-size: 20px;
            //             margin-bottom: 4px;
            //         }
            //         span {
            //             font-size: 13px;
            //             margin-bottom: 20px;
            //         }
            //         form {
            //             flex-direction: column;
            //             gap: 14px;
            //         }

            //         div {
            //             align-items: flex-start;
            //             flex-direction: column;
            //             gap: 18px;
            //             margin-top: 30px;

            //             button {
            //                 width: 100%;
            //             }
            //             p {
            //                 display: flex;
            //                 justify-content: space-between;
            //                 width: 100%;
            //             }
            //         }
            //     }
            // }
        }

        .disclaimer {
            position: sticky;
            display: none;
            align-items: flex-end;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            bottom: -200px;
            padding: 24px;
            opacity: 0;
            background-color: white;
            box-shadow: 0px 0px 15px 0px #00000040;
            border-radius: 12px 12px 0px 0px;
            transition: 0.4s;

            .page-up {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                border: 1px solid #dbdbdb;
                background-color: inherit;
                position: absolute;
                top: -20px;
                right: 24px;
                transition: 0.3s;
                background-color: white;

                img {
                    transition: 0.5s;
                    width: 24px;
                    height: 24px;
                    // width: 100%;
                    // height: 100%;
                }

                &:hover {
                    // padding-bottom: 2px;
                    // top: 12px;
                    border-color: #0c0c0c;
                    img {
                        filter: brightness(0%);
                    }
                }
            }

            .disclaimer-research-btn {
                text-align: center;
                font-size: 16px;
                height: 40px;
                max-width: 180px;
                overflow: hidden;
                cursor: pointer;
                text-decoration: none;
                color: black;
                border-radius: 24px;
                border: 1px solid #dbdbdb;
                padding: 8px 20px;
                transition: 0.2s ease-in-out;

                &:hover {
                    border-color: black;
                }
            }

            @keyframes pageUp {
                0% {
                    border-color: #dbdbdb;
                }
                50% {
                    border-color: #0000006c;
                    box-shadow: 0px 0px 8px 1px #00000040;
                }
                100% {
                    border-color: #dbdbdb;
                }
            }
            @keyframes pageUpImg {
                50% {
                    filter: brightness(50%);
                }
            }

            .research-share {
                display: flex;
                flex-direction: column;
                gap: 12px;

                p {
                    color: black;
                    font-size: 16px;
                    transition: 0.4s;
                }

                div {
                    display: flex;
                    gap: 8px;

                    a {
                        &:nth-child(2) {
                            img {
                                padding-right: 7.5px;
                                padding-left: 6.5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
