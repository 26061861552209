@import "../../../styles/main.scss";

.fractional-status-indicator {
    @include flex-center;
    width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 6px;
    transition: 0.2s;
    cursor: pointer;

    img {
        width: 30px !important;
        height: 30px !important;
    }

    &:hover {
        filter: brightness(0.9);
    }
}
