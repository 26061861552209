.citizenshipField {
    width: 90%;
    height: 72px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f3f5;
    padding: 0 24px;
    overflow: hidden;
    gap: 24px;
    transition: 0.1s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        img {
            width: 24px;
            height: 24px;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            color: #2b2f38;
        }
    }

    &:hover {
        background: #000f3014;
        padding-right: 20px;
    }
}
