.market {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(249, 249, 249, 0.34);

    .search-panel {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 24px 8px 24px;
        gap: 16px;

        .serch-form {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 56px;
            width: 100%;
            height: 48px;
            border-radius: 16px;
            background: rgba(255, 255, 255, 1);
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
            cursor: text;
            img {
                cursor: text;
                position: absolute;
                left: 16px;
            }
        }
        .filter-btn {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            border-radius: 16px;
            background: rgba(255, 255, 255, 1);
        }
    }

    .tab-bar {
        display: flex;
        gap: 16px;
        overflow: scroll;
        width: 100%;
        padding: 20px 24px;
        position: sticky;
        top: 0px;
        z-index: 10;
        background: rgba(251, 251, 251);

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .market-main {
        display: flex;
        flex-direction: column;
        padding: 0 24px 90px 24px;
        position: relative !important;

        .calendar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            min-height: 48px;
            border-radius: 12px;
            background: rgba(230, 231, 235, 0.7);
            padding-left: 16px;
            padding-right: 16px;
            margin-block: 16px;
            cursor: pointer;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                p {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                    color: rgba(43, 47, 56, 1);
                }
            }
        }
    }

    .btn-wrapper {
        position: fixed;
        max-width: 450px;
        bottom: 95px;
    }


}
// img {
//     image-rendering: auto;
//     image-rendering: crisp-edges;
//     image-rendering: pixelated;
// }
.strategy-market{
    display: flex;
    flex-direction: column;
    gap: 12px;
}