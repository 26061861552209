.filter {
    max-width: 450px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .filter-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        min-height: 72px;
        animation: search 1s;

        img {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .tab-bar-filter {
        display: flex;
        gap: 16px;
        overflow: scroll;
        width: 100%;
        max-height: 90px;
        padding: 20px 24px;
        min-height: 77px;
        top: 0px;
        z-index: 10;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .filter-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        animation: search 1s;
        overflow-y: scroll;
        height: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .filter-cards {
            width: 100%;
            margin-bottom: 16px;

            .filter-ready-box {
                display: flex;
                align-items: center;
                min-width: 100%;
            }
            .filter-my-box {
                display: flex;
                align-items: center;
                width: auto;
                padding: 16px 24px;
                overflow-y: scroll;
                gap: 16px;   
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
@keyframes search {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
