.choose-doc-type {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .choose-doc-type-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding-left: 24px;
        padding-right: 24px;

        .register-title {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 20%;
            width: 90%;
            color: rgba(43, 47, 56, 1);
        }
        .register-subtitle {
            font-family: "SpaceGrotesk";
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10%;
            width: 90%;
        }
    }

    @media (max-width: 750px) {
        .radioCard {
            background: #f2f3f5;
            width: 100%;
            max-width: 540px;
        }
    }
}

//todo OLD CODE (CODE BEFORE ONBOARDING TO ACCOUNT)

// .createAcc {
//     width: 555px;
//     height: 504px;
//     padding: 0px;
//     border-radius: 36px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding-top: 48px;
//     background: rgba(255, 255, 255, 1);
//     box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
//     z-index: 10;
//     position: relative;

//     div {
//         display: flex;
//     }

//     p {
//         font-family: "SpaceGrotesk";
//         font-size: 28px;
//         font-weight: 600;
//         color: rgba(43, 47, 56, 1);
//         text-align: center;
//     }
//     span {
//         margin: 10px 0 56px 0;
//         font-size: 16px;
//         font-weight: 400;
//         color: rgba(78, 85, 102, 1);
//         text-align: center;
//     }

//     .joinCards {
//         display: flex;
//         flex-direction: column;
//         gap: 16px;
//         width: 82%;
//     }
// }
// @media (max-width: 750px) {
//     .chooseDocType {
//         margin-top: 12%;
//         width: 100% !important;
//         max-width: 465px !important;
//         height: auto;
//         padding-top: 0;
//         background: inherit;
//         box-shadow: none;
//         justify-content: center;
//         position: static;
//         padding: 0px !important;

//         .doctype {
//             background: rgba(249, 249, 249, 1);
//         }
//     }
// }
// @media (max-width: 500px) {
//     .chooseDocType {
//         margin-top: 0;
//     }
// }
