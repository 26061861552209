.message-image {
    min-width: 100px;
    min-height: 90px;
    width: auto;
    height: auto;
    max-width: 68%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 3px;
    background: rgba(77, 89, 117, 1);
    border-radius: 12px;
    position: relative;

    img {
        max-width: 100%;
        max-height: 260px;
        object-fit: contain;
        border-radius: 10px;
    }

    span {
        max-width: 38px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.0075em;
        text-align: center;
        // color: rgba(191, 196, 206, 1);
        color: rgb(253, 253, 253);
        background-color: rgba(0, 0, 0, 0.35);
        padding: 0 6px 0 6px;
        border-radius: 10px;
        position: absolute;
        bottom: 6px;
        right: 8px;
    }
    &.last-msg {
        border-radius: 12px 12px 0 12px;
        img {
            border-radius: 10px 10px 0 10px;
        }
    }
}
.message-admin-image {
    gap: 0;
    background: rgba(226, 228, 233, 1);

    span {
        // text-align: left;
        // color: rgba(78, 85, 102, 1);
        // position: absolute;
        left: 8px;
    }

    &.last-msg {
        border-radius: 12px 12px 12px 0px;
        img {
            border-radius: 10px 10px 10px 0px;
        }
    }
}

.vue-image-message {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50;

    .vue-img-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 16px;

        span {
            color: rgba(255, 255, 255, 0.4);
        }

        .action_btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            .action_btn-wrapper {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 0.2s;

                img {
                    width: 24px;
                    height: 24px;
                    opacity: 0.4;
                    transition: 0.2s;
                }
                // .zoom-out-img {
                //     width: 25.5px;
                //     min-width: 25.5px;
                //     height: 25.5px;
                // }

                &:hover {
                    background-color: rgb(255, 255, 255, 0.1);
                    img {
                        opacity: 1;
                    }
                }
            }
            .close-img-vue-icon {
                width: 22px;
                max-width: 22px;
                height: 22px;
                max-height: 22px;
            }
        }
    }

    .zoomed-img {
        min-width: 80%;
        max-width: 90%;
        max-height: 95%;
        object-fit: contain;
    }
    img {
        max-width: 60%;
    }
}
