.sign-user-contract {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .sign-user-contract-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        .rotate-screen {
            // cursor: pointer;
            position: absolute;
            right: 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }

        .download-btn {
            cursor: pointer;
            position: absolute;
            right: 24px;
        }
    }
    .sign-user-contract-main {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 0;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .user-contract-document {
            width: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        #proxy-renderer {
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .react-pdf__Page {
            width: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            min-height: 0px !important;
            max-height: 100% !important;
        }
        .react-pdf__Page__canvas {
            width: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            height: auto !important;
        }
        .react-pdf__Page__annotations,
        .react-pdf__Page__textContent {
            height: 0px !important;
            max-height: 0px !important;
        }
        #pdf-toggle-pagination {
            display: none !important;
        }
        #pdf-controls {
            display: none !important;

            // position: absolute;
            // top: 24px;
            // left: 85%;
            // height: 30px;
            // width: 30px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // box-shadow: none !important;
            // padding: none !important;
            // background-color: rgba(0, 0, 0, 0) !important;

            // a {
            //     border: 1px solid black;
            //     width: 30px !important;
            //     height: 30px !important;
            //     min-width: 30px;
            //     min-height: 30px;
            // }

            // button {
            //     display: none !important;
            // }
        }
        #pdf-page-info {
            display: none !important;
        }
        .contract-docs-viewer {
            width: 100%;
            height: 100vh;
        }

        @media (max-width: 500px) {
            .react-pdf__Document {
                margin: 0px !important;
                padding-top: 100px;
                transform: scale(1.1);
            }
        }

        #pdf-page-wrapper {
            position: relative;
            margin: 0px !important;
        }

        // #loading {
        //     display: none;
        // }
        .sign-container {
            position: absolute;
            bottom: 35%;
            left: 45px;
            z-index: 10;
            font-family: "ArTar";
            font-size: 26px;

            @media (max-width: 410px) {
                bottom: 35%;
                left: 38px;
                font-size: 24px;
            }
            @media (max-width: 365px) {
                bottom: 35%;
                left: 35px;
                font-size: 22px;
            }
            @media (max-width: 345px) {
                bottom: 35%;
                left: 35px;
                font-size: 20px;
            }
        }
    }

    .contract-sign-btn-wrapper {
        display: flex;
        flex-direction: column;
        z-index: 10;
        gap: 15px;
        background-color: #ffffffbb;
        padding-block: 10px;
    }
}

// #pdf-page-wrapper{
//     background-image: linear-gradient(red, rgb(27, 27, 30));
//     padding-block: 24px;
// }
