.onboarding-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    flex-grow: 1;

    @media (max-width: 750px) {
        padding-top: 70px;
    }
    @media (max-width: 550px) {
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
    }
}

.onboardingLayoutAccCreated {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 0.995;
    overflow: hidden;
    padding-top: 0;
}
@media (max-width: 750px) {
    .onboarding-layout-terms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-grow: 0.995;
        overflow: hidden;
        padding-top: 0;
    }
}
@media (max-width: 500px) {
    .onboarding-layout-terms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-grow: 0.995;
        overflow: hidden;
        padding-top: 40px;
    }
}

