.bargaining-alert {
    width: 100%;
    margin-block: 8px;
    padding: 8px 12px;
    border: 1px solid #eb5757;
    background: #fdeded;
    border-radius: 10px;
    text-align: left;

    &.warning {
        background: #f3f0e6;
        border: 1px solid #af8d14;
        color: #af8d14;
    }

    &.danger {
        background: #fdeded;
        border: 1px solid #eb5757;
        color: #eb5757;
    }

    &.info {
        background: rgb(236, 244, 253);
        border: 2px solid rgb(47, 128, 237);
        color: rgb(43, 47, 56);
        font-size: 14px;
        font-weight: 500;
    }
}
