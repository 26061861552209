.filter-review {
    max-width: 452px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    padding-top: 72px;
    padding-bottom: 110px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .filter-review-header {
        background-color: #f9f9f9 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 450px;
        height: 72px;
        min-height: 72px;
        position: fixed;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -0%);
        z-index: 25;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .result-quantity {
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #4e5566;
        padding-left: 24px;
        margin-block: 8px;
    }
    .filter-review-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative !important;
        padding-left: 24px;
        padding-right: 24px;
        overflow-y: scroll;
        height: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .btn-wrapper {
        padding: 4px 24px;
        width: 100%;
        position: fixed;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        max-width: 450px;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -20px);
        z-index: 10;
    }

    .sorted-configs-empty {
        min-height: 60px;
        height: auto;
        max-height: 60px;
    }

    .sorted-configs {
        min-height: 60px;
        height: auto;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;

        p {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: rgba(249, 249, 249, 1);
        }
    }

    .filterReviewEditSaveBtn {
        width: 50% !important;
        min-height: 60px;
        height: auto;
        max-height: 60px;
    }
}
