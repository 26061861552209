.logoAndTitle {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;

  p {
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 600;
    color: rgba(43, 47, 56, 1);
  }
}
