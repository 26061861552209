@import "../../../styles/main.scss";

.transaction-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  .transaction-info-title {
    width: 100%;
    display: flex;
    padding: 8px 32px 8px 0px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);

    th {
      color: #2b2f38;
      width: 20%;
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      @include flex-center;
    }
  }
}

.transaction-row {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px 12px 0;
  border-bottom: 0.5px solid rgba(191, 196, 206, 1);

  td {
    color: #2b2f38;
    width: 20%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    @include flex-center;
  }
  img {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}
