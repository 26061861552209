.modal-adviser {
    padding: 24px;
    max-width: 327px;
    width: 88%;
    height: auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    text-align: center;
    position: absolute;

    .modal-adviser-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;

        p {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 600;
            color: #2b2f38;
            text-align: left;
        }

        .close {
            cursor: pointer;
        }
    }

    span {
        font-size: 16px;
        font-weight: 400;
        color: #2b2f38;
        margin-bottom: 24px;
        text-align: left;
    }
    button {
        width: 100%;
    }
}
.outlet-adviser {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
}
