.tab-btn {
    height: 37px;
    width: auto;
    padding: 8px 24px;
    border-radius: 32px;
    background: rgba(230, 231, 235, 1);
    position: relative;
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
    }
}
.active-tab {
    background: rgba(0, 15, 48, 1);
    span {
        color: rgba(249, 249, 249, 1);
    }
}
