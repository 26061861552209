.outlet-add-account-currency {
    max-width: 450px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 100;
    position: absolute;
}
.add-account-currency {
    width: 100%;
    max-width: 450px;
    max-height: 60vh;
    height: auto;
    padding: 0px 0px 40px 0px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: accCurrency 0.45s ease-out;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .add-account-currency-header {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: start;
        border-radius: 16px 16px 0px 0px;
        justify-content: space-between;
        padding: 24px 24px 10px 24px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        background-color: rgba(249, 249, 249, 1);
        position: fixed;

        img {
            cursor: pointer;
        }

        p {
            font-size: 20px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
        }
    }

    .firstRowMargin {
        margin-top: 92px;
    }
}
@keyframes accCurrency {
    from {
        max-height: 0;
    }
    to {
        max-height: 60vh;
    }
}
