.saved-to-favorites-notification {
    position: absolute;
    z-index: 100;
    padding: 10px;
    width: 79%;
    background-color: rgb(255, 217, 160, 0.7);
    border: 2px solid rgb(255, 152, 0);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    animation: saved 3.4s;
    color: #ff8c00f1;
    // color: #2b2f38;

    span {
        font-weight: 600;
        color: #ff8c00;
        text-decoration: underline;
        cursor: pointer;
    }
}
@keyframes saved {
    0% {
        top: 0px;
        opacity: 0;
    }
    12% {
        top: 8%;
        opacity: 1;
    }
    18%,
    85% {
        top: 7.8%;
        opacity: 1;
    }
    100% {
        top: 0px;
        opacity: 0;
    }
}
