@import "../../../styles/main.scss";

.exchange-status {
    width: 100%;
    max-width: 450px;
    max-height: 50vh;
    height: auto;
    padding: 12px 16px 24px 16px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: status 0.5s ease-out;

    .exchange-status-header {
        width: 100%;
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        margin-bottom: 4px;

        .exchange-status-symbol {
            @include flex-center;
            width: 28px;
            height: 28px;
            min-height: 28px;
            border-radius: 8px;

            img {
                width: 20px !important;
                height: 20px !important;
            }
        }
        p {
            text-align: left;
            font-size: 20px;
            font-family: "SpaceGrotesk";
            font-weight: 900;
        }
    }
    .status-info {
        width: 100%;
        font-size: 16px;
        color: #2b2f38;
        text-align: left;
        padding: 0 12px 0 34px;
        font-weight: 400;
    }
    .timeline {
        padding: 12px 24px 0 34px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 6px;
        position: relative;

        span {
            font-size: 10px;
            color: #2b2f38;
            line-height: 10px;
        }

        .line {
            height: 7px;
            background-color: #22ab94;
            border-radius: 4px;
            position: relative;

            .point {
                margin-left: -2px;
                position: absolute;
                width: 4px;
                height: 14px;
                top: -3.5px;
                background-color: #2b2f38;
                border: 1px solid white;
                border-radius: 2px;
                z-index: 100;
            }

            .from-to {
                width: 100%;
                height: 10px;
                background-color: initial;
                margin-top: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    background-color: initial;
                    color: #6a6d78;
                    font-family: "SpaceGrotesk";
                    font-size: 10px;
                    line-height: 10px !important;
                    width: 28px;

                    &:nth-child(1) {
                        margin-left: -13px;
                    }
                    &:nth-last-child(1) {
                        margin-right: -13px;
                    }
                }
            }

            .timeline-comment-wrapper {
                transition: 0.5s;
                opacity: 0;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                top: -18px;
                z-index: 10;

                .timeline-comment {
                    height: 20px;
                    border-radius: 4px;
                    background-color: rgba(19, 23, 34, 0.9);
                    padding: 0 6px;
                    color: white;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 0.7em;
                        line-height: 16px;

                        span {
                            line-height: 16px;
                            margin-right: 6px;
                            background-color: inherit;
                            font-weight: 600;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -4px;
                        width: 6px;
                        height: 4px;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid rgba(19, 23, 34, 0.9);
                    }
                }
            }

            &:hover {
                .timeline-comment-wrapper {
                    transition-delay: 0.5s;
                    opacity: 1;
                }
            }
        }
    }
    .timezone {
        color: #6a6d78;
        font-size: 11px;
        line-height: 18px;
        margin-top: 22px;
        text-align: center;
        width: 100%;
    }
}
@keyframes status {
    from {
        max-height: 0;
    }
    to {
        max-height: 50vh;
    }
}
.outlet-stock-status-modal {
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0px;
    z-index: 100;
    position: absolute;
}
