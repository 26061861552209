.history {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .date-deadline-container {
        padding: 8px 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 15px;
    }
    .history-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 72px;
        height: 72px;
        margin-bottom: 12px;

        .go-back-history {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
        .search-history {
            cursor: pointer;
            position: absolute;
            right: 24px;
        }
    }

    .query-wrapper {
        margin-top: 24px;
        padding: 10px 26px;
        width: 100%;

        .query-type {
            width: 100%;
            background-color: white;
            border-radius: 16px;
            position: relative;
            height: 44px;
            display: flex;

            button {
                width: 33%;
                height: 44px;
                min-height: 44px;
                border-radius: 16px;
                background-color: inherit;
                transition: 0s;
                overflow: hidden;
                padding: 0 8px;

                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                text-align: center;
                word-break: break-all;
                color: #2b2f38;
                border-bottom: 1px solid rgba(0, 0, 0, 0);
            }

            .active-btn {
                color: #f9f9f9;
                transition:
                    all 0.7s,
                    color 0.3s;
                background: #000f30;
            }
        }
    }
    .history-main {
        width: 100%;
        max-width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: white;
        border-radius: 32px 32px 0px 0px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .history-loader {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}

// .query-wrapperr {
//     // padding: 0 26px 24px 26px;
//     margin-bottom: 24px;
//     width: 100%;

//     .query-type {
//         width: 100%;
//         background-color: white;
//         border-radius: 16px;
//         position: relative;
//         // height: 44px;
//         height: auto;
//         display: flex;
//         align-items: center;
//         justify-content: start;

//         button {
//             // width: 33%;
//             // height: 44px;
//             // min-height: 44px;
//             // border-radius: 16px;
//             // border-radius: 12px;
//             // background-color: inherit;
//             // transition: 0s;
//             // overflow: hidden;
//             padding: 0 8px;
//             width: auto;
//             height: 32px;
//             background-color: inherit;
//             transition: 0s;
//             overflow: hidden;
//             // padding: 0 8px;

//             font-size: 14px;
//             line-height: 16px;
//             font-weight: 500;
//             text-align: center;
//             word-break: break-all;
//             color: #2b2f38;
//             border-bottom: 1px solid rgba(0, 0, 0, 0);
//         }

//         .active-btn {
//             // color: #f9f9f9;
//             // transition:
//             //     all 0.7s,
//             //     color 0.3s;
//             // background: #000f30;
//             font-weight: 600;
//             border-bottom: 1px solid #000f30;
//         }
//     }
// }
