.deal-container {
  max-width: 452px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: rgba(251, 251, 251);
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 50;

  &::-webkit-scrollbar {
    display: none;
  }
  .deal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 93px;
    min-height: 93px;
    margin-bottom: 12px;

    .go-back-portfolio {
      cursor: pointer;
      position: absolute;
      left: 24px;
    }

    div {
      display: flex;
      flex-direction: column;
      p {
        font-size: 16px;
        font-weight: 600;
        color: #2b2f38;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(78, 85, 102, 1);

          &:nth-last-child(1) {
            color: #27ae60;
            margin-left: 4px;
            padding-left: 4px;
            border-left: #bfc4ce;
            line-height: 12px;
          }
        }
      }
    }
  }
  .deal-main {
    padding: 0 24px 120px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .deal-on-account {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      .title-span {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #4e5566;
      }
      .deal-on-account-box {
        display: flex;
        flex-direction: row;
        padding: 24px 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #000f30;
        border-radius: 12px;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #f9f9f999;
          }
          p {
            font-size: 24px;
            font-weight: 600;
            color: #f9f9f9;
          }
        }
        img {
          filter: brightness(0) invert(1);
          cursor: pointer;
        }
      }
      .price-box {
        display: flex;
        flex-direction: row;
        padding: 24px 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #000f30;
        border-radius: 12px;
      }
    }
    .price-type-container {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 12px;
      background: #e6e7eb;
      padding: 12px 16px;
      cursor: pointer;
      overflow: hidden;

      p {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #2b2f38;
      }
    }
    .price-box {
      width: 100%;
      margin-block: 24px 40px;
      border-radius: 8px;
      border: 1px solid #bfc4ce;
      padding: 14px 16px 14px 24px;
      text-align: left;
      position: relative;

      p {
        font-size: 24px;
        font-weight: 600;
        color: #2b2f38;
      }
      span {
        font-size: 24px;
        font-weight: 600;
        color: #2b2f38;
        line-height: 28.8px;
        font-weight: 400;
        color: #7a8499;
      }
    }
    .specific-price-input {
      // height: 80%;
      font-size: 24px;
      font-weight: 600;
      color: #2b2f38;
      // line-height: 28.8px;
      background: inherit;
      -moz-appearance: textfield;
      border: none;
      width: 90%;
      &:focus {
        outline: none;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .error-spec-price {
      position: absolute;
      bottom: -26px;
      left: 4px;
      color: rgba(235, 87, 87, 1) !important;
      font-size: 12px !important;
      padding: 0;
      margin: 0;
      margin-top: 5px;
    }

    .deal-quantity {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      label {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
        color: #4e5566;
      }
      input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #bfc4ce;
        padding: 16px 24px;
        font-size: 16px;
        color: #2b2f38;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #7a8499;
        }
      }
      .info-about {
        display: flex;
        flex-direction: column;
        animation: infoAbout 0.3s;

        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.005em;
          text-align: left;
          color: #4e5566;
        }
      }
      @keyframes infoAbout {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    .deal-commission {
      margin: 10px 0;
      text-align: left;
      font-size: 14px;

      span {
        font-size: 15px;
      }
    }
  }
  .deal-btn-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    position: fixed;
    max-width: 440px;
    bottom: 40px;
    z-index: 10;
  }
  .deal-btn {
    height: auto;
    padding-block: 5px;
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
  }
}
