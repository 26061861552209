.accountCreated {
  width: 558px;
  height: 483px;
  border-radius: 24px;
  gap: 40px;
  background: #000f30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: PopUp 0.6s ease-out;
  z-index: 1;

  p {
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 500;
    color: #f9f9f9;
    max-width: 350px;
  }
  button {
    width: 350px;
    height: 56px;
    border-radius: 12px;
    gap: 8px;
    background: #f8009e;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
}
@media (max-width: 750px) {
  .accountCreated {
    width: 80%;
    max-width: 540px;
    background-color: inherit;
    padding: 0;
    box-shadow: none;
    animation: none;

    .goBack {
      display: none;
    }
    p {
      max-width: 100%;
    }

    button {
      width: 100%;
      max-width: 540px;
    }
  }
}
@media (max-width: 500px) {
  .accountCreated {
    width: 100%;
    margin-top: -60px;

    p{
      max-width: 95%;
    }

    button {
      width: 80%;
      max-width: 540px;
      position: absolute;
      bottom: 40px;
    }
  }
}
@keyframes PopUp {
  0% {
    margin-bottom: -50%;
  }
  100% {
    width: 558px;
    height: 483px;
    border-radius: 24px;
    gap: 40px;
    background: #000f30;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }
}
