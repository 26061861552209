.stock-details {
    max-width: 452px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(251, 251, 251);
    color: #e0e3eb4d;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 50;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .stock-details-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 93px;
        min-height: 93px;
        margin-bottom: 12px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            p {
                font-size: 16px;
                font-weight: 600;
                color: #2b2f38;
            }
            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
                width: 75%;
            }
        }

        .stock-details-header-span {
            -webkit-line-clamp: 2 !important;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .tab-bar {
        display: flex;
        gap: 16px;
        overflow: scroll;
        width: 100%;
        padding: 20px 24px;
        position: sticky;
        min-height: 77px;
        top: 0px;
        z-index: 10;
        background: rgba(251, 251, 251);

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .stock-details-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 24px 120px 24px;

        .asset-logo {
            max-width: 40px;
            width: 40px;
            object-fit: contain;
            filter: brightness(0.9);
        }

        .stock-info-bargaining {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-block: 16px;

            .stock-source-status {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
            }

            .stock-info {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 16px;
                text-align: left;
                width: 100%;

                .stock-info-header {
                    display: flex;
                    flex-direction: row;
                    gap: 6px;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .stock-price-status {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: row;
                        gap: 8px;
                    }
                }

                div {
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                }
                img {
                    width: 40px;
                    height: 40px;
                }
                .price {
                    font-size: 24px;
                    font-weight: 600;
                    color: rgba(0, 15, 48, 1);
                }
                .gain-percent {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: rgba(39, 174, 96, 1);
                    }
                    .gains-percent {
                        border-left: 1px solid rgba(191, 196, 206, 1);
                        padding-left: 8px;
                        margin-left: 8px;
                        height: 12px;
                        line-height: 12px;
                    }
                    .loss {
                        color: rgba(235, 87, 87, 1);
                    }
                }
                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: rgba(78, 85, 102, 1);
                }
            }
        }

        // ..
        .dividents-title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                color: rgba(78, 85, 102, 1);
            }
        }
        // ..
        .indicators-title {
            width: 100%;
            text-align: left;
            margin-block: 16px;
            p {
                font-size: 20px;
                font-weight: 620;
                color: rgba(43, 47, 56, 1);
            }
        }
        // ..
        .review-title {
            width: 100%;
            text-align: left;
            margin-block: 16px;
            p {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 620;
                color: rgba(43, 47, 56, 1);
            }
        }
        .description-span {
            margin-bottom: 16px;
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 47, 56, 1);
        }
        .description-details {
            padding-bottom: 16px;
            width: 100%;
        }
        .info-details {
            width: 100%;
        }
    }

    .bargaining-alert {
        width: 100%;
        margin-block: 8px;
        padding-block: 8px;
        padding-left: 12px;
        border: 1px solid #eb5757;
        background: #fdeded;
        border-radius: 12px;
        text-align: left;

        span {
            font-size: 14px;
            font-weight: 500;
            color: #eb5757;
        }
    }

    .action-btns-wrapper {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        position: fixed;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        max-width: 440px;
        bottom: 40px;
        z-index: 10;
    }

    .highcharts-axis-title {
        display: none !important;
    }
    .highcharts-credits {
        display: none !important;
    }
}
.stock-detailsDividents {
    min-height: 500px;
    height: 100%;

    .stock-details-main-dividents {
        background: rgba(251, 251, 251);
        padding-bottom: 120px;
    }
}
.stock-detailsIndicators {
    min-height: 500px;
    height: 100%;

    .stock-details-main {
        background: rgba(251, 251, 251);
        padding-bottom: 120px;
    }
    .action-btns-wrapper {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 440px;
        bottom: 40px;
    }
}
.stock-detailsReview {
    min-height: 500px;
    height: 100%;

    .stock-details-main {
        background: rgba(251, 251, 251);
        padding-bottom: 120px;
    }
    .action-btns-wrapper {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        position: fixed !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 440px;
        bottom: 40px;
    }
}
